<template>
  <div>
    <a
      class="file file--download"
      v-if="isDownload"
      :href="file?.url"
      :download="file?.file_name"
      target="_blank"
    >
        <VIcon class="file__icon" width="23" height="27" icon-name="File" />
        <span class="file__name">
          {{ file?.name || file?.title }}
        </span>
      <button
        v-if="showDelete"
        class="icon__btn-delete btn"
        :class="iconNameBtnDelete === 'YellowDelete' && 'icon__btn-delete--yellow'"
        @click.prevent="onDelete(file)"
      >
        <VIcon :icon-name="iconNameBtnDelete" :width="iconSize" :height="iconSize" />
      </button>
    </a>

    <div class="file" v-else>
      <VIcon class="file__icon" width="23" height="27" icon-name="File" />
      <span class="file__name">
          {{ file?.name || file?.title }}
      </span>

      <button
        v-if="showDelete"
        class="icon__btn-delete btn"
        :class="iconNameBtnDelete === 'YellowDelete' && 'icon__btn-delete--yellow'"
        @click.prevent="onDelete(file)"
      >
        <VIcon :icon-name="iconNameBtnDelete" :width="iconSize" :height="iconSize" />
      </button>
    </div>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import { computed } from 'vue'

export default {
  components: { VIcon },
  emits: ['onDelete'],
  props: {
    file: {
      type: Object,
      required: true
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    isDownload: {
      type: Boolean,
      required: false
    },
    iconNameBtnDelete: {
      type: String,
      required: false,
      default: 'DeleteFile'
    },
    index: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (props, { emit }) {
    const onDelete = file => {
      // TODO �������� ������ �������� �� ������ (��� ����� ������ � ���������, ��� ������ � �������)
      emit('onDelete', file.id || file.name, { id: file.id || null, name: file.name || file.title, index: props.index })
    }

    const iconSize = computed(() => {
      switch (props.iconNameBtnDelete) {
        case 'DeleteFile': return 27
        case 'YellowDelete': return 38
        default: return 0
      }
    })

    return {
      iconSize,
      onDelete
    }
  }
}
</script>
