<script setup>
import { defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isLast: {
    type: Boolean,
    required: true
  }
})

function toRoute () {
  if (!props.item.route) return
  let next = props.item.route
  if (typeof props.item.route === 'function') {
    next = props.item.route({ id: route.params.id })
  }
  router.push(next)
}
</script>

<template>
  <li class="breadcrumbs__item" @click="toRoute">
    {{ item.name }}<span class="breadcrumbs__item--slash">{{ isLast ? '' : '/' }}</span>
  </li>
</template>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.breadcrumbs__item {
  font-size: 14px;
  color: #899787;
  line-height: 140%;
  cursor: pointer;

  transform: color $transition-duration ease-in-out;

  &:hover {
    color: $color-primary;
  }

  &--slash {
    padding: 0 2px;
  }
}
</style>
