import { $authHost, $host } from '@/http/index'

const REFERENCES_URL = '/references/'

export const getManualByKey = async ({ isAuth, key }) => {
  const URL = REFERENCES_URL + key
  let data
  if (isAuth) {
    data = await $authHost.get(URL)
  } else {
    data = await $host.get(URL)
  }

  return data
}

export const getTimeStart = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}time-before-start`)
  return data.data
}

export const getManual = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}list`)
  return data
}

export const getManualId = async (id) => {
  const { data } = await $authHost.get(`${REFERENCES_URL}list/${id}?expand=child`)
  return data.data
}

export const setManualId = async (id, formData) => {
  const { data } = await $authHost.put(`${REFERENCES_URL}list/${id}`, formData)
  return data
}

export const getMeasurement = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}measurement`)
  return data
}

export const setCategoryTable = async (formData, id) => {
  const setCategoryTableUrl = REFERENCES_URL + 'category'
  if (id) {
    const { data } = await $authHost.put(`${setCategoryTableUrl}${id}`, formData)
    return data
  } else {
    const { data } = await $authHost.post(setCategoryTableUrl, formData)
    return data
  }
}

export const getParameterType = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}parameter-type`)
  return data
}

export const getParameterCategory = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}parameter-category`)
  return data
}

export const getParameterDepartment = async () => {
  const { data } = await $authHost.get(`${REFERENCES_URL}parameter-department`)
  return data
}

export const createParameterOptions = async (id, options) => {
  const { data } = await $authHost.put(`${REFERENCES_URL}parameter-option/${id}`, { child: options })
  return data
}
