<template>
  <!-- default width="8" height="14" -->
  <path d="M1.07837 1L6.07837 7.18182L1.07837 13" stroke="currentColor" stroke-width="2"/>
</template>

<script>
export default {
  name: 'IconArrowNext'
}
</script>
