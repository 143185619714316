<template>
  <ul
    v-if="files?.length"
    class="files"
    :class="{'files--grid' : grid}"
  >
    <li
      class="files__item"
      v-for="(file, i) in files"
      :key="i"
    >
      <VFileItem
        :is-download="isDownload"
        :file="file"
        :index="i"
        :showDelete="showDelete"
        :icon-name-btn-delete="iconNameBtnDelete"
        @onDelete="onDelete"
      />
    </li>
  </ul>

  <p v-else class="mt-30">Файлы отсутствуют</p>
</template>

<script>
import VFileItem from '@/components/list/FileList/VFileItem'
export default {
  emits: ['onDelete'],
  props: {
    files: {
      type: [Array, null],
      required: true
    },
    grid: {
      type: Boolean,
      required: false,
      default: false
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    isDownload: {
      type: Boolean,
      required: false,
      default: true
    },
    iconNameBtnDelete: {
      type: String,
      required: false,
      default: 'DeleteFile'
    }
  },
  setup (props, { emit }) {
    const onDelete = (fileId, file) => {
      emit('onDelete', fileId, file)
    }

    return {
      onDelete
    }
  },
  components: {
    VFileItem
  }
}
</script>
