import store from '@/store'
import { Alert } from '@/extension/Alert/Alert'

export class WebSocketsCentrifugeSubscribes {
  async subscribeUser (ctx) {
    store.commit('notification/setNotification', ctx.data)
    store.commit('notification/setUnreadNotifications', ctx.data)
    await Alert.show('warn', ctx.data.message)
  }

  subscribeLot () {
    return store.dispatch('lot/callReRenderLot')
  }
}
