<template>
  <!-- default width="15" height="15" -->
  <circle cx="7.5" cy="7.5" r="6.75" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M4.77246 7.5H10.227" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconCancelLot'
}
</script>
