import store from '@/store'

export function setMountedBestWinner (winnersList, offers) {
  offers.slice(0, 3).forEach(offer => {
    winnersList.push(offer)
    store.commit('offer/setWinnerList', offer)
  })
}

export const pushCategories = (categories, item) => {
  if (!item.categories?.length) return
  item.categories.forEach(cat => {
    categories.push(cat)
  })
}

export function checkCurrentStatus (statusIds) {
  const id = store.getters['lot/getLot']?.status?.id
  return statusIds.includes(id)
}

export function checkAuthRole (roles) {
  return roles.includes(store.getters['auth/getRole'])
}

export function checkBidderCount () {
  const lot = store.getters['lot/getLot']
  return lot.bidder_count > 0
}

function publicAndEditFromCandidate (role) {
  const list = ['cancel', 'hide']
  if (role === 'admin') list.push('open')
  return list
}

function getNameMethodOfLotController (emitList, touchbar, list) {
  emitList.forEach(emitName => {
    const item = touchbar.find(el => el.emit === emitName)
    list.push(item)
  })
}

export function paintMenuByStatus (list, statusId, touchbar) {
  const role = store.getters['auth/getRole']
  const statusEmitList = new Map([
    [1, ['public', 'hide', 'deleteBeforePublic']],
    [3, publicAndEditFromCandidate(role)],
    [4, publicAndEditFromCandidate(role)],
    [6, ['extend']],
    [11, ['hide']]
  ])
  getNameMethodOfLotController(statusEmitList.get(statusId), touchbar, list)

  if (role === 'admin') {
    const adminItem = touchbar.find(item => item.emit === 'delete')
    list.push(adminItem)
  }
}

export function checkParametersOnlySelected(arr) {
  return arr.filter(par => par.full_name !== 'Начальная цена' && par.full_name !== 'Количество' && par.is_selected)
}

export function checkParametersDefault(arr) {
  return arr.filter(par => par.full_name !== 'Начальная цена' && par.full_name !== 'Количество')
}
