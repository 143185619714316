import { useStore } from 'vuex'
import { computed } from 'vue'

export function useAlert () {
  const store = useStore()

  const getIsAlert = computed(() => {
    return store.getters.getAlert
  })

  return {
    getIsAlert
  }
}
