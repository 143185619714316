import adminMeta from '../adminMeta'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/admin/trade/active',
    name: 'admin-trade-active',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Trade/AdminTradeActiveView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/trade/archive',
    name: 'admin-trade-archive',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Trade/AdminTradeArchiveView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/trade/delete',
    name: 'admin-trade-delete',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Trade/AdminTradeDeleteView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  }
]
