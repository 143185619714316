import { $authHost } from '@/http/index'

const ACCREDITATION_URL = '/accreditation'

export const sendAccreditation = async (userId) => {
  return await $authHost.post(ACCREDITATION_URL, { user_id: userId })
}

export const getAccreditation = async (query, page = 1) => {
  const { data } = await $authHost.get(`${ACCREDITATION_URL}?${query}&page=${page}`)
  return data
}

export const getOneAccreditation = async (id) => {
  const { data } = await $authHost.get(`${ACCREDITATION_URL}/${id}`)
  return data
}

export const changeAccreditation = async (id, status) => {
  const { data } = await $authHost.put(`${ACCREDITATION_URL}/${id}`, { status: status })
  return data
}

export const cancelAccreditation = async (id, { status, message }) => {
  const { data } = await $authHost.put(`${ACCREDITATION_URL}/${id}`, { status: status, message })
  return data
}
