<template>
  <div :class="['confirm', maxWidthClass]">
    <h4 class="confirm__question">{{ confirmQuestion }}</h4>

    <slot />

    <div class="confirm__buttons">
      <button class="btn btn-primary btn-bg-green" @click="acceptIf">
        {{ confirmBtnAccept }}
      </button>
      <button class="btn btn-primary btn-bg-transparent" @click="declineIf">
        {{ confirmBtnDecline }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emits = defineEmits(['accept', 'decline'])

defineProps({
  confirmQuestion: {
    type: String,
    required: false
  },
  confirmBtnAccept: {
    type: String,
    required: false,
    default: 'Да'
  },
  confirmBtnDecline: {
    type: String,
    required: false,
    default: 'Нет'
  },
  maxWidthClass: {
    type: String,
    required: false
  }
})

const acceptIf = () => {
  emits('accept', true)
}

const declineIf = () => {
  emits('decline', false)
}
</script>
