<template>
  <a
    class="link-file link-reset gap-10"
    :href="href"
    target="_blanc"
  >
    <VIcon
      width="23"
      height="27"
      icon-name="File"
    />
    {{ text }}
  </a>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
export default {
  // Чтобы файл скачивался, а не открывался в новой вкладке,
  // нужно добавить атрибут "download" на компонент
  name: 'LinkFile',
  components: { VIcon },
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    href: {
      type: String,
      required: true
    }
  }
}
</script>
