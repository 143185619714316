import bidderMeta from '@/router/bidder/bidderMeta'
import lot from '@/router/bidder/lot'
import category from '@/router/bidder/category'
import myAuctions from '@/router/bidder/myAuctions'
import trade from '@/router/bidder/trade'
import profile from '@/router/bidder/profile'
import { checkRole } from '@/utils/utils'
import { ApiError } from '@/extension/Error/ApiError'

const apiError = new ApiError()

export default [
  {
    path: '/bidder',
    name: 'bidder',
    redirect: { name: 'bidder-my-auctions-sidebar' },
    meta: bidderMeta,
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/my-auctions',
    name: 'bidder-my-auctions',
    redirect: { name: 'bidder-my-auctions-sidebar' },
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/BidderMyAuctionsView'),
    children: [
      ...myAuctions
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/trade',
    name: 'bidder-trade',
    redirect: { name: 'bidder-trade-active' },
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarTradeView'),
    props: {
      title: 'Торговые процедуры'
    },
    children: [
      ...trade
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/setting',
    name: 'bidder-setting',
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarSettingView'),
    props: {
      title: 'Настройки'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/category',
    name: 'bidder-category',
    redirect: { name: 'bidder-category-all' },
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarCategoryView'),
    props: {
      title: 'Мои категории'
    },
    children: [
      ...category
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/profile',
    name: 'bidder-profile',
    redirect: { name: 'bidder-profile-info' },
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarProfileView'),
    props: {
      title: 'Профиль'
    },
    children: [
      ...profile
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        const candidateUser = await checkRole('user')
        if (candidate || candidateUser) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  {
    path: '/bidder/favorites',
    name: 'bidder-favorites',
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarFavoritesView'),
    props: {
      title: 'Избранное'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        throw new Error(apiError.BadRequest())
      }
    }
  },
  ...lot
]
