<template>
  <div class="form__control form__control-file">
    <h4 v-if="title" class="file__title h4">
      {{ title }}
    </h4>

    <VFileList
      v-if="files.length"
      show-delete
      :grid="grid"
      :files="files"
      :icon-name-btn-delete="iconNameBtnDelete"
      @onDelete="clearField"
    />

    <div v-if="isVisibleInput">
      <div :class="['label-file-flex', classFile]">
        <input
          type="file"
          class="input-file"
          :id="id"
          ref="file"
          :name="name"
          @change="entryFile"
        />

        <label :for="id" class="label-file label-file-underline">
        <span>
          {{label}}
        </span>

          <VIcon
            class="label-file-icon"
            width="33"
            height="33"
            icon-name="LoadFile"
          />
        </label>
      </div>

      <div v-if="errors?.length">
        <small class="error-small">{{ errors[0].$message }}</small>
      </div>
      <small v-if="description && !errors?.length" class="description">{{ description }}</small>
    </div>
  </div>
</template>

<script>
import VFileList from '@/components/list/FileList/VFileList'
import { fieldProps } from '@/mixin/props'
import { ref, computed, watch } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import { Alert } from '@/extension/Alert/Alert'

export default {
  emits: ['onDeleteFile'],
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: 'Загрузить документ'
    },
    id: {
      type: [String, Number],
      required: true
    },
    multiFile: {
      type: Boolean,
      required: false,
      default: false
    },
    classFile: {
      type: String,
      required: false
    },
    grid: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [Object, null]
    },
    pushModelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    iconNameBtnDelete: {
      type: String,
      required: false,
      default: 'DeleteFile'
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const file = ref(null)
    const files = ref([])

    const isVisibleInput = computed(() => {
      return !props.isDisabled && !(!props.multiFile && files.value.length)
    })

    const entryFile = async () => {
      const file0 = file.value?.files[0]
      if (!file0?.size) return

      if (file0.size < 100) {
        return await Alert.show('error', 'Размер файла слишком маленький')
      }
      files.value.push(file.value.files[0])
      emit('update:modelValue', files.value)
    }

    const getModelValue = computed(() => {
      return props.modelValue
    })

    watch(getModelValue, (val) => {
      if (!getModelValue.value) {
        file.value = null
        files.value.length = 0
      }
      if (val?.length) {
        files.value = val
      }
    })

    if (props.modelValue?.length) {
      files.value.push(...props.modelValue)
    }

    const clearField = (fileName, file) => {
      files.value.splice(file.index, 1)
      emit('update:modelValue', files.value)
      if (file.id) {
        emit('onDeleteFile', file.id)
      }
    }

    return {
      file,
      files,
      entryFile,
      clearField,
      isVisibleInput
    }
  },
  components: {
    VIcon,
    VFileList
  }
}
</script>
