import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-multiselect/dist/vue-multiselect.css'
import './styles/style.scss'

import { SetupCalendar } from 'v-calendar'
import 'v-calendar/dist/style.css'

createApp(App)
  .use(store)
  .use(router)
  .use(SetupCalendar, {})
  .mount('#app')
