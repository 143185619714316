import bidderMeta from '@/router/bidder/bidderMeta'

export default [
  {
    path: '/bidder/category/all',
    name: 'bidder-category-all',
    meta: bidderMeta,
    component: () => import('@/views/Bidder/Category/BidderCategoryListView')
  },
  {
    path: '/bidder/category/setting',
    name: 'bidder-category-setting',
    meta: bidderMeta,
    component: () => import('@/views/Bidder/Category/BidderSettingCategoryView')
  }
]
