<template>
  <div class="notifications-bar notification-js">

    <div class="notifications-bar__header">
      <button @click="toNotification" class="btn btn-link not-js">Все уведомления</button>
      <VIcon
        class="icon icon__btn-delete"
        icon-name="Close"
        @click="closeModal"
      />
    </div>

    <div class="notifications-bar__list">
      <VNotificationList
        :isWindow="true"
        :notifications="getNotifications"
      />
    </div>
  </div>
</template>

<script>
import VNotificationList from '@/components/list/notification/VNotificationList'
import VIcon from '@/components/ui/icon/VIcon'
import { useRouter } from 'vue-router'
import { useCloseOutsideModal } from '@/use/Base/useCloseOutsideModal'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  emits: ['close'],
  setup (props, { emit }) {
    const router = useRouter()
    const store = useStore()

    const closeModal = () => {
      emit('close')
    }

    const toNotification = () => {
      router.push({ name: 'notification-all' })
      closeModal()
    }

    const handleClick = (e) => {
      if (e.target.closest('.notification-js')) return
      emit('close')
    }

    const getNotifications = computed(() => {
      return store.getters['notification/getNotifications']
    })

    useCloseOutsideModal(handleClick)
    return {
      closeModal,
      getNotifications,
      toNotification
    }
  },
  components: {
    VIcon,
    VNotificationList
  }
}
</script>
