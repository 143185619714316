import { reactive } from 'vue'
import { spliceBy } from '@/utils/utils'
import { refreshArr } from '@/utils/reactive'

export default {
  namespaced: true,
  state () {
    return {
      offersWithoutFakeBidder: [],
      winnersList: [],
      primaryWinners: [],
      approveList: [],
      isOnlyTopRang: false,
      scroll: null,
      positions: [],
      activePosition: reactive([]),
      selItems: [],
      beforeResultPosition: {},
      activeWinners: []
    }
  },
  getters: {
    getOffersWithoutFakeBidder(state) {
      return state.offersWithoutFakeBidder
    },
    getWinnersList(state) {
      return state.winnersList
    },
    getPrimaryWinners(state) {
      return state.primaryWinners
    },
    getApproveList(state) {
      return state.approveList
    },
    getIsOnlyTopRang(state) {
      return state.isOnlyTopRang
    },
    getScroll(state) {
      return state.scroll
    },
    getPositions(state) {
      return state.positions
    },
    getSelItems(state) {
      return state.selItems
    },
    getBeforeResultPosition(state) {
      return state.beforeResultPosition
    },
    getActivePositions(state) {
      return state.activePosition
    },
    getActiveWinners(state) {
      return state.activeWinners
    }
  },
  mutations: {
    setOffersWithoutFakeBidder(state, offers) {
      state.offersWithoutFakeBidder = offers
    },
    setWinnerList(state, winner) {
      state.winnersList.push(winner)
    },
    setPrimaryWinners(state, winners) {
      state.primaryWinners = winners
    },
    toggleApproveList(state, item) {
      const idxItem = state.approveList.findIndex(apr => apr.key === item.key)
      if (idxItem !== -1) {
        state.approveList.splice(idxItem, 1)
      } else {
        state.approveList.push(item)
      }
    },
    toggleIsOnlyTopRang(state) {
      state.isOnlyTopRang = !state.isOnlyTopRang
      state.selItems = []
    },
    setScroll(state, event) {
      state.scroll = event
    },
    setPositions(state, positions) {
      state.positions = positions

      state.activePosition.length = 0
      state.activePosition.push(...positions)
    },
    setSelItems(state, items) {
      items.forEach(item => {
        if (!state.selItems.includes(item)) {
          state.selItems.push(item)
        } else {
          state.selItems = state.selItems.filter(selItem => selItem !== item)
        }
      })
    },
    setBeforeResultPosition(state, byPosition) {
      for (const offers of byPosition) {
        const positionId = offers[0]?.offers[0]?.position_id
        if (!positionId) continue

        state.beforeResultPosition[positionId] = offers
      }
    },
    deleteActivePosition(state, position) {
      spliceBy(position.id, state.activePosition)
    },
    addActivePositions(state, positions) {
      refreshArr(state.activePosition, positions)
    },
    setActiveWinners(state, winners) {
      state.activeWinners = winners
    }
  }
}
