<template>
  <!-- default width="15" height="9" -->
  <path d="M14 8.12012L7.21639 1.89606L1 8.12012" stroke="currentColor" stroke-width="2"/>
</template>

<script>
export default {
  name: 'IconAccordionArrow'
}
</script>
