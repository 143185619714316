<template>
  <div class="nav">
<!--    <LinkBidderInstruction class="color&#45;&#45;light-gray hover&#45;&#45;yellow mr-30" />-->

    <div class="nav__call">
      <span class="nav__call-text">Горячая линия по вопросам качества:</span>
      <a class="nav__call-link" href="tel:+73519499399">8 (3519) 499-399</a>
    </div>

    <TheNavNotification :is-auth="isAuth" />
    <TheNavPersonal :is-auth="isAuth" />
  </div>
</template>

<script setup>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import TheNavNotification from '@/components/header/module/TheNavNotification'
import TheNavPersonal from '@/components/header/module/TheNavPersonal'
// import LinkBidderInstruction from '@/components/ui/link/LinkBidderInstruction'

const store = useStore()
const isAuth = computed(() => {
  return store.getters['auth/getIsAuth']
})

defineComponent({
  TheNavNotification,
  TheNavPersonal
})
</script>
