import store from '@/store/index'

export class Alert {
  static alertStatus = ['success', 'warn', 'error', 'info']

  static async show (type, message) {
    if (!this.alertStatus.includes(type)) {
      throw new Error('Статус оповещения в модальном окне указан не корректно')
    }
    await store.dispatch('setAlert', {
      type: type,
      description: message
    })
  }
}
