import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
export function useNotificationAnim () {
  const store = useStore()
  const isSend = ref(false)

  const getUnreadItemDom = computed(() => {
    return store.getters['notification/getItemDom']
  })

  watch(getUnreadItemDom, () => {
    if (getUnreadItemDom.value.length) {
      document.addEventListener('scroll', handlerScrollPage)
    } else {
      document.removeEventListener('scroll', handlerScrollPage)
    }
  }, { deep: true })

  function handlerScrollPage () {
    const items = getUnreadItemDom.value
    items.forEach(async (item) => {
      controlChange(item)
    })
  }

  function controlChange (item) {
    const topItem = item.getBoundingClientRect().top

    if (topItem < window.innerHeight && topItem > 0) {
      store.commit('notification/setWriteNotification', Number(item.dataset.index))
      store.commit('notification/setSending', Number(item.dataset.index))
      sendChange()
    }
  }

  function sendChange () {
    if (isSend.value) return
    isSend.value = true
    setTimeout(async () => {
      const items = store.getters['notification/getSending']
      await store.dispatch('notification/writeNotification', items)
      store.commit('notification/clearSending')
      isSend.value = false
    }, 1000)
  }
}
