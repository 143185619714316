<template>
  <!-- default width="26" height="27"-->
  <rect x="0.587891" y="10.1504" width="23.9302" height="6.23779" stroke="currentColor"/>
  <rect x="0.587891" y="0.5" width="23.9302" height="6.23779" stroke="currentColor"/>
  <rect x="0.587891" y="19.8008" width="23.9302" height="6.23779" stroke="currentColor"/>
</template>

<script>
export default {
  name: 'IconToggleDisplayTable'
}
</script>
