export const sidebarAdmin = [
  {
    name: 'Пользователи',
    icon: 'users.svg',
    routeName: 'admin-users'
  },
  {
    name: 'Товарные категории',
    icon: 'filter.svg',
    routeName: 'admin-category'
  },
  {
    name: 'Торговые процедуры',
    icon: 'fair.svg',
    routeName: 'admin-trade'
  },
  {
    name: 'Тех. работы',
    icon: 'setting.svg',
    routeName: 'admin-setting'
  },
  {
    name: 'Доп. параметры',
    icon: 'parameters.svg',
    routeName: 'admin-parameters'
  },
  {
    name: 'Справочники',
    icon: 'manual.svg',
    routeName: 'admin-manual'
  },
  {
    name: 'Диагностика',
    icon: 'diagnostic.svg',
    routeName: 'admin-diagnostic'
  }
]
export const sidebarSecurity = [
  {
    name: 'Пользователи',
    icon: 'users.svg',
    routeName: 'security-users'
  },
  {
    name: 'Торговые процедуры',
    icon: 'fair.svg',
    routeName: 'security-trade'
  },
  {
    name: 'Заявки на аккредитацию',
    icon: 'fair.svg',
    routeName: 'security-accreditation'
  }
]
export const sidebarOrganizer = [
  {
    name: 'Создать ТП',
    icon: 'plus.svg',
    routeName: 'create-trade'
  },
  {
    name: 'ТП по шаблону',
    icon: 'template.svg',
    routeName: 'create-trade-layout'
  },
  {
    name: 'Заказ поставщика',
    icon: '1c.svg',
    routeName: 'trade-erp'
  },
  {
    name: 'Мои ТП',
    icon: 'lots.svg',
    routeName: 'organizer-my-trade'
  },
  {
    name: 'Реестр торгов',
    icon: 'fair.svg',
    routeName: 'organizer-register'
  },
  {
    name: 'Статистика и отчеты',
    icon: 'graph.svg',
    routeName: 'organizer-graph'
  }
]
export const sidebarBidder = [
  {
    name: 'Мои аукционы',
    icon: 'auction.svg',
    routeName: 'bidder-my-auctions'
  },
  {
    name: 'Торговые процедуры',
    icon: 'fair.svg',
    routeName: 'bidder-trade'
  },
  {
    name: 'Избранное',
    icon: 'favorites.svg',
    routeName: 'bidder-favorites'
  },
  {
    name: 'Мои категории',
    icon: 'filter.svg',
    routeName: 'bidder-category'
  }
]
export const sidebarUser = [
  {
    name: 'Профиль',
    icon: 'user.svg',
    routeName: 'bidder-profile'
  }
]

export const sidebarShowcase = [
  {
    name: 'Торговые процедуры',
    icon: 'fair.svg',
    routeName: 'showcase'
  },
  {
    name: 'Новые',
    icon: 'new.svg',
    routeName: 'showcase-new'
  }
]
