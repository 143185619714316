import { WebSocketCentrifuge } from '@/modules/websocket/centrifuge/WebSocketCentrifuge'
import { useStore } from 'vuex'
import { computed } from 'vue'

export function useWebSocket () {
  const store = useStore()
  const ws = new WebSocketCentrifuge()

  const userId = computed(() => {
    return store.getters['auth/getUser']?.id
  })

  function connect () {
    if (!userId.value) {
      return
    }

    ws.connect()
    ws.subscribe(`User_${userId.value}`, 'subscribeUser')
  }

  function disconnect () {
    ws.disconnect()
  }

  return {
    connect,
    disconnect,
    ws
  }
}
