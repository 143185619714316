export default {
  namespaced: true,
  state () {
    return {
      // default state = true
      isDisplayTable: true
    }
  },
  getters: {
    getIsDisplayTable (state) {
      return state.isDisplayTable
    }
  },
  mutations: {
    setIsDisplayTable (state, boolean) {
      state.isDisplayTable = boolean
    }
  }
}
