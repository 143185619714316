import notificationMeta from '../notification/notificationMeta'

export default [
  {
    path: '/notification/all',
    alias: ['/notification', '/notification/'],
    name: 'notification-all',
    component: () => import('@/views/Notification/NotificationView'),
    meta: {
      ...notificationMeta,
      layout: 'main-layout'
    }
  }
  // {
  //   path: '/notification/new',
  //   name: 'notification-new',
  //   component: () => import('@/views/Notification/NotificationView'),
  //   meta: {
  //     ...notificationMeta,
  //     layout: 'main-layout'
  //   }
  // }
]
