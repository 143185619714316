const attention = {
  title: 'Внимание',
  status: 'attention'
}
const information = {
  title: 'Информация',
  status: 'change'
}

export const listOptionsNotification = new Map([
  ...paintItemInOptionsList(attention, 3, 6, 8),
  ...paintItemInOptionsList(information, 7, 4, 11),
  [12, {
    title: 'Победитель',
    status: 'win'
  }]
])

function paintItemInOptionsList (object, ...args) {
  const list = []
  args.forEach(arg => {
    list.push([arg, object])
  })

  return list
}
