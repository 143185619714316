import { Alert } from '@/extension/Alert/Alert'
import { getNotification } from '@/http/notificationApi'
import store from '@/store'
import { transformNotifications } from '@/utils/notification/notification.function'

export function notificationMiddleware () {
  (async function getNotificationList () {
    try {
      if (!store.getters['auth/getIsAuth'] || store.getters['notification/getTimeout']) return
      store.commit('notification/toggleTimeout')
      const { data } = await getNotification()
      const unreadNotification = data.filter(not => !not.reading_at)
      store.commit('notification/setNotifications', transformNotifications(data))
      store.commit('notification/setUnreadNotifications', transformNotifications(unreadNotification))
      setTimeout(() => {
        store.commit('notification/toggleTimeout')
      }, 10000)
    } catch (e) {
      await Alert.show('error', ' Не удалось получить уведомления')
    }
  })()
}
