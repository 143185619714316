<template>
  <rect x="0.75" y="4.75" width="15.7941" height="18.2647" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M2.70557 5V1H19.9997V20.7647H16.5" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M4.20605 13L8.20605 17.5L13.2061 9.5" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconSaveTemplate'
}
</script>
