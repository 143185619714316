<template>
  <div class="cookies">
    <div class="container">
      <div class="cookies__inner">
        <div class="cookies__text">
          Этот сайт использует файлы cookie для хранения данных. Продолжая использовать сайт, вы даете свое согласие на работу с этими файлами.
          <a class="btn btn-link cookies__text" target="_blank" href="https://sitno.ru/politika-obrabotki-personalnyh-dannyh">Подбробнее</a>
        </div>
        <VIcon
          class="icon icon__btn-delete"
          icon-name="Close"
          @click="emits('close')"
        />
      </div>
    </div>
    </div>
</template>

<script setup>
import { defineEmits, defineExpose } from 'vue'
import { goCookiePolitic } from '@/utils/goRoute'
import VIcon from '@/components/ui/icon/VIcon'

const emits = defineEmits(['close'])

defineExpose({
  emits,
  goCookiePolitic
})
</script>
