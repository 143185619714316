import { $authHost } from '@/http'

export const followLot = async (lotId, userId) => {
  const { data } = await $authHost.put(`/users/${userId}/follow`, { lot: lotId })
  return data
}

export const unfollowLot = async (lotId, userId) => {
  const { data } = await $authHost.put(`/users/${userId}/stop-follow`, { lot: lotId })
  return data
}
