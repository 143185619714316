import adminMeta from '@/router/admin/adminMeta'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/admin/diagnostic/error',
    name: 'admin-diagnostic-error',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Diagnostics/AdminDiagnosticErrorView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  // {
  //   path: '/admin/diagnostic/set',
  //   name: 'admin-diagnostic-set',
  //   meta: {
  //     ...adminMeta,
  //     layout: 'main-sidebar-layout'
  //   },
  //   component: () => import('@/views/Admin/Diagnostics/AdminDiagnosticStatisticsSETView'),
  //   async beforeEnter (to, from, next) {
  //     try {
  //       const candidate = await checkRole('admin')
  //       if (candidate) {
  //         return next()
  //       } else {
  //         return next({ name: 'login' })
  //       }
  //     } catch (e) {
  //     }
  //   }
  // }
]
