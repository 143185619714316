<template>
  <!-- default width="14" height="14" -->
<!--  <path d="M10.7702 5.77639L10.7699 5.77654C10.77 5.77649 10.7701 5.77644 10.7702 5.77639L10.882 6L10.7702 5.77639ZM10.3229 6L0.75 10.7865L0.750001 1.21352L10.3229 6Z" stroke="#233F1F" stroke-width="1.5"/>-->
  <path d="M1 13L13 0.999962" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M1 1L13 13" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>
