import securityMeta from '@/router/security/securityMeta'

export default [
  {
    path: '/security/trade/active',
    name: 'security-trade-active',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Trade/SecurityTradeActiveView')
  },
  {
    path: '/security/trade/archive',
    name: 'security-trade-archive',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Trade/SecurityTradeArchiveView')
  }
]
