<template>
  <!-- default width="11" height="7" -->
  <path d="M10 1L5.30366 5L1 1" stroke="currentColor" stroke-width="2"/>
</template>

<script>
export default {
  name: 'IconArrow'
}
</script>
