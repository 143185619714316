import { Alert } from '@/extension/Alert/Alert'
import { ApiError } from '@/extension/Error/ApiError'

export class RenderApiError extends ApiError {
  async showAlert (e, msg = '') {
    if (msg) {
      return await Alert.show('error', msg)
    }
    const status = e.response?.data?.status || 500
    const message = e.response?.data?.message || this.getMessage(status)
    await Alert.show('error', message)
  }

  async showAlertByStatus (status, msg) {
    if (msg) {
      return await Alert.show('error', msg)
    }
    const message = this.getMessage(status)
    await Alert.show('error', message)
  }
}
