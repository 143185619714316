import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useBreadcrumbs() {
  const route = useRoute()
  const store = useStore()
  const breadcrumbs = route.meta?.breadcrumbs

  if (!breadcrumbs) return

  // Sets way current user to personal area
  if (breadcrumbs.length) {
    breadcrumbs.forEach(breadcrumb => {
      if (breadcrumb.isCheckRole) {
        breadcrumb.routeName = store.getters['auth/getRole']
      }

      if (breadcrumb.paramsKey) {
        breadcrumb.name = 'Лот № ' + route.params[breadcrumb.paramsKey]
      }
    })
  }

  return {
    breadcrumbs
  }
}
