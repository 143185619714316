export default {
  namespaced: true,
  state () {
    return {
      categoryList: [],
      myCategory: [],
      activeCategoryList: [],
      changeCategoryList: {}
    }
  },
  getters: {
    getCategoryList (state) {
      return state.categoryList
    },
    getActiveCategoryList (state) {
      return state.activeCategoryList
    },
    getChangeCategoryList (state) {
      return state.changeCategoryList
    },
    getMyCategory (state) {
      return state.myCategory
    }
  },
  mutations: {
    setCategoryList (state, list) {
      state.categoryList = list
    },
    setActiveCategoryList (state, list) {
      state.activeCategoryList = list
    },
    changeCategoryList (state, { email, id }) {
      if (!state.changeCategoryList[email]) {
        state.changeCategoryList[email] = [id]
        return
      }

      const item = state.changeCategoryList[email].find(el => el === id)

      if (item) {
        state.changeCategoryList[email] = state.changeCategoryList[email].filter(el => el !== id)

        if (!state.changeCategoryList[email].length) {
          delete state.changeCategoryList[email]
        }
      } else {
        state.changeCategoryList[email].push(id)
      }
    },
    clearChangeCategoryList (state) {
      state.changeCategoryList = {}
    },
    deleteCategory (state, { email, categories }) {
      state.changeCategoryList[email] = categories
    },
    setMyCategory (state, categories) {
      state.myCategory = categories
    }
  }
}
