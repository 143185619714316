export default {
  namespaced: true,
  state () {
    return {
      files: [],
      queueForDelete: []
    }
  },
  getters: {
    getFiles (state) {
      return state.files
    },
    getQueueForDelete (state) {
      return state.queueForDelete
    }
  },
  mutations: {
    setFiles (state, files) {
      state.files.length = 0
      if (!files) return
      state.files.push(...files)
    },
    setFile (state, file) {
      state.files.push(file)
    },
    deleteFile (state, id) {
      state.queueForDelete.push(id)
      state.files = state.files.filter(file => file.id !== id)
    },
    clearQueueFile (state) {
      state.queueForDelete.length = 0
    }
  },
  actions: {
  }
}
