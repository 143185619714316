<template>
  <LinkFile
    v-if="!isAuth || isBidder || isUser"
    text="Инструкция для Участника ТП"
    href="instruction/instruction_bidder.pdf"
  />
</template>

<script setup>
import LinkFile from '@/components/ui/buttons/LinkFile'
import { computed } from 'vue'
import store from '@/store'

const isAuth = computed(() => store.getters['auth/getIsAuth'])
const isUser = computed(() => store.getters['auth/isUser'])
const isBidder = computed(() => store.getters['auth/isBidder'])
</script>
