import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { reactive } from 'vue'
import { Alert } from '@/extension/Alert/Alert'

export function FormHandler (formAction = null) {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()

  const serverErrors = reactive({})

  const handlerErrors = async () => {
    serverErrors.value = {}
    try {
      await formAction()
    } catch (e) {
      await Alert.show('error', e?.response?.data?.message)
      if (e?.response?.data?.errors) {
        serverErrors.value = e?.response?.data?.errors
      }
    }
  }

  return {
    store,
    router,
    route,
    serverErrors,
    handlerErrors
  }
}
