import store from '@/store'
import { checkAccessToken } from '@/http/tokenApi'
import {
  clearView,
  isNotAuthorizedAndHasAToken,
  isQueryHasAccessToken
} from '@/router/middleware/auth.middleware.function'

function setAccessToken (to) {
  localStorage.setItem('accessString', to.query['access-string'])
}

async function authorizationUser () {
  try {
    const { data } = await checkAccessToken()
    store.commit('auth/setUser', data)
    store.commit('auth/setIsAuth', true)
  } catch (e) {
    store.commit('auth/setIsAuth', false)
    localStorage.removeItem('accessToken')
  }
}

export async function authMiddleware (to, from, next) {
  const requireAuth = to.meta.auth
  const isLogin = to.meta.login

  if (to.query.smart) {
    store.commit('redirect/setRoute', to.path)
  }

  clearView()
  if (isQueryHasAccessToken(to)) {
    setAccessToken(to)
  }

  if (isNotAuthorizedAndHasAToken()) {
    await authorizationUser()
  }

  if (requireAuth && store.getters['auth/getIsAuth']) {
    return next()
  } else if (requireAuth && !store.getters['auth/getIsAuth']) {
    return next({ name: 'showcase' })
  } else if (isLogin && store.getters['auth/getIsAuth']) {
    return next({ name: 'showcase' })
  } else {
    return next()
  }
}
