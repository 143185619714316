<template>
  <!-- default width="27" height="27" -->
  <circle cx="13.5" cy="13.5" r="13.5" fill="#F3F5F3"/>
  <path d="M8 19L19 7.99996" stroke="#899787" stroke-width="1.5"/>
  <path d="M8 8L19 19" stroke="#899787" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconDeleteFile'
}
</script>
