<template>
  <!-- default width="29" height="29" -->
  <path class="socials__item-icon" d="M21.9789 10.6509C21.9789 10.6509 21.8695 10.3223 21.1901 10.4127L18.9409 10.4291C18.7691 10.4044 18.6441 10.4784 18.6441 10.4784C18.6441 10.4784 18.5113 10.5523 18.4489 10.733C18.0818 11.7267 17.6132 12.5809 17.6132 12.5809C16.6136 14.3713 16.2075 14.4699 16.0435 14.3549C15.6608 14.1003 15.7623 13.3201 15.7623 12.7616C15.7623 11.0204 16.0122 10.2977 15.2703 10.1088C15.0282 10.0513 14.8486 10.0102 14.216 10.002C13.4116 9.9938 12.7321 10.002 12.3495 10.1991C12.0917 10.3305 11.8965 10.6262 12.0136 10.6426C12.162 10.6673 12.4978 10.7412 12.6775 10.9958C12.6775 10.9958 12.8727 11.3325 12.904 12.0717C12.9899 14.1167 12.5916 14.3713 12.5916 14.3713C12.287 14.5438 11.7559 14.2563 10.9749 12.5645C10.9749 12.5645 10.522 11.7267 10.1705 10.8069C10.1081 10.6344 9.98311 10.5441 9.98311 10.5441C9.98311 10.5441 9.84254 10.4291 9.63948 10.3962L7.49961 10.4126C7.1716 10.4126 7.05446 10.5687 7.05446 10.5687C7.05446 10.5687 6.93731 10.7001 7.04665 10.9711C8.71793 15.1023 10.6235 17.1719 10.6235 17.1719C10.6235 17.1719 12.3651 19.0856 14.3409 18.9542H15.2391C15.5124 18.9295 15.653 18.7735 15.653 18.7735C15.653 18.7735 15.7779 18.6256 15.7701 18.3546C15.7545 17.0898 16.3168 16.9009 16.3168 16.9009C16.8635 16.7202 17.5664 18.1246 18.3161 18.6667C18.3161 18.6667 18.8862 19.0774 19.3157 18.987L21.315 18.9542C22.3694 18.946 21.8695 18.0261 21.8695 18.0261C21.8305 17.9522 21.5806 17.3855 20.3779 16.211C19.1283 14.9873 19.2923 15.1844 20.8074 13.0654C21.729 11.776 22.096 10.9875 21.9789 10.6508V10.6509Z" fill="currentColor"></path>
  <circle class="socials__item-circle" cx="14.5" cy="14.5" r="14" stroke="currentColor"></circle>
</template>

<script>
export default {
  name: 'IconVK'
}
</script>
