<template>
  <!-- default width="25" height="25" -->
  <circle cx="12.5" cy="12.5" r="12.5" fill="#FFED00"/>
  <path d="M6 8H19" stroke="black" stroke-width="1.5"/>
  <path d="M6 13H19" stroke="black" stroke-width="1.5"/>
  <path d="M6 18H19" stroke="black" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconRival'
}
</script>
