import { Request } from '@/service/api/Request/Request'
import { ManualService } from '@/service/api/ManualService'
import store from '@/store'

export class ManualController extends Request {
  #manualService = new ManualService()

  async setManualByKey({ isAuth, key }) {
    try {
      const { data } = await this.#manualService.setManualByKey({ isAuth, key })
      store.commit('manual/setManual', { key, items: data })
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
