export default {
  namespaced: true,
  state () {
    return {
      isEdit: false
    }
  },
  getters: {
    getIsEdit (state) {
      return state.isEdit
    }
  },
  mutations: {
    setIsEdit (state, boolean) {
      state.isEdit = boolean
    }
  }
}
