<template>
  <!-- default width="14" height="14" -->
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.85961 4.35871L7.40605 5.63986L6.44911 6.79496L2.53738 3.5543L7.13126 0.382812L7.98346 1.61722L6.16709 2.87118L8.49587 3.06003C15.1064 3.59609 15.0438 13.2973 8.42707 13.7483L8.37607 13V13.75H0V12.25H8.34964C13.09 11.903 13.1265 4.94047 8.37463 4.55512L5.95255 4.35871H5.85961Z" fill="#233F1F"/>
</template>

<script>
export default {
  name: 'Extend'
}
</script>
