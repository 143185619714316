<template>
  <div class="nav__personal-window">
    <div class="nav__personal-inner" tabindex="-1">
      <ul
        class="nav__personal-list"
      >
        <VIcon
          class="nav__personal-triangle"
          width="30"
          height="22"
          icon-name="Triangle"
        />
        <li
          class="nav__personal-link"
          v-for="item in list"
          :key="item.title"
          @click="toAction(item)"
        >
          <v-icon
            :icon-name="item.iconName"
            :class="item.iconName"
            width="37"
            height="37"
          />
          {{ item.title }}
        </li>
      </ul>
<!--      <div v-if="isAdmin">-->
<!--        <div-->
<!--          v-for="item in adminsRoute"-->
<!--          :key="item.title"-->
<!--          class="nav__personal-link"-->
<!--          @click="$router.push(item.route)"-->
<!--        >-->
<!--          {{ item.title }}-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="close__modal-personal"></div>
  </div>
</template>

<script setup>
import { defineEmits, defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useCloseOutsideModal } from '@/use/Base/useCloseOutsideModal'
import VIcon from '@/components/ui/icon/VIcon'
import { bidderNav, unAuthUser, organizerNav, allUserNav, adminsNav } from '@/utils/consts/personaList'

const emits = defineEmits(['close', 'open'])

const store = useStore()
const router = useRouter()

const list = computed(() => {
  return navMap.get(store.getters['auth/getRole'])
})

const clickLogout = async () => {
  await store.dispatch('auth/logout')
  await router.push({ name: 'login' })
}

const toAction = async (item) => {
  if (item.route) {
    await router.push(item.route)
    return
  }

  if (item.action) {
    await actionsMap.get(item.action)()
  }
}

const toPersonalArea = async (routeName) => {
  if (routeName) {
    await router.push({ name: routeName })
    return
  }

  const name = linkMap.get(store.getters['auth/getRole'])
  await router.push({ name: name })
}

const navMap = new Map([
  ['user', unAuthUser],
  ['bidder', bidderNav],
  ['organizer', organizerNav],
  ['security', allUserNav],
  ['admin', adminsNav]
])

const actionsMap = new Map([
  ['error', () => emits('open')],
  ['instruction', () => {
    const url = window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
    window.open(`${url}/instruction/instruction_bidder.pdf`, '_blank')
  }],
  ['personal', toPersonalArea],
  ['logout', clickLogout]
])

const linkMap = new Map([
  ['user', 'bidder-profile-info'],
  ['bidder', 'bidder'],
  ['organizer', 'organizer'],
  ['security', 'security'],
  ['admin', 'admin']
])

const handleClick = (e) => {
  if (e.target.closest('.nav__personal')) return
  emits('close')
}

defineComponent({
  VIcon
})

useCloseOutsideModal(handleClick)
</script>
