<template>
  <!-- default width="50" height="50" -->
  <circle cx="24.9987" cy="25" r="21.4167" stroke="#D41B1B" stroke-width="3"/>
  <path d="M26.5 14C26.5 13.1716 25.8284 12.5 25 12.5C24.1716 12.5 23.5 13.1716 23.5 14H26.5ZM23.5 28.5C23.5 29.3284 24.1716 30 25 30C25.8284 30 26.5 29.3284 26.5 28.5H23.5ZM23.5 14V28.5H26.5V14H23.5Z" fill="#D41B1B"/>
  <path d="M26.5 34C26.5 33.1716 25.8284 32.5 25 32.5C24.1716 32.5 23.5 33.1716 23.5 34H26.5ZM23.5 36C23.5 36.8284 24.1716 37.5 25 37.5C25.8284 37.5 26.5 36.8284 26.5 36H23.5ZM23.5 34V36H26.5V34H23.5Z" fill="#D41B1B"/>
</template>

<script>
export default {
  name: 'IconModalSuccess'
}
</script>
