<template>
  <!-- default width="38" height="38" -->
  <rect width="38" height="38" rx="19" fill="white"/>
  <path d="M11 19.125H27.25" stroke="currentColor" stroke-width="1.5"/>
  <path d="M19.125 11L19.125 27.25" stroke="currentColor" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconAccordionAdd'
}
</script>
