<script setup>
import VModal from '@/components/ui/modal/VModal'
import VInput from '@/components/ui/form/VInput'
import VPhone from '@/components/ui/form/VMask'
import { defineEmits, defineProps } from 'vue'
import VFile from '@/components/ui/form/File/VFile'
import { useError } from '@/use/Form/useError'

const emit = defineEmits(['close', 'send'])

defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
})

const { formData, validateField, v$, onSubmit, isSend } = useError(emit)

const handlerClose = () => {
  onSubmit()
}

</script>

<template>
  <VModal
    v-if="isOpen"
    modalInnerClass="width-885 flex-direction"
    scroll-y
    @close="$emit('close')"
  >
    <h2 class="modal__title mb-40">Сообщить об ошибке</h2>
    <p class="mb-30">Уважаемые Партнёры, в связи недавней разработкой тендерной площадки, на ней могут возникать ошибки,
    мешающие польноценному использованию функционала. Если вы столкнулись с ошибками,
    сообщите нам о ней, заполнив краткую форму. Разработчик ЭТП, при необходимости,
    свяжется с Вами, для уточнения деталей и устранит ошибку. Спасибо Вам за помощь в улучшении
    тендерной площадки.</p>
    <VInput
      name="name"
      id="name"
      validator-name="onlyText"
      v-model="formData.name"
      :errors="v$.name.$errors"
      @blur="validateField"
      @input="validateField"
      :class-input="['input input-long input-placeholder-black input-border-bottom']"
      placeholder="Как к вам обращаться*"
    />

    <VPhone
      type="phone"
      id="phone"
      name="phone"
      mask-type
      placeholder="Номер телефона"
      :class-input="['input input-long input-placeholder-black']"
      v-model="formData.phone"
    />

    <VInput
      name="email"
      id="email"
      type="text"
      v-model="formData.email"
      :class-input="['input input-long input-placeholder-black input-border-bottom']"
      placeholder="E-mail"
    />

    <VInput
      name="description"
      id="description"
      v-model="formData.description"
      :errors="v$.description.$errors"
      @blur="validateField"
      @input="validateField"
      :maxLength="1500"
      :class-input="['input input-long input-placeholder-black input-border-bottom']"
      placeholder="Описание ошибки*"
    />

    <VFile
      label="Прикрепить файл"
      class="max-w-initial mb-30"
      id="files"
      name="files"
      icon-name-btn-delete="DeleteFile"
      multi-file
      grid
      v-model="formData.files"
    />

    <button
      class="btn btn-primary btn-bg-green"
      :disabled="isSend"
      @click="handlerClose"
    >
      Отправить
    </button>
    <div class="text-center mt-30">
      Пользуясь сайтом или отправляя любые формы запросов,
      <br>
      Вы соглашаетесь с условиями <a class="btn btn-link" href="">&#60;&#60;Положение о конфидециальности&#62;&#62;</a>
    </div>
  </VModal>
</template>
