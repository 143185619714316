<template>
  <!-- default width="16" height="20" -->
  <path d="M1.89438 6.03304C1.87439 5.88317 1.99099 5.75 2.14218 5.75H13.8578C14.009 5.75 14.1256 5.88317 14.1056 6.03304L12.3723 19.033C12.3557 19.1572 12.2498 19.25 12.1245 19.25H3.87552C3.75022 19.25 3.64427 19.1572 3.62771 19.033L1.89438 6.03304Z" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M0 3H16" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M8 0L8 3" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M5.5 8L6.5 17" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconDelete'
}
</script>
