<template>
  <!-- default width="45" height="45" -->
  <path d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3049 22.9734C25.2575 22.9734 27.6512 20.5798 27.6512 17.6271C27.6512 14.6745 25.2575 12.2809 22.3049 12.2809C19.3522 12.2809 16.9586 14.6745 16.9586 17.6271C16.9586 20.5798 19.3522 22.9734 22.3049 22.9734ZM22.3049 24.2543C25.965 24.2543 28.932 21.2872 28.932 17.6271C28.932 13.9671 25.965 11 22.3049 11C18.6448 11 15.6777 13.9671 15.6777 17.6271C15.6777 21.2872 18.6448 24.2543 22.3049 24.2543Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8828 32.7194C29.7867 32.1512 29.6268 31.5942 29.4053 31.0593C29.019 30.1269 28.453 29.2797 27.7393 28.566C27.0257 27.8524 26.1785 27.2863 25.246 26.9001C24.3136 26.5138 23.3143 26.3151 22.305 26.3151C21.2958 26.3151 20.2964 26.5138 19.364 26.9001C18.4316 27.2863 17.5843 27.8524 16.8707 28.566C16.1571 29.2797 15.591 30.1269 15.2048 31.0593C14.9832 31.5942 14.8233 32.1512 14.7272 32.7194L29.8828 32.7194ZM31.1792 32.7194C31.0728 31.9825 30.8748 31.2601 30.5886 30.5691C30.1381 29.4813 29.4776 28.4929 28.645 27.6603C27.8124 26.8277 26.824 26.1673 25.7362 25.7167C24.6484 25.2661 23.4825 25.0342 22.305 25.0342C21.1276 25.0342 19.9616 25.2661 18.8738 25.7167C17.786 26.1673 16.7976 26.8277 15.965 27.6603C15.1324 28.4929 14.472 29.4813 14.0214 30.5691C13.7352 31.2601 13.5372 31.9825 13.4308 32.7194C13.3697 33.1427 13.3389 33.5708 13.3389 34.0003L22.305 34.0003H31.2712C31.2712 33.5708 31.2403 33.1427 31.1792 32.7194Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6646 25.8136V23.4746H22.9454V25.8136H21.6646Z" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconUser'
}
</script>

<style scoped>

</style>
