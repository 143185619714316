import adminMeta from '@/router/admin/adminMeta'
import users from './users/users'
import diagnostics from './diagnostic/diagnostics'
import trade from './trade/trade'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'admin-users-all' },
    meta: {
      ...adminMeta
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/admin/create-user',
    name: 'admin-users-create',
    component: () => import('@/views/Admin/AdminUsersCreateUserView'),
    meta: {
      ...adminMeta,
      layout: 'main-layout',
      back: {
        name: 'admin-users-all'
      }
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    redirect: { name: 'admin-users-all' },
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarUsersView'),
    props: {
      title: 'Пользователи'
    },
    children: [
      ...users
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/trade',
    name: 'admin-trade',
    redirect: { name: 'admin-trade-active' },
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarTradeView'),
    props: {
      title: 'Торговые процедуры'
    },
    children: [
      ...trade
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/setting',
    name: 'admin-setting',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarSettingWorkView'),
    props: {
      title: 'Тех. работы'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/manual',
    name: 'admin-manual',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarManualView'),
    props: {
      title: 'Справочник'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/diagnostic',
    name: 'admin-diagnostic',
    redirect: { name: 'admin-diagnostic-error' },
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarDiagnosticView'),
    children: [
      ...diagnostics
    ],
    props: {
      title: 'Диагностика'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/category',
    name: 'admin-category',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarCategoryView'),
    props: {
      title: 'Товарные категории'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  },
  {
    path: '/admin/parameters',
    name: 'admin-parameters',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Sidebar/AdminSidebarParametersView'),
    props: {
      title: 'Доп. параметры'
    },
    children: [
      {
        path: ':id',
        name: 'admin-parameter',
        meta: {
          ...adminMeta,
          layout: 'main-sidebar-layout'
        },
        component: () => import('@/views/Admin/Parameters/AdminParametersView'),
        props: {
          title: 'Доп. параметры'
        }
      }
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
      }
    }
  }
]
