function createFormDataNewFiles (files, {
  type = null,
  sort = null
}) {
  if (!files?.length) return false

  const formData = new FormData()
  let count = 0

  files.forEach(file => {
    if (!file.id) {
      formData.append('files[]', file)
      count += 1
    }
  })

  if (!count) return false

  if (type) {
    formData.append('type', type)
  }

  if (sort) {
    formData.append('sort', sort)
  }

  return formData
}

// ��� "= {}" ��������, ��� �� ������� ������ ��������
function createArrayFormDataNewFilesType (formData, { prefixKey = 'files_', removePrefix = false } = {}) {
  const arrayFormData = []

  for (const key in formData) {
    if (!key.includes(prefixKey)) continue

    const filesType = removePrefix ? key.replace(prefixKey, '') : key

    const formDataNewFilesType = createFormDataNewFiles(formData[key], { type: filesType })
    if (!formDataNewFilesType) continue

    arrayFormData.push(formDataNewFilesType)
  }

  return arrayFormData
}

function updateFormDataFilesType (formData, updateData, prefixKex = 'files_') {
  for (const key in updateData) {
    if (!key.includes(prefixKex)) continue

    formData[key] = updateData[key]
  }
}

export {
  createFormDataNewFiles,
  createArrayFormDataNewFilesType,
  updateFormDataFilesType
}
