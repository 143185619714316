<template>
  <path d="M0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.895431 0 0 0.89543 0 2Z" fill="#233F1F"/>
  <path d="M0 13C0 14.1046 0.895431 15 2 15C3.10457 15 4 14.1046 4 13C4 11.8954 3.10457 11 2 11C0.895431 11 0 11.8954 0 13Z" fill="#233F1F"/>
  <path d="M0 24C0 25.1046 0.895431 26 2 26C3.10457 26 4 25.1046 4 24C4 22.8954 3.10457 22 2 22C0.895431 22 0 22.8954 0 24Z" fill="#233F1F"/>
</template>

<script>
export default {
  name: 'IconMenuLot'
}
</script>
