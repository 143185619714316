<template>
  <!-- default width="48" height="48" -->
  <rect width="48" height="48" rx="3" fill="#FFED00"/>
  <path d="M38 31H10" stroke="currentColor" stroke-width="1.5"/>
  <path d="M38 17H10" stroke="currentColor" stroke-width="1.5"/>
  <circle cx="18" cy="31" r="3" fill="currentColor"/>
  <circle cx="27" cy="17" r="3" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconFilter'
}
</script>
