import { AccreditationService } from '@/service/api/AccreditationService'
import { Alert } from '@/extension/Alert/Alert'
import { Request } from '@/service/api/Request/Request'

export class AccreditationController extends Request {
  #accreditationService = new AccreditationService()

  async applicationAccreditation (userId) {
    try {
      const message = await this.#accreditationService.applicationAccreditation(userId)
      await Alert.show('success', message)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
