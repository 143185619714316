import securityMeta from '@/router/security/securityMeta'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/security/accreditation/active',
    name: 'security-accreditation-active',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Accreditation/SecurityAccreditationActiveView')
  },
  {
    path: '/security/accreditation/archive',
    name: 'security-accreditation-archive',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Accreditation/SecurityAccreditationArchiveView')
  },
  {
    path: '/security/accreditation/:id',
    name: 'security-accreditation-info',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Accreditation/SecurityAccreditationItemView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  }
]
