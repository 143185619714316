<template>
  <!-- default width="18" height="18" -->
  <path d="M4.64813 4.48464L13.2537 8.35135L7.95746 13.3196L4.64813 4.48464Z" fill="#233F1F"/>
  <rect x="11.1416" y="8.79688" width="4.3213" height="3.25631" transform="rotate(47.3074 11.1416 8.79688)" fill="#233F1F"/>
  <path d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM1.54671 9C1.54671 13.1163 4.88366 16.4533 9 16.4533C13.1163 16.4533 16.4533 13.1163 16.4533 9C16.4533 4.88366 13.1163 1.54671 9 1.54671C4.88366 1.54671 1.54671 4.88366 1.54671 9Z" fill="#233F1F"/>
  <path d="M15 3.5L3 14" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconDeactivate'
}
</script>
