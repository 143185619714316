export const PARTNERS = 'partners'
export const ORGANIZATIONS = 'organizations'
export const COMPANY = 'company'
export const PROCEDURE_TYPE = 'procedure-type'
export const CATEGORY_LOT = 'category'
export const CONDITIONS_DELIVERY = 'delivery-condition'
export const SPECIAL_CONDITIONS_DELIVERY = 'construction/special-condition'
export const CRITERIAL_EXECUTION = 'construction/criteria-execution'
export const TERM_CONTRACT = 'construction/term-contract'
export const CONDITIONS_PAYMENT = 'payment-term'
export const CURRENCY = 'currency'
export const MEASUREMENT = 'measurement'
export const RELATION = 'relation-to-products'

export const PARAMETER_TYPE = 'parameter-type'
export const PARAMETER_DEPARTMENT = 'parameter-department'
export const PARAMETER_CATEGORY = 'parameter-category'
