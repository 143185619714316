import securityMeta from '@/router/security/securityMeta'
import profile from '@/router/security/profile/index'
import { checkRole } from '@/utils/utils'
import { Alert } from '@/extension/Alert/Alert'

export default [
  {
    path: '/security/users/:id',
    name: 'security-user',
    redirect: { name: 'security-profile-info' },
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Profile/SecurityProfileUserView'),
    props: {
      title: 'Профиль'
    },
    children: [
      ...profile
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        await Alert.show('error', 'Что-то пошло не так')
      }
    }
  },
  {
    path: '/security/users/users-all',
    name: 'security-users-all',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Users/SecurityUsersAllUsersView')
  },
  {
    path: '/security/users/members-tp',
    name: 'security-users-members-tp',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Users/SecurityUsersMembersTPView')
  },
  {
    path: '/security/users/banned',
    name: 'security-users-banned',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Users/SecurityUsersBannedView')
  }
]
