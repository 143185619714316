<script setup>
import { computed, defineProps, ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import TheModalNotification from '@/components/ui/modal/header/TheModalNotification'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

defineProps({
  isAuth: {
    type: Boolean,
    required: true
  }
})

const isOpenNotification = ref(false)
const store = useStore()
const router = useRouter()

const getNotificationCounter = computed(() => {
  return store.getters['notification/getUnreadNotifications']?.length
})

const handlerNotificationModal = () => {
  if (!getNotificationCounter.value) {
    router.push({ name: 'notification-all' })
    return
  }
  isOpenNotification.value = !isOpenNotification.value
}
</script>

<template>
  <div
    class="nav__notification notification-js notification--header"
    v-if="isAuth"
    @click="handlerNotificationModal"
    :class="[isOpenNotification ? 'show-window' : '']"
  >
    <VIcon
      width="54"
      icon-name="Notification"
      data-notification="notification"
    />
    <div v-if="getNotificationCounter" class="notification--header__counter">{{ getNotificationCounter }}</div>
  </div>

  <TheModalNotification v-if="isOpenNotification" @close="handlerNotificationModal"/>
</template>
