<template>
  <!-- default width="54" height="45" -->
  <path data-notification="notification" d="M45.8564 22.5C45.8564 34.9264 35.7829 45 23.3564 45C10.93 45 0.856445 34.9264 0.856445 22.5C0.856445 10.0736 10.93 0 23.3564 0C35.7829 0 45.8564 10.0736 45.8564 22.5Z" fill="white"/>
  <path data-notification="notification" d="M27.2579 30.4297C27.2579 31.3331 26.899 32.1995 26.2602 32.8383C25.6214 33.4771 24.755 33.836 23.8516 33.836C22.9482 33.836 22.0818 33.4771 21.443 32.8383C20.8042 32.1995 20.4453 31.3331 20.4453 30.4297" stroke="currentColor" stroke-width="1.5"/>
  <path data-notification="notification" d="M18.747 20.1123C18.747 18.786 19.2777 17.514 20.2222 16.5761C21.1668 15.6382 22.4479 15.1113 23.7837 15.1113" stroke="currentColor" stroke-width="1.5"/>
  <path data-notification="notification" d="M32.8625 30.5315H13.9901C14.8596 28.5393 15.2133 26.3858 15.4904 24.2907C15.5384 23.9276 15.5842 23.5667 15.6296 23.2082C15.9016 21.0611 16.1623 19.0028 16.8388 17.0897C17.847 14.2386 20.421 12.4453 23.4259 12.4453C26.3532 12.4453 28.9037 14.3 29.9571 17.1191C30.6786 19.0501 30.9523 21.1499 31.2386 23.3463C31.2794 23.6587 31.3204 23.9732 31.3629 24.2894C31.6448 26.3832 31.9963 28.5414 32.8625 30.5315Z" stroke="currentColor" stroke-width="1.5"/>
  <path data-notification="notification" d="M23.4263 12.1211V9.14062" stroke="currentColor" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconNotification'
}
</script>

<style scoped>

</style>
