import {
  sidebarAdmin,
  sidebarBidder,
  sidebarOrganizer,
  sidebarSecurity,
  sidebarShowcase,
  sidebarUser
} from '@/utils/sidebar/sidebar'
import { PAGE_DESCRIPTION } from '@/utils/text/pageDescription'

export const slugsMainLayout = new Map([
  ['organizer', {
    sidebar: sidebarOrganizer,
    title: 'Личный кабинет',
    isBack: true,
    bgIsWhite: false,
    description: 'В данном разделе Вы можете создавать и настраивать свои лоты, участвовать в торгах, ознакомляться с результатами и отслеживать процесс  торговых процедур.',
    isCenterTitle: false,
    isSmallTitle: false
  }],
  ['admin', {
    title: 'Добавление пользователя',
    description: 'Личный кабинет, настройка и управление торговой площадкой.',
    isBack: true,
    bgIsWhite: false,
    isCenterTitle: false,
    isSmallTitle: false
  }],
  ['bidder', {
    title: 'Создать торговую процедуру',
    description: 'Заполните форму ниже для того, чтобы иметь возможность организовать торговую процедуру.',
    isBack: true,
    bgIsWhite: false,
    isCenterTitle: false,
    isSmallTitle: false
  }],
  ['bidding', {
    title: 'Торговая процедура',
    description: '',
    isSmallTitle: true,
    bgIsWhite: true,
    isBack: true,
    isCenterTitle: false
  }],
  ['notification', {
    title: 'Уведомления',
    bgIsWhite: true,
    description: '',
    isBack: true,
    isCenterTitle: true,
    isSmallTitle: false
  }],
  ['not-registered', {
    title: 'Тендеры',
    description: PAGE_DESCRIPTION.notRegistered,
    bgIsWhite: true,
    isBack: true,
    isCenterTitle: false,
    isSmallTitle: false
  }],
  ['accreditation', {
    title: 'Аккредитация',
    description: 'Заполните форму ниже для того, чтобы иметь возможность участвовать в торговых процедурах.',
    isBack: true,
    bgIsWhite: false,
    isCenterTitle: false,
    isSmallTitle: false
  }]
])

export const slugsMainSidebarLayout = new Map([
  ['organizer', {
    title: 'Личный кабинет',
    sidebar: sidebarOrganizer,
    description: 'В данном разделе Вы можете создавать и настраивать свои лоты, участвовать в торгах, ознакомляться с результатами и отслеживать процесс  торговых процедур.',
    showSidebarInfo: true
  }],
  ['admin', {
    title: 'Администратор',
    sidebar: sidebarAdmin,
    description: 'Личный кабинет, настройка и управление торговой площадкой.',
    showSidebarInfo: false,
    sidebarInfo: null
  }],
  ['security', {
    title: 'Служба безопасности',
    sidebar: sidebarSecurity,
    description: 'Личный кабинет, настройка и управление торговой площадкой.',
    showSidebarInfo: false,
    sidebarInfo: null
  }],
  ['bidder', {
    title: 'Личный кабинет',
    sidebar: sidebarBidder,
    description: 'В данном разделе Вы можете создавать и настраивать свои лоты, участвовать в торгах, ознакомляться с результатами и отслеживать процесс  торговых процедур.',
    showSidebarInfo: true
  }],
  ['not-registered', {
    title: 'Тендеры',
    sidebar: sidebarShowcase,
    description: PAGE_DESCRIPTION.notRegistered,
    showSidebarInfo: false,
    sidebarInfo: null
  }],
  ['user', {
    title: 'Личный кабинет (НЕ АККРЕДИТОВАН)',
    sidebar: sidebarUser,
    description: 'В данном разделе Вы можете создавать и настраивать свои лоты, участвовать в торгах, ознакомляться с результатами и отслеживать процесс  торговых процедур.',
    showSidebarInfo: true
  }]
])
