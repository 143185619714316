<template>
  <!-- default width="18" height="18" -->
  <path d="M18 9C18 7.21997 17.4722 5.47991 16.4832 3.99987C15.4943 2.51983 14.0887 1.36627 12.4442 0.685084C10.7996 0.0038955 8.99002 -0.174335 7.24419 0.172932C5.49836 0.520199 3.89471 1.37737 2.63604 2.63604C1.37737 3.89471 0.520199 5.49836 0.172932 7.24419C-0.174335 8.99002 0.00389552 10.7996 0.685084 12.4442C1.36627 14.0887 2.51983 15.4943 3.99987 16.4832C5.47991 17.4722 7.21997 18 9 18V16.4533C7.52588 16.4533 6.08486 16.0162 4.85917 15.1972C3.63349 14.3782 2.67818 13.2142 2.11406 11.8523C1.54994 10.4903 1.40234 8.99173 1.68992 7.54594C1.97751 6.10014 2.68737 4.77209 3.72973 3.72973C4.77209 2.68737 6.10014 1.97751 7.54594 1.68992C8.99173 1.40234 10.4903 1.54994 11.8523 2.11406C13.2142 2.67818 14.3782 3.63349 15.1972 4.85917C16.0162 6.08486 16.4533 7.52588 16.4533 9H18Z" fill="#233F1F"/>
  <path d="M14 9C14 8.01109 13.7068 7.04439 13.1573 6.22215C12.6079 5.3999 11.827 4.75904 10.9134 4.3806C9.99979 4.00216 8.99445 3.90315 8.02455 4.09607C7.05464 4.289 6.16373 4.7652 5.46447 5.46447C4.7652 6.16373 4.289 7.05464 4.09607 8.02455C3.90315 8.99445 4.00216 9.99979 4.3806 10.9134C4.75904 11.827 5.3999 12.6079 6.22215 13.1573C7.04439 13.7068 8.01109 14 9 14V12.4936C8.30903 12.4936 7.63358 12.2887 7.05906 11.9048C6.48453 11.5209 6.03675 10.9753 5.77233 10.3369C5.5079 9.69857 5.43872 8.99612 5.57352 8.31843C5.70832 7.64074 6.04106 7.01824 6.52965 6.52965C7.01824 6.04106 7.64074 5.70832 8.31843 5.57352C8.99612 5.43872 9.69857 5.5079 10.3369 5.77233C10.9753 6.03675 11.5209 6.48453 11.9048 7.05906C12.2887 7.63358 12.4936 8.30903 12.4936 9H14Z" fill="#233F1F"/>
  <path d="M7.64813 7.41042L16.2537 11.2771L10.9575 16.2454L7.64813 7.41042Z" fill="#233F1F"/>
  <rect x="14.1416" y="11.7227" width="4.3213" height="3.25631" transform="rotate(47.3074 14.1416 11.7227)" fill="#233F1F"/>
</template>

<script>
export default {
  name: 'IconActivate'
}
</script>
