<template>
  <!-- default width="26" height="27" -->
  <path d="M6.72424 13.2131L4.81584 9.01465H6.5334L7.86927 12.0681L9.20515 9.01465H11.1135L9.01431 13.2131L11.1135 17.6024H9.20515L7.86927 14.3582L6.5334 17.6024H4.625L6.72424 13.2131Z" fill="#233F1F"/>
  <path d="M15.313 4.05344V1L1 3.67176V23.3282L15.313 26V22.9466M15.313 4.05344H25.0458V22.9466H15.313M15.313 4.05344V22.9466" stroke="#233F1F" stroke-width="1.5"/>
  <rect x="15.3125" y="6.15137" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="15.3125" y="9.20587" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="15.3125" y="12.2585" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="15.3125" y="15.3135" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="15.3125" y="18.3662" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="19.5938" y="6.15137" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="19.5938" y="9.20587" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="19.5938" y="12.2585" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="19.5938" y="15.3135" width="3.04861" height="1.9084" fill="#233F1F"/>
  <rect x="19.5938" y="18.3662" width="3.04861" height="1.9084" fill="#233F1F"/>
</template>

<script>
export default {
  name: 'IconExcel'
}
</script>

<style scoped>

</style>
