<template>
  <!-- default width="45" height="45" -->
  <circle cx="22.5" cy="22.5" r="22.5" fill="white"/>
  <path d="M16 29.1421L30.1421 15" stroke="currentColor" stroke-width="1.5"/>
  <path d="M16 15L30.1421 29.1421" stroke="currentColor" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>
