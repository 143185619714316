import { $authHost } from '@/http'
import { EXPAND_LOT } from '@/utils/consts'

const LOT_URL = '/trade-offer/lots'

export const createLot = async (formData) => {
  const { data } = await $authHost.post(LOT_URL, formData)
  return data
}

export const changeLotById = async (id, formData) => {
  const { data } = await $authHost.put(`${LOT_URL}/${id}`, formData)
  return data
}

export const getAllLot = async (query = null) => {
  if (query) {
    return await $authHost.get(`${LOT_URL}?${query}`)
  }
  return await $authHost.get(`${LOT_URL}?${EXPAND_LOT}`)
}

export const getLot = async (id) => {
  const { data } = await $authHost.get(`${LOT_URL}/${id}?${EXPAND_LOT}`)
  return data
}

export const updateLot = async (id, formData) => {
  const { data } = await $authHost.put(`${LOT_URL}/${id}`, formData)
  return data
}

export const deleteLot = async (id) => {
  // const { data } = await $authHost.delete(`${LOT_URL}/${id}`)
  const { data } = await $authHost.delete(`${LOT_URL}-mass-delete`, { data: { lot_ids: id } })
  return data
}

export const attachLot = async (lotId) => {
  const { data } = await $authHost.put(`${LOT_URL}/${lotId}/attach`)
  return data
}

export const detachLot = async (lotId, userId) => {
  const { data } = await $authHost.put(`${LOT_URL}/${lotId}/detach`, userId)
  return data
}

export const setFileLots = async (id, formData) => {
  const { data } = await $authHost.post(`${LOT_URL}/${id}/files`, formData)
  return data
}

export const getLotResult = async (id) => {
  const { data } = await $authHost.get(`${LOT_URL}/${id}/before-result`)
  return {
    data: data.data.all,
    byPosition: data.data.by_position
  }
}

export const restoreLot = async id => {
  const { data } = await $authHost.put(`${LOT_URL}/${id}/restore`)
  return data
}
