import { $authHost } from '@/http/index'

const NOTIFICATION = '/messages?sort=-id'

export const getNotification = async () => {
  const { data } = await $authHost.get(NOTIFICATION)
  return data
}

export const checkNotification = async (items) => {
  const { data } = await $authHost.put(`${NOTIFICATION}`, { ids: items })
  return data
}
