<template>
  <!-- default width="19" height="21" -->
  <path d="M10.5667 3.9245L15.3501 9.04233M10.5667 3.9245L13.3001 1L18.0834 6.11787L15.3501 9.04233L10.5667 3.9245ZM10.5667 3.9245L1.28304 13.8574C1.10182 14.0513 1 14.3143 1 14.5885V19.2781H5.38306C5.63936 19.2781 5.88516 19.1692 6.0664 18.9752L15.3501 9.04233L10.5667 3.9245Z" stroke="currentColor" stroke-width="1.70833" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script>
export default {
  name: 'IconEdit'
}
</script>
