import store from '@/store'
import router from '@/router'
import { $authHost, $host } from '@/http/index'
import { computed } from 'vue'
// import { useCrypto } from '@/use/Base/useCrypto'
import axios from 'axios'
import { Alert } from '@/extension/Alert/Alert'

const ACCESS_STRING = 'accessString'

const route = computed(() => {
  return router.currentRoute.value
})

export function axiosCreateDefault() {
  return axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_SERVER_URL,
  })
}

export function getAccessString() {
  return localStorage.getItem(ACCESS_STRING)
}

export function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export function isModeDevelopment() {
  return process.env.NODE_ENV === 'production'
}

export function hostRequest(config) {
  if (getAccessString()) {
    config.headers.AccessString = getAccessString()
  }
  return config
}

export function getAccessStringInQuery() {
  return route.value.query.access_string
}

export function repeatOriginalRequest($axios, originalRequest) {
  localStorage.setItem(ACCESS_STRING, route.value.query.access_string)
  return $host.request(originalRequest)
}

export async function hostInterceptorResponseReject(error) {
  const originalRequest = error.config
  if (error.response.status === 503 && !error.config._isRetry) {
    originalRequest._isRetry = true
    if (getAccessStringInQuery()) {
      return repeatOriginalRequest($host, originalRequest)
    }
    store.commit('setIsWorks')
    await router.push({ name: 'work' })
    throw error
  }
  return error
}

export async function hostInterceptorResponseSuccess (config) {
  // if (isModeDevelopment()) {
  //   config.data.data = useCrypto(config)
  // }
  return config
} 

export function authRequest(config) {
  if (getAccessString()) {
    config.headers.AccessString = getAccessString()
  }
  config.headers.Authorization = `Bearer ${getAccessToken()}`
  return config
}

export async function authHostInterceptorResponseReject(error) {
  const originalRequest = error.config
  if (error.response.status === 401 && !error.config._isRetry) {
    originalRequest._isRetry = true
    try {
      return $authHost.request(originalRequest)
    } catch (e) {
      await Alert.show('error', 'Пользователь не авторизован')
    }
  }
  if (error.response.status === 503 && !error.config._isRetry) {
    originalRequest._isRetry = true
    if (route.value.query.access_string) {
      localStorage.setItem(ACCESS_STRING, route.value.query.access_string)
      return $authHost.request(originalRequest)
    }
    store.commit('setIsWorks')
    await router.push({ name: 'work' })
  }
  throw error
}
export function authHostInterceptorResponseSuccess (config) {
  // if (isModeDevelopment()) {
  //   config.data.data = useCrypto(config)
  // }
  return config
}
