import { $authHost } from '@/http'

const PARAMETER_URL = '/registers/parameter'

export const createParameter = async (parameter) => {
  const { data } = await $authHost.post(PARAMETER_URL, parameter)
  return data
}

export const getParameterById = async (id) => {
  const { data } = await $authHost.get(`${PARAMETER_URL}/${id}`)
  return data
}

export const searchParametersByName = async (string) => {
  const { data } = await $authHost.get(`${PARAMETER_URL}?search[All]=${string}`)
  return data
}
