<template>
  <!-- default width="30" height="22" -->
  <path d="M15 0L29.7224 21.545H0.277568L15 0Z" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconTriangle'
}
</script>

<style scoped>

</style>
