export default {
  namespaced: true,
  state () {
    return {
      list: [],
      reCalc: false
    }
  },
  getters: {
    getErr (state) {
      return state.list
    },
    getReCalc (state) {
      return state.reCalc
    }
  },
  mutations: {
    setErr (state, err) {
      state.list.push(err)
    },
    dropErr (state, id) {
      state.list = state.list.filter(item => item.id !== id)
    },
    setReCalc (state) {
      state.reCalc = !state.reCalc
    }
  }
}
