import { CategoryService } from '@/service/api/user/CategoryService'
import { Alert } from '@/extension/Alert/Alert'
import { Request } from '@/service/api/Request/Request'

export class CategoryController extends Request {
  #categoryService = new CategoryService()

  // Categories
  async getCategories () {
    return await this.#categoryService.getUserCategory()
  }

  async getMyCategories () {
    return await this.#categoryService.getMyCategories()
  }

  async setCategories (categories) {
    const response = await this.#categoryService.setCategory(categories)
    await Alert.show('success', 'Категории были изменены')
    return response
  }
}
