import router from '@/router'

import { isNavigationFailure, NavigationFailureType } from 'vue-router'

export const goBack = () => router.back()

export const goMain = () => router.push({ name: 'showcase' })

export const goAccreditation = () => router.push({ name: 'accreditation' })

export const goLotInfo = (id, name) => router.push({ name, params: { id } })

export const goSitno = () => {
  window.location.href = 'https://sitno.ru/'
}

export const goVK = () => {
  window.location.href = 'https://vk.com/sitno_official'
}

export const goCookiePolitic = () => {
  window.location.href = 'https://sitno.ru/politika-obrabotki-personalnyh-dannyh'
}

export const goLotOrganizer = () => router.push({ name: 'create-trade' })

export function goToPage (name, params) {
  return router
    .push({ name, params })
    .catch(onErrorNavigation)
}

function onErrorNavigation (error) {
  if (isNavigationFailure(error, NavigationFailureType.duplicated)) return

  console.error(error)
}

export function goLotOffer () {
  return goToPage(
    'bidder-lot-create',
    { id: router.currentRoute.value.params.id }
  )
}

export function goToAdminUser (row) {
  return goToPage('admin-user', { id: row.item.id })
}
