export default {
  namespaced: true,
  state () {
    return {
      breadcrumbs: []
    }
  },
  getters: {
    getBreadcrumbs(state) {
      return state.breadcrumbs
    }
  },
  mutations: {
    setBreadcrumbs(state, list) {
      state.breadcrumbs = list
    }
  }
}
