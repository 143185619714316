import { attachLot, deleteLot, detachLot, getLot, restoreLot } from '@/http/lot/lotApi'
import store from '@/store'
import { followLot, unfollowLot } from '@/http/user/userLotApi'
import { changeStatus } from '@/http/offer/offerApi'
import {
  DELETE_BEFORE_PUBLIC,
  TRADE_CANCEL,
  TRADE_EXTEND,
  TRADE_HIDE,
  TRADE_OPEN,
  TRADE_PUBLIC
} from '@/utils/lot/lotStatus'
import { createPatternLot, getPatterns, updatePatternLot, destroyPatternLot } from '@/http/lot/patternLotApi'

export class LotService {
  async getLotById (id) {
    const { data } = await getLot(id)
    return data
  }

  async delete (id) {
    await deleteLot(id)
    store.commit('item/deleteItemsById', [id])
  }

  async extend (id, data = {}) {
    await changeStatus(id, TRADE_EXTEND, data)
  }

  async deleteBeforePublic (id) {
    await changeStatus(id, DELETE_BEFORE_PUBLIC)
  }

  async public (id) {
    await changeStatus(id, TRADE_PUBLIC)
  }

  async open (id) {
    await changeStatus(id, TRADE_OPEN)
  }

  async cancel (id) {
    await changeStatus(id, TRADE_CANCEL)
  }

  async hide (id) {
    await changeStatus(id, TRADE_HIDE)
  }

  async attach (lotId) {
    const response = await attachLot(lotId)
    return response.message
  }

  async detach (lotId, userId) {
    const response = await detachLot(lotId, userId)
    return response.message
  }

  async follow (lotId, userId) {
    const response = await followLot(lotId, userId)
    return response.message
  }

  async unfollow (lotId, userId) {
    const response = await unfollowLot(lotId, userId)
    return response.message
  }

  async createPatternLot (patterns) {
    const { data } = await createPatternLot(patterns)
    return data
  }

  async updatePatternLot (id, pattern) {
    const { data } = await updatePatternLot(id, pattern)
    return data
  }

  async destroyPatternLot (id) {
    const { data } = await destroyPatternLot(id)
    return data
  }

  async getTemplate () {
    const { data } = await getPatterns()
    return data
  }

  async restore (id) {
    await restoreLot(id)
  }
}
