import { FilesService } from '@/service/api/FilesService'
import { Request } from '@/service/api/Request/Request'

export class FilesController extends Request {
  #fileService = new FilesService()

  async sendFiles (data, userId, { files, nameList }) {
    try {
      return await this.#fileService.sendFiles(data, userId, { files, nameList })
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
