<template>
  <main class="main">
    <TheHeader />
    <div class="main-container">
      <div  :class="['block', bgIsWhite ? 'block__bg-white' : '']">
        <div class="block__img block__img-create-user">
          <div class="container">
            <div class="block__info">
              <div
                :class="[
                'block__title',
                isSmallTitle ? 'block__title-small' : '',
                isCenterTitle ? 'block__title--center' : '']"
              >{{ title }}</div>
              <div class="block__description">
                {{ description }}
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="mt-20 mb-50" v-if="isBack">
            <VLink @click="routeBack($route.meta)">Назад</VLink>
            <router-view></router-view>
          </div>

        </div>
      </div>
    </div>
    <TheFooter />
  </main>
</template>

<script>
import TheHeader from '@/components/header/TheHeader'
import TheFooter from '@/components/TheFooter'
import VLink from '@/components/ui/buttons/VLink'
import { slugsMainLayout } from '@/utils/slugs'
import { getSlugs } from '@/utils/utils'
import { useRoute } from 'vue-router'
import { routeBack } from '@/utils/routeBack'

export default {
  setup () {
    const route = useRoute()
    const slug = route.meta.slug
    return {
      ...getSlugs(slugsMainLayout, slug),
      routeBack
    }
  },
  components: {
    TheHeader, TheFooter, VLink
  }
}
</script>
