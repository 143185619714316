<template>
  <!-- default width="23" height="23" -->
  <path d="M11.4996 14.6496C13.2394 14.6496 14.6496 13.2394 14.6496 11.4996C14.6496 9.75986 13.2394 8.34961 11.4996 8.34961C9.75986 8.34961 8.34961 9.75986 8.34961 11.4996C8.34961 13.2394 9.75986 14.6496 11.4996 14.6496Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.5035 9.81517L18.3509 7.03204L19.9 5.2L17.8 3.1L15.9779 4.6571L13.1357 3.48823L12.4821 1H10.43L9.76655 3.52119L6.98963 4.69176L5.2 3.1L3.1 5.2L4.62604 7.07829L3.49112 9.86861L1 10.45V12.55L3.52117 13.2383L4.69154 16.0147L3.1 17.8L5.2 19.9L7.08072 18.3673L9.81685 19.4929L10.45 22H12.55L13.1847 19.4939L15.9679 18.3413C16.4317 18.6729 17.8 19.9 17.8 19.9L19.9 17.8L18.3417 15.9619L19.4946 13.1779L21.9999 12.5261L22 10.45L19.5035 9.81517Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script>
export default {
  name: 'IconSetting'
}
</script>
