import { $authHost } from '@/http'

const USER_CATEGORY_URL = '/user-category'

export const getUserCategory = async () => {
  const { data } = await $authHost.get(USER_CATEGORY_URL)
  return data
}

export const setUserCategory = async (categories) => {
  const { data } = await $authHost.put(USER_CATEGORY_URL, { categories })
  return data
}

export const getAllMyCategory = async () => {
  const { data } = await $authHost.get(`${USER_CATEGORY_URL}/my-category`)
  return data
}
