import showCaseUnauthorizedMeta from './showCaseUnauthorizedMeta'

export default [
  {
    path: '/showcase',
    name: 'showcase',
    alias: ['/showcase/'],
    component: () => import('@/views/ShowCaseUnauthorized/ShowcaseAllTradeProductView'),
    props: {
      title: 'Торговые процедуры'
    },
    meta: {
      ...showCaseUnauthorizedMeta,
      layout: 'main-sidebar-layout'
    }
  },
  {
    path: '/showcase/new',
    name: 'showcase-new',
    component: () => import('@/views/ShowCaseUnauthorized/ShowcaseNewView'),
    props: {
      title: 'Новые'
    },
    meta: {
      ...showCaseUnauthorizedMeta,
      layout: 'main-sidebar-layout'
    }
  }
]
