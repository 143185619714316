import { ItemService } from '@/service/api/ItemService'
import { Alert } from '@/extension/Alert/Alert'
import { Request } from '@/service/api/Request/Request'

export class ItemController extends Request {
  #itemService = new ItemService()

  async changeItem ({ url, action, items, custom, formData = null }) {
    try {
      const message = await this.#itemService.changeItem(url, action, items, custom, formData)
      await Alert.show('success', message)
    } catch (e) {
      console.log(e)
      await this.handlerErrors.showAlert(e)
    }
  }
}
