import { reactive } from 'vue'
import { useForm } from '@/use/Base/useForm'
import { FormHandler } from '@/utils/use/formHandler'
import { requiredValid } from '@/utils/validator/fields'
import { Alert } from '@/extension/Alert/Alert'
import { unionFiles } from '@/utils/utils'
import { sendError } from '@/http/otherApi'

export function useError(emit) {
  const formData = reactive({
    name: '',
    email: '',
    description: '',
    phone: '',
    files: []
  })

  const rules = {
    name: requiredValid,
    description: requiredValid
  }

  const formAction = async () => {
    const data = new FormData()
    Object.keys(formData).forEach(key => data.append(key, formData[key]))
    unionFiles(formData.files, null, data)
    await sendError(data)
    emit('close')
    await Alert.show('success', 'Спасибо! Ваше сообщение успешно отправлено в техподдержку.')
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors)
  }
}
