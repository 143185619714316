<template>
  <!-- default width="25" height="25" -->
  <path d="M1.60712 10.3203H19.3928V15.356C19.3928 20.2674 15.4114 24.2489 10.5 24.2489C5.58859 24.2489 1.60712 20.2674 1.60712 15.356V10.3203Z" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M16.2857 10.5352L16.2857 4.74944C16.2857 2.6192 18.0126 0.892298 20.1428 0.892298V0.892298C22.2731 0.892298 24 2.6192 24 4.74944L24 10.5352" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M10.5 16.3223L10.5 20.1794" stroke="#233F1F" stroke-width="1.5"/>
  <circle cx="10.5" cy="15.3583" r="1.92857" fill="#233F1F"/>
</template>

<script>
export default {
  name: 'IconPardon'
}
</script>
