import { createRouter, createWebHistory } from 'vue-router'
import admin from './admin/index'
import security from './security/index'
import organizer from './organizer/index'
import bidder from '@/router/bidder'
import biddingProcedure from '@/router/biddingProcedure'
import notification from '@/router/notification'
import showCaseUnauthorizedMeta from '@/router/showCaseUnauthorized'
import { checkPermissionForResetPassword } from '@/http/auth/authApi'
import { authMiddleware } from '@/router/middleware/auth.middleware'
import { notificationMiddleware } from '@/router/middleware/notification.middleware'
import { paginationMiddleware } from '@/router/middleware/pagination.middleware'
import { filterMiddleware } from './middleware/filter.middleware'
import bidderMeta from '@/router/bidder/bidderMeta'
import { checkRole } from '@/utils/utils'
import { Alert } from '@/extension/Alert/Alert'

let routes = [
  {
    path: '/:notFound(.*)',
    redirect: { name: 'admin' }
  },
  {
    path: '/loading',
    name: 'spinner',
    component: () => import('../components/views/Spinner/VSpinner'),
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../components/views/Spinner/VSpinner'),
    meta: {
      layout: 'auth-layout'
    },
    async beforeEnter (to, from, next) {
      const isWork = true
      if (isWork) {
        next()
      } else {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/Auth/RegistrationView'),
    meta: {
      layout: 'auth-layout',
      login: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginView'),
    meta: {
      layout: 'auth-layout',
      login: true
    }
  },
  {
    path: '/change-password',
    name: 'reset',
    component: () => import('../views/Auth/ResetPasswordView'),
    meta: {
      layout: 'auth-layout',
      login: true
    },
    async beforeEnter (to, from, next) {
      try {
        await checkPermissionForResetPassword(to.query)
        next()
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
]

const additionalRoutes = [
  ...admin,
  ...security,
  ...bidder,
  ...security,
  ...organizer,
  ...biddingProcedure,
  ...notification,
  ...showCaseUnauthorizedMeta
]

routes = [...routes, ...additionalRoutes]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authMiddleware)
router.beforeEach(filterMiddleware)
router.beforeEach(notificationMiddleware)
router.beforeEach(paginationMiddleware)

export default router
