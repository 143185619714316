export class ApiError extends Error {
  constructor () {
    super()
    this.messageList = new Map([
      [401, this.Unauthenticated()],
      [403, this.Forbidden()],
      [429, this.Unauthenticated()],
      [500, this.InternalError()]
    ])
  }

  getMessage (status) {
    return this.messageList.has(status) ? this.messageList.get(status) : this.messageList.get(500)
  }

  BadRequest () {
    return 'Некорректный запрос'
  }

  Forbidden () {
    return 'У вас нет доступа'
  }

  Unauthenticated () {
    return 'Пожалуйста авторизуйтесь'
  }

  InternalError () {
    return 'Сервер столкнулся с неожиданной ошибкой, которая помешала ему выполнить запрос.'
  }
}
