import organizerMeta from '@/router/organizer/organizerMeta'
import myTrade from '@/router/organizer/myTrade'
import profile from '@/router/organizer/profile'
import store from '@/store'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/organizer',
    name: 'organizer',
    redirect: { name: 'organizer-my-trade' },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/trade-register',
    name: 'organizer-register',
    meta: organizerMeta,
    props: {
      titlePage: 'Реестр торгов'
    },
    children: [
      {
        path: '/organizer/trade-register/summarizing/:id',
        name: 'organizer-summarizing-lot',
        meta: organizerMeta,
        component: () => import('@/views/Organizer/Summarizing/OrganizerSummarizingLot'),
        async beforeEnter (to, from, next) {
          try {
            const candidate = await checkRole('organizer')
            if (candidate) {
              return next()
            } else {
              return next({ name: 'login' })
            }
          } catch (e) {
            next({ name: 'login' })
          }
        }
      }
    ],
    component: () => import('@/views/Organizer/Sidebar/OrganizerSidebarSummarizing'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/create-trade-platform',
    name: 'create-trade',
    meta: {
      ...organizerMeta,
      layout: 'main-layout'
    },
    component: () => import('@/views/Organizer/Sidebar/create/OrganizerSidebarCreateTradeView'),
    props: {
      titlePage: 'Торговые процедуры'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/create-trade-platform-layout',
    name: 'create-trade-layout',
    meta: {
      ...organizerMeta,
      layout: 'main-layout'
    },
    component: () => import('@/views/Organizer/Sidebar/create/OrganizerSidebarCreateTradeLayout'),
    props: {
      titlePage: 'Торговые процедуры'
    },
    children: [
      {
        path: '/organizer/create-trade-platform-layout/:id',
        name: 'create-trade-layout-id',
        meta: {
          ...organizerMeta,
          layout: 'main-layout',
          back: {}
        },
        component: () => import('@/views/Organizer/Create/OrganizerCreateTradeLayout')
      }
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/trade-erp',
    name: 'trade-erp',
    meta: {
      ...organizerMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Organizer/Sidebar/OrganizerSidebarTrade1cView'),
    props: {
      title: 'Торговые процедуры'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/create-trade-platform/:id',
    name: 'edit-trade',
    meta: {
      ...organizerMeta,
      layout: 'main-layout',
      back: {
        name: 'organizer-my-trade-active'
      }
    },
    component: () => import('@/views/Organizer/Sidebar/create/OrganizerSidebarCreateTradeView'),
    props: {
      titlePage: 'Аукцион с понижением цены'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/my-trade',
    name: 'organizer-my-trade',
    redirect: { name: 'organizer-my-trade-sidebar' },
    meta: organizerMeta,
    children: [
      ...myTrade
    ],
    component: () => import('@/views/Organizer/OrganizerMyTradeView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/profile',
    name: 'organizer-profile',
    redirect: { name: 'organizer-profile-info' },
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Sidebar/OrganizerSidebarProfileView'),
    props: {
      title: 'Профиль'
    },
    children: [
      ...profile
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/graph',
    name: 'organizer-graph',
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Sidebar/OrganizerSidebarGraphView'),
    props: {
      title: 'Статистика и отчеты'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/organizer/lot-info/:id',
    name: 'organizer-lot-info',
    meta: {
      ...organizerMeta,
      layout: 'main-lot-layout',
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          route: () => {
            return {
              name: store.getters['auth/getRole']
            }
          },
          isCheckRole: true
        },
        {
          name: '',
          paramsKey: 'id'
        }
      ]
    },
    component: () => import('@/views/Organizer/OrganizerLotInfoView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('organizer')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  }
]
