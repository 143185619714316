<template>
  <!-- default width="23" height="23" -->
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70588 17.9118C14.2379 17.9118 17.9118 14.2379 17.9118 9.70588C17.9118 5.1739 14.2379 1.5 9.70588 1.5C5.1739 1.5 1.5 5.1739 1.5 9.70588C1.5 14.2379 5.1739 17.9118 9.70588 17.9118ZM17.0789 16.0183C18.5332 14.3212 19.4118 12.1161 19.4118 9.70588C19.4118 4.34547 15.0663 0 9.70588 0C4.34547 0 0 4.34547 0 9.70588C0 15.0663 4.34547 19.4118 9.70588 19.4118C12.1161 19.4118 14.3212 18.5332 16.0183 17.0789L21.6545 22.7151L22.7151 21.6545L17.0789 16.0183ZM13.9433 9.33473C13.8155 8.17835 13.2975 7.10001 12.4749 6.27735C11.6523 5.45481 10.5742 4.93693 9.418 4.80898L9.41748 4.80892L9.43423 4.65742L9.58232 3.31801L9.59907 3.1665C10.1039 3.22232 10.5976 3.33435 11.0715 3.49853C12.0335 3.83178 12.9143 4.37988 13.6433 5.1089C14.3723 5.83793 14.9205 6.71874 15.2537 7.6807C15.4176 8.15386 15.5296 8.64666 15.5855 9.15067L15.5857 9.15312L15.4342 9.16988L14.0948 9.31798L13.9433 9.33473Z" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconSearch'
}
</script>
