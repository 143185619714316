import { AuthService } from '@/service/api/AuthService'
import { Alert } from '@/extension/Alert/Alert'
import { Request } from '@/service/api/Request/Request'

export class AuthController extends Request {
  #authService = new AuthService()

  async changePasswordByAdmin (isSend, id) {
    try {
      isSend = true
      await this.#authService.changePasswordByAdmin(id)
      await Alert.show('success', 'Письмо с новым паролем успешно отправлено пользователю на почту')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    } finally {
      isSend = false
    }
  }
}
