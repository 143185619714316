export default {
  namespaced: true,
  state () {
    return {
      currentCell: {},
      isReload: false
    }
  },
  getters: {
    getCurrentCell (state) {
      return state.currentCell
    },
    getIsReload(state) {
      return state.isReload
    }
  },
  mutations: {
    setCurrentCell (state, cell) {
      state.currentCell = cell
    },
    setIsReload (state) {
      state.isReload = !state.isReload
    }
  }
}
