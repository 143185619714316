import { $authHost } from '../index'
import axios from 'axios'

export const EXPAND_USER = 'roles,avatar,file,files_organization_chart, files_enterprise_map, files_appointment,files_proxy,files_tin,files_ogrn,files_usrle,files_certificate_bank,files_bank_card,files_passport,files_rent, organization_type, partner_type'
const USER_URL = '/users'

export const createUserFromAdmin = async (formData) => {
  const { data } = await $authHost.post(USER_URL, formData)
  return data
}

export const resetPasswordForAdmin = async (id) => {
  const { data } = await $authHost.put(`${USER_URL}/${id}/reset`)
  return data
}

export const getUser = async (id) => {
  const { data } = await $authHost.get(`${USER_URL}/${id}?expand=${EXPAND_USER}`)
  return data
}

export const setFileUser = async (id, formData) => {
  const { data } = await $authHost.post(`${USER_URL}/${id}/file`, formData)
  return data
}

export const setFileTypeUser = async (id, arrayFormData) => {
  return await axios.all(arrayFormData.map(formData => setFileUser(id, formData)))
}

export const setAvatarUser = async (id, formData) => {
  const { data } = await $authHost.post(`${USER_URL}/${id}/avatar`, formData)
  return data
}
