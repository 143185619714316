const DELAY = 100
export const DONT_MOVE = 'dMove-js'
const HIDE_TRANSFORM = 'hide_transform'
const SHOW = 'show'
const HIDE_BEFORE_DELETE_SHOW = 'hideBeforeDeleteShow'

function calculateVisibleHeader () {
  return document.documentElement.clientHeight < window.pageYOffset * 2
}

function checkIsHave(e) {
  const isItem = e.target.classList.contains(DONT_MOVE)
  const isParentItem = e.target.closest(`.${DONT_MOVE}`)
  return isItem || !!isParentItem
}

function isDontMoveForModal (e) {
  return checkIsHave(e)
}

function isShow (el, e) {
  el.classList.add(HIDE_TRANSFORM)
  if (e.clientY < 83) {
    el.classList.add(SHOW)
  } else {
    if (!isDontMoveForModal(e)) {
      if (el.classList.contains(SHOW)) {
        el.classList.add(HIDE_BEFORE_DELETE_SHOW)
      }

      setTimeout(() => {
        el.classList.remove(SHOW)
        el.classList.remove(HIDE_BEFORE_DELETE_SHOW)
      }, DELAY)
    }
  }
}

function isHide (el) {
  if (el.classList.contains(HIDE_TRANSFORM)) {
    el.classList.remove(HIDE_TRANSFORM)
  }
}

export const headerAnim = {
  mounted (el) {
    let isMove = false
    window.addEventListener('mousemove', (e) => {
      if (isMove) return
      isMove = true

      setTimeout(() => {
        isMove = false
        if (calculateVisibleHeader()) {
          isShow(el, e)
        } else {
          isHide(el)
        }
      }, DELAY)
    })
  }
}
