import { Alert } from '@/extension/Alert/Alert'
import { checkNotification } from '@/http/notificationApi'

export default {
  namespaced: true,
  state () {
    return {
      notifications: [],
      unreadNotification: [],
      sending: [],
      notificationItemDom: [],
      isTimeout: false
    }
  },
  getters: {
    getTimeout (state) {
      return state.isTimeout
    },
    getNotifications (state) {
      return state.notifications
    },
    getUnreadNotifications (state) {
      return state.unreadNotification
    },
    getSending (state) {
      return state.sending
    },
    getItemDom (state) {
      return state.notificationItemDom
    }
  },
  mutations: {
    toggleTimeout (state) {
      state.isTimeout = !state.isTimeout
    },
    setNotificationDom (state, items) {
      state.notificationItemDom = items
    },
    setNotificationOneItemDom (state, item) {
      state.notificationItemDom.push(item)
    },
    setNotifications (state, list) {
      state.notifications = list
    },
    setUnreadNotifications (state, notification) {
      state.unreadNotification = notification
    },
    setWriteNotification (state, idx) {
      state.notifications[idx].reading_at = new Date().toISOString()
    },
    setNotification (state, notification) {
      state.notifications.push(notification)
    },
    setSending (state, idx) {
      if (state.sending.includes(idx)) return
      state.sending.push(idx)
    },
    clearSending (state) {
      state.sending.length = 0
    }
  },
  actions: {
    writeNotification: async function ({ state, commit }, items) {
      try {
        const ids = items.map(el => state.notifications[el].id)
        await checkNotification(ids)
        state.unreadNotification = state.notifications.filter(item => !item.reading_at)
      } catch (e) {
        await Alert.show('error', 'Невозможно прочитать уведомления')
      }
    }
  }
}
