import { createParameter, getParameterById, searchParametersByName } from '@/http/lot/parameterApi'
import { createParameterOptions, getParameterCategory, getParameterDepartment, getParameterType } from '@/http/manualApi'

export class ParametersService {
  async createParameter (parameter) {
    return await createParameter(parameter)
  }

  async searchParameter (string) {
    const { data } = await searchParametersByName(string)
    return data
  }

  async sendChanges (id, parameters) {
    const { data } = await createParameterOptions(id, parameters)
    return data
  }

  async getParameter (id) {
    const { data } = await getParameterById(id)
    return data
  }

  async department () {
    const { data } = await getParameterDepartment()
    return data
  }

  async type () {
    const { data } = await getParameterType()
    return data
  }

  async category () {
    const { data } = await getParameterCategory()
    return data
  }
}
