function scrollToNodeById (id) {
  const node = document.getElementById(id)
  scrollToNode(node)
}

function scrollToChildById (node, childId) {
  const child = node.querySelector(`[id='${childId}']`)
  scrollToNode(child)
}

function scrollToNode (node) {
  if (!node) return

  node.scrollIntoView({
    block: 'start',
    behavior: 'smooth'
  })
}

export {
  scrollToNodeById,
  scrollToChildById,
  scrollToNode
}
