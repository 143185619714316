import bidderMeta from '@/router/bidder/bidderMeta'

export default [
  {
    path: '/bidder/trade/active',
    name: 'bidder-trade-active',
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Trade/BidderSidebarTradeActiveView')
  },
  {
    path: '/bidder/trade/my-applications',
    name: 'bidder-trade-my-applications',
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Trade/BidderSidebarTradeMyApplicationsView')
  },
  {
    path: '/bidder/trade/archive',
    name: 'bidder-trade-archive',
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Bidder/Trade/BidderSidebarTradeArchiveView')
  }
]
