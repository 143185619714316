import adminMeta from '@/router/admin/adminMeta'
import profile from '@/router/admin/profile'
import { checkRole } from '@/utils/utils'
import { Alert } from '@/extension/Alert/Alert'

export default [
  {
    path: '/admin/user/:id',
    name: 'admin-user',
    redirect: { name: 'admin-profile-info' },
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout',
      back: 'admin-users-all'
    },
    component: () => import('@/views/Admin/Profile/AdminProfileUserView'),
    props: {
      title: 'Профиль'
    },
    children: [
      ...profile
    ],
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('admin')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        await Alert.show('error', 'Что-то пошло не так')
      }
    }
  },
  {
    path: '/admin/users/all',
    name: 'admin-users-all',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersAllUsersView')
  },
  {
    path: '/admin/users/members-tp',
    name: 'admin-users-members-tp',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersMembersTPView')
  },
  {
    path: '/admin/users/organizer',
    name: 'admin-users-organizer',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersOrganizersView')
  },
  {
    path: '/admin/users/security',
    name: 'admin-users-security',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersSecurityView')
  },
  {
    path: '/admin/users/admins',
    name: 'admin-users-admins',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersAdminsView')
  },
  {
    path: '/admin/users/journal',
    name: 'admin-users-journal',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersJournalView')
  },
  {
    path: '/admin/users/banned',
    name: 'admin-users-banned',
    meta: {
      ...adminMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Admin/Users/AdminUsersBannedView')
  },
]
