<template>
  <main class="main">
    <TheHeader />
    <div class="main-container">
      <div class="block">
        <div class="block__img block__img-admin">
          <div class="container">
            <div class="block__info">
              <div class="block__title">{{ title }}</div>
              <div class="block__description">
                {{ description }}
              </div>
            </div>
          </div>
        </div>

        <div class="admin">
          <div class="block__inner">
            <div class="container container--lot">
              <VSidebar :options="sidebar"  is-vertical/>

              <div class="lot-layout mt-40 mb-120">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter />
  </main>
</template>

<script>
import TheHeader from '@/components/header/TheHeader'
import TheFooter from '@/components/TheFooter'
import VSidebar from '@/components/views/Sidebar/VSidebar'
import { useRoute } from 'vue-router'
import { getSlugs } from '@/utils/utils'
import { slugsMainSidebarLayout } from '@/utils/slugs'

export default {
  setup () {
    const route = useRoute()

    return {
      ...getSlugs(slugsMainSidebarLayout, route.meta.slug)
    }
  },
  components: {
    TheHeader,
    TheFooter,
    VSidebar
  }
}
</script>
