<template>
  <!-- default width="15" height="14" -->
  <path d="M13.5 7L7.5 0.999999M0.999999 7L13.5 7L0.999999 7ZM13.5 7L7.5 13L13.5 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
 </template>

<script>
export default {
  name: 'IconCalendarArrow'
}
</script>
