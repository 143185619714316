import { RenderApiError } from '@/extension/Error/RenderApiError'
import { reactive } from 'vue'

export class Request {
  constructor () {
    this.handlerErrors = new RenderApiError()
    this.serverErrors = reactive({})
  }

  setServerErrors (e) {
    if (e?.response?.data?.errors) {
      this.serverErrors.value = e?.response?.data?.errors
    }
  }
}
