import router from '@/router/index'
import store from '@/store/index'
export function routeBack (meta) {
  if (meta.back) {
    const to = {}
    if (!Object.keys(meta.back).length) {
      to.name = redirectMetaLotLayout[store.getters['auth/getRole']]
    } else {
      to.name = meta.back.name
      to.params = meta.back.params ? router.currentRoute.value.params[meta.params] : ''
    }
    return router.push(to)
    }

  return router.back()
}

const ROLE_ADMIN = 'admin'
const ROLE_SECURITY = 'security'
const ROLE_BIDDER = 'bidder'
const ROLE_ORGANIZER = 'organizer'

export const redirectMetaLotLayout = {
  [ROLE_ADMIN]: 'admin-trade-active',
  [ROLE_SECURITY]: 'security-trade-active',
  [ROLE_BIDDER]: 'bidder-trade-active',
  [ROLE_ORGANIZER]: 'organizer-trade-active'
}
