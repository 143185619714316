import bidderMeta from '@/router/bidder/bidderMeta'

export default [
  {
    path: '/bidder/profile/info',
    name: 'bidder-profile-info',
    meta: bidderMeta,
    component: () => import('@/views/Bidder/Profile/BidderProfileInfoView')
  },
  {
    path: '/bidder/profile/documents',
    name: 'bidder-profile-documents',
    meta: bidderMeta,
    component: () => import('@/views/Bidder/Profile/BidderProfileDocumentsView')
  },
  {
    path: '/bidder/profile/edit/:id',
    name: 'bidder-profile-edit',
    meta: bidderMeta,
    component: () => import('@/views/Bidder/Profile/BidderProfileEditView')
  }
]
