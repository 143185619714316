import { $authHost, $host } from '@/http'

const CHANGE_PASSWORD_URL = '/change-password'

export const login = async (formData) => {
  const { data } = await $host.post('/login', { ...formData })
  return data
}

export const logout = async () => {
  const { data } = await $authHost.post('/logout')
  return data
}

export const registration = async (formData) => {
  return await $host.post('/register', formData)
}

export const verification = async (formData) => {
  const { data } = await $host.post('/verification', formData)
  return data
}

export const reset = async (formData) => {
  const { data } = await $host.post(CHANGE_PASSWORD_URL, { ...formData })
  return data
}

export const checkPermissionForResetPassword = async (query) => {
  const { data } = await $host.post(`${CHANGE_PASSWORD_URL}/verification`, { ...query })
  return data
}

// eslint-disable-next-line camelcase
export const changePassword = async ({ id, hash_verified }, password) => {
  const { data } = await $host.put(`${CHANGE_PASSWORD_URL}/${id}`, { hash_verified, password })
  return data
}
