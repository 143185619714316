import { listOptionsNotification } from '@/utils/notification/list'

export function transformNotifications (notifications) {
  notifications.forEach(notification => {
    const { title, status } = definitionTitleAndStatus(notification)
    notification.title = title
    notification.status = status
    notification.description = notification.message
  })
  return notifications
}

export function filterNotification (notifications) {
  const IS_READING = 'reading_at'
  return notifications.filter(notification => notification[IS_READING] !== null)
}

function definitionTitleAndStatus (notification) {
  let title = ''
  let status = ''
  if (!notification.status_id) {
    title = 'Аккредитация'
    status = 'call'
  } else {
    const option = listOptionsNotification.get(notification.status_id)
    title = option.title
    status = option.status
  }

  return {
    title,
    status
  }
}
