import store from '@/store'

function clearView () {
  // store.commit('clearAlert')
}

function isNotAuthorizedAndHasAToken () {
  return localStorage.getItem('accessToken') && !store.getters['auth/getIsAuth']
}

function isQueryHasAccessToken (to) {
  return to.query['access-string']
}

export {
  clearView,
  isNotAuthorizedAndHasAToken,
  isQueryHasAccessToken
}
