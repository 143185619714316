export class WebSocket {
  constructor ({ listeners = [], name }) {
    this.listeners = listeners
    this.name = name
  }

  connect () {}

  disconnect () {}

  initListeners (socket) {
    this.listeners.forEach((listener) => {
      const method = this[listener.method]
      if (!method) {
        throw new Error(`Метод ${listener.method} не был имплементирован внутри класса ${this.name}`)
      }
      socket[listener.name](listener.event, method)
    })
  }

  removeListeners () {}
}
