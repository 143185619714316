import { Request } from '@/service/api/Request/Request'
import { ParametersService } from '@/service/api/ParametersService'
import { Alert } from '@/extension/Alert/Alert'
import store from '@/store'

export class ParametersController extends Request {
  #parameterService = new ParametersService()

  async createParameter (parameter) {
    try {
      const { data } = await this.#parameterService.createParameter(parameter)
      data.category_name = data.category.name
      data.department_name = data.department.name
      data.type_name = data.type?.name
      store.commit('item/setOneItem', data)
      await Alert.show('success', 'Параметр успешно создан')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async searchParameter (string) {
    try {
      return await this.#parameterService.searchParameter(string)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async sendChanges (id, parameters) {
    try {
      return await this.#parameterService.sendChanges(id, parameters)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getParameterById (id) {
    try {
      return await this.#parameterService.getParameter(id)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getCategory () {
    try {
      return await this.#parameterService.category()
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getType () {
    try {
      return await this.#parameterService.type()
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getDepartment () {
    try {
      return await this.#parameterService.department()
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
