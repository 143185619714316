import { checkAccessToken } from '@/http/tokenApi'
import store from '@/store/index'
import router from '@/router'
import { getTime } from '@/http/offer/offerApi'
import { formatCurrency } from '@/utils/format/number'
import { formatDateTime } from '@/utils/format/date'

export function getSlugs (slugs, slug) {
  const isUser = store.getters['auth/getRole'] === 'user'
  const isProfileRoute = router.currentRoute.value.fullPath.includes('profile')
  const currentSlug = store.getters['auth/getRole'] === 'security' ? store.getters['auth/getRole'] : slug
  if (isUser && isProfileRoute) {
    return slugs.get('user')
  }
  return slugs.get(currentSlug)
}

export const resetForm = (form, { boolean = false, string = '', number = null }) => {
  const typeList = new WeakMap([
    ['boolean', (val) => { val = boolean }],
    ['string', (val) => { val = string }],
    ['number', (val) => { val = number }]
  ])

  for (const key of form) {
    const typeKey = typeof form[key]
    typeList.get(typeKey)(form[key])
  }
}

export const resetFormData = (formData) => {
  for (const key in formData) {
    if (formData[key] !== 'familiar_with_rules' || formData[key] !== 'familiar_with_policy' || typeof formData[key] !== 'string') {
      formData[key] = null
    } else {
      formData[key] = ''
    }
  }
}

let delay
export function toScrollTop (marginTopBody, marginTopDocumentElement, fn) {
  const top = Math.max(document.body.scrollTop - marginTopBody, document.documentElement.scrollTop - marginTopDocumentElement)
  if (top > 0) {
    window.scrollBy(0, -50)
    delay = setTimeout(() => {
      fn()
    }, 10)
  } else {
    clearTimeout(delay)
  }
  return false
}

export const unionFiles = (filesList, nameList = null, data) => {
  filesList.map((arr, i) => {
    if (Array.isArray(arr)) {
      for (let idx = 0; idx < arr?.length; idx++) {
        if (nameList) {
          data.append(`${nameList[i]}[${idx}]`, arr[idx])
        } else {
          data.append(`files[${idx}]`, arr[idx])
        }
      }
    } else {
      data.append(`files[${i}]`, filesList[i])
    }
  })
}

export const deepClone = obj => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)

  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  )

  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
      ? Array.from(obj)
      : clone
}

export const hashCode = (s) => {
  return s.split('').reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0)
}

export const checkRole = async (role) => {
  try {
    const { data } = await checkAccessToken()
    store.commit('auth/setUser', data)
    store.commit('auth/setIsAuth', true)

    if (Array.isArray(role)) {
      return store.getters['auth/getRole'] === 'admin' || store.getters['auth/getRole'] === 'security' || role.includes(store.getters['auth/getRole'])
    }

    if (role === 'admin') {
      return store.getters['auth/getRole'] === 'admin'
    }

    return store.getters['auth/getRole'] === 'admin' || store.getters['auth/getRole'] === 'security' || store.getters['auth/getRole'] === role
  } catch (e) {
    await store.dispatch('setAlert', {
      type: 'error',
      description: 'Что-то пошло не так, повторите попытку позже'
    })
  }
}

export const randomInteger = (min, max) => {
  const rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

export const inputRangeMaxMin = (input, range, maxLength = false) => {
  let sum = 0
  const totalAmount = 0
  const items = Object.keys(range)
  for (let i = 0; i < items.length; i++) {
    const obj = range[items[i]]
    for (let j = 0; j < obj.pos.length; j++) {
      if (sum === 0) {
        sum = input.value[obj.pos[j]]
      } else {
        if (input.value[obj.pos[j]]) {
          sum += input.value[obj.pos[j]]
        }
      }
    }
    if (sum < obj.min) {
      input.value = totalAmount
      return
    }
    if (sum > obj.max) {
      input.value = totalAmount
      return
    }

    if (maxLength && (input.value.length > String(obj.max).length)) {
      input.value = ''
      return
    }

    sum = 0
  }

  return totalAmount
}

export const transformLotObject = async (data, isTime = false) => {
  let time = null
  if (isTime) {
    time = await getTime()
  }

  const renderDate = (lot, dateStart, currentDate) => {
    const diff = Number(dateStart) - Number(currentDate)
    if (!diff) return
    const d = Math.floor(diff / 1000 / 60 / 60 / 24)
    const h = Math.floor((diff - (d * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60)
    const m = Math.floor(((diff - (d * 24 * 60 * 60 * 1000)) - (h * 60 * 60 * 1000)) / 1000 / 60)
    lot.timeToStart = `${d} д ${h} ч ${m} мин`
  }
  data.forEach((lot) => {
    const currentDate = new Date(Date.now(time))
    const dateStart = new Date(lot.started_at)
    if (dateStart.toISOString() < currentDate.toISOString()) {
      lot.timeToStart = 'Торги уже начались'
    } else {
      renderDate(lot, dateStart, currentDate)
    }
    lot.duration = formatDateTime(lot.duration)
    lot.started_at = formatDateTime(lot.started_at)
    lot.status = lot.status.name
    lot.member = lot.bidder?.length || 'Нет'
    if (lot.published_at) {
      lot.published_at = formatDateTime(lot.published_at)
    }
  })
  return data
}

export const inputDateRealtime = (e) => {
  if (e.target.value.length === 2) {
    e.target.value = e.target.value + '.'
  }

  if (e.target.value.length === 5) {
    e.target.value = e.target.value + '.'
  }

  if (e.target.value.length > 7 && new Date(Date.now()).getUTCFullYear() > e.target.value.slice(6)) {
    e.target.value = e.target.value.splice(6) + '.' + new Date().getUTCFullYear()
  }
}

export const transformDate = (date, min) => {
  date.setMinutes(date.getMinutes() + min)
  return date
}

export const calculateRangeBetweenDate = (dateStart, dateEnd) => {
  if (!dateStart || !dateEnd) return 'нет'
  const dS = new Date(dateStart)
  const dE = new Date(dateEnd)
  return Math.round(Math.abs(dE - dS) / 1000 / 60 / 60) + 'ч'
}

export const getRoleName = (role) => {
  const roleList = new Map([
    ['admin', 'Администратор'],
    ['security', 'Служба безопасности'],
    ['organizer', 'Организатор'],
    ['bidder', 'Участник торгов'],
    ['user', 'Пользователь']
  ])
  if (!roleList.has(role)) return

  return roleList.get(role)
}

export const getDescriptionAccreditation = (role) => {
  if (!role) return 'Пользователь не подтвердил e-mail'

  const accreditationListRoles = ['admin', 'security', 'organizer', 'bidder']

  return accreditationListRoles.includes(role) && role ? 'Аккредитован' : 'Не аккредитован'
}

export const getMemberLot = () => {
  const role = store.getters['auth/getRole']
  return role === 'bidder' ? 'bidder' : 'organizer'
}

export const copyObject = (to, from) => {
  Object.keys(from).forEach(key => { to[key] = from[key] })
}

export const formatPrice = (price) => {
  if (!price) return 'Не указано'

  return formatCurrency(price) + ' руб.'
}

export const sumNds = (price, nds) => {
  return (price * nds / 100) + price
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const lowerCase = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

// data = {}[] key - item key for disabled, condition - why disabled key
// { key: disabledField.value, condition = formData.organization_type_id === 2 }
export const disabledField = (data) => {
  data.forEach((item) => {
    if (typeof item.key === 'object') {
      item.key.value = item.condition
      return
    }
    item.key = item.condition
  })
}

export function getValueByKey(el, fullPathValue, defaultValue = 'Не указано') {
  const countIterable = fullPathValue.length
  const curItem = el[fullPathValue[0]]
  if (countIterable && curItem) {
    return getValueByKey(curItem, fullPathValue.slice(1))
  }
  return el || defaultValue
}

export function debounce(fn, wait) {
  let timeout
  return function(...args) {
    const later = () => {
      clearTimeout(timeout)
      // eslint-disable-next-line
      fn.apply(this, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const toNum = (val) => {
  if (typeof val === 'number') return val

  if (!val) return 0

  return +val.replace(/,/g, '.')
}

export function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

export function mapData2num (data, keyList) {
  const newData = {
    ...data
  }

  for (const key of keyList) {
    if (key in newData) {
      newData[key] = toNum(newData[key])
    }
  }

  return newData
}

// Удаляет элемент из массива.
// cb - коллбэк для кастомного поиска индекса в массиве.
// Если его не передать, будет поиск по id.
export function spliceBy (val, arr, cb) {
  const i = cb ? cb(arr, val) : arr.findIndex(item => item.id === val)
  if (i === -1) return
  arr.splice(i, 1)
}

export function getIndexByFullName (arr, name) {
  return arr.findIndex(item => item.full_name === name)
}
