export default {
  namespaced: true,
  state () {
    return {
      maxPage: 1,
      currentPage: 1
    }
  },
  getters: {
    getMaxPage (state) {
      return state.maxPage
    },
    getCurrentPage (state) {
      return state.currentPage
    }
  },
  mutations: {
    setMaxPage (state, page) {
      state.maxPage = page
    },
    setCurrentPage (state, page) {
      state.currentPage = page
    },
    decrementPage (state) {
      state.currentPage = state.currentPage--
    },
    incrementPage (state) {
      state.currentPage = state.currentPage++
    }
  }
}
