<template>
  <!-- w -22 h -22 -->
  <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 8.00001C8 4.49998 13.5 4.50001 13.5 8.00001C13.5 10.5 11 9.99991 11 12.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11 17.01L11.01 16.9989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script>
export default {
  name: 'IconTooltip'
}
</script>
