import securityMeta from '@/router/security/securityMeta'

export default [
  {
    path: '/security/profile/info/:id',
    name: 'security-profile-info',
    meta: securityMeta,
    component: () => import('@/views/Security/Profile/SecurityProfileInfoView')
  },
  {
    path: '/security/profile/documents/:id',
    name: 'security-profile-documents',
    meta: securityMeta,
    component: () => import('@/views/Security/Profile/SecurityProfileDocumentsView')
  },
  {
    path: '/security/profile/changelog/:id',
    name: 'security-profile-changelog',
    meta: securityMeta,
    component: () => import('@/views/Security/Profile/SecurityProfileChangelogView')
  },
  {
    path: '/security/profile/edit/:id',
    name: 'security-profile-edit',
    meta: securityMeta,
    component: () => import('@/views/Security/Profile/SecurityProfileEditView')
  }
]
