<template>
  <div :class="['form__control mb-20', classForm]">
    <input
      :class="[
        classInput,
        getError ? 'error' : '',
        IsLengthInput ? 'active' : '',
      ]"
      :id="id"
      :name="name"
      :type="getTypeInput"
      :maxlength="maxLength"
      :disabled="disabled"
      :min="min"
      :max="max"
      :value="modelValue"
      @keyup="validator"
      @keypress="handlerOnKeyPress"
      @input="handlerValueInput"
      @blur="setBlur"
      @wheel="handlerWheel"
    />

    <label
      v-if="!classInput.includes('input-border-all')"
      :for="id"
      :class="['label-valid', getError ? 'error' : 'success']"
    />

    <label
      :class="[
        'label--placeholder',
        IsLengthInput ? 'active' : '',
        disabled ? 'disabled' : '',
      ]"
      :for="id"
    >
      {{ placeholder }}
    </label>

    <!--  Hidden/Show  -->
    <VIcon
      v-if="type === 'password'"
      :class="[
        'input__eye',
        getIsHidden ? 'input__eye-hidden' : '',
        getError ? 'error' : 'success',
      ]"
      width="25"
      :height="getIsHidden ? '24' : '16'"
      :icon-name="getIsHidden ? 'EyeHidden' : 'Eye'"
      @click="setIsHidden"
    />

    <div v-if="getError">
      <small class="error-small" v-if="errors[0]?.$message">{{
        errors[0]?.$message
      }}</small>
      <small
        class="error-small"
        v-if="serverErrors?.length && !errors.length"
        >{{ serverErrors[0] }}</small
      >
      <small class="error-small" v-if="!errors?.length && !serverErrors?.length"
        >Пароли не совпадают</small
      >
    </div>
    <small v-if="description && !errors?.length" class="description">
      {{ description }}
    </small>
  </div>
</template>

<script>
  import VIcon from '@/components/ui/icon/VIcon'
  import { computed, ref } from 'vue'
  import { fieldProps } from '@/mixin/props'
  import { inputRangeMaxMin } from '@/utils/utils'
  import { CharCode } from '../../../extension/LibraryStatic/CharCode/CharCode'

  export default {
    components: { VIcon },
    mixins: [fieldProps],
    props: {
      label: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: [String, Number],
        required: false,
      },
      type: {
        type: String,
        required: false,
        default: 'text',
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      classInput: {
        type: [Array, String],
        default: 'input',
      },
      classLabel: {
        type: [Array, String],
        default: 'label',
      },
      classForm: {
        type: [Array, String],
      },
      modelValue: {
        type: [Number, String],
      },
      serverErrors: {
        type: Array,
        required: false,
      },
      isRange: {
        type: Boolean,
        required: false,
        default: false,
      },
      range: {
        type: Object,
        required: false,
      },
      maxLength: {
        type: Number,
        required: false,
        default: 150,
      },
      validatorName: {
        type: String,
        required: false,
        default: '',
      },
      isWhole: {
        type: Boolean,
        required: false,
      },
      max: {
        type: Number,
        required: false
      },
      min: {
        type: Number,
        required: false
      },
      mode: {
        type: String,
        required: false
        // whole, diapason
      }
    },
    setup(props, { emit }) {
      const isHidden = ref(true)

      const getIsHidden = computed(() => {
        return isHidden.value
      })

      const validatorList = new Map([
        ['onlyText', /[^A-Za-zА-Яа-яЁё\s]/g],
        ['onlyNumber', /[^\d[\,\.]/g],
      ])

      const IsLengthInput = computed(() => {
        if (props.modelValue === null) return 0
        return String(props.modelValue)?.length
      })

      const getError = computed(() => {
        return props.errors?.length || props.serverErrors?.length
      })

      const handlerValueInput = (e) => {
        if (props.isRange) {
          inputRangeMaxMin(e.target, props.range, props.maxLength)
        }
        emit('update:modelValue', e.target.value)
        emit('input', props.name, e.target.value)
        if (props.serverErrors?.length) props.serverErrors.length = 0
      }

      const modeKeyPress = new Map([
        ['diapason', (e) => {
          if (e.key === '-' || (props.modelValue + parseInt(e.key)) < props.min || (props.modelValue + parseInt(e.key)) > props.max) {
            e.preventDefault()
          }
        }],
        ['whole', (e) => {
          if (!props.isWhole) return
          if (e.charCode >= CharCode.zero && e.charCode <= CharCode.nine) return
          e.preventDefault()
        }]
      ])

      const handlerOnKeyPress = (e) => {
        if (props.mode && modeKeyPress.has(props.mode)) {
          modeKeyPress.get(props.mode)(e)
        }
      }

      const validator = (e) => {
        const validator = validatorList.get(props.validatorName)
        if (!validator) return
        e.target.value = e.target.value.replace(validator, '')
      }

      const setIsHidden = () => {
        isHidden.value = !isHidden.value
      }

      const getTypeInput = computed(() => {
        if (props.type !== 'password') {
          return props.type
        }
        return isHidden.value ? 'password' : 'text'
      })

      const setBlur = () => {
        emit('blur', props.name, props.modelValue)
      }

      function handlerWheel (event) {
        if (props.type !== 'number') return
        if (event.target !== document.activeElement) return

        event.target.blur()
      }

      return {
        validator,
        handlerValueInput,
        isHidden,
        setIsHidden,
        getIsHidden,
        getTypeInput,
        setBlur,
        getError,
        IsLengthInput,
        handlerOnKeyPress,
        handlerWheel
      }
    },
  }
</script>
