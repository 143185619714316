<template>
  <!--  default width=15 height=15 -->
  <circle cx="7.5" cy="7.5" r="6.71" stroke="#233F1F" stroke-width="1.58"/>
  <path d="M5 11L9.79961 5M9.79961 5L5 6M9.79961 5L9.99961 10.0909" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconOpenLot'
}
</script>
