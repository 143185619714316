export default {
  namespaced: true,
  state () {
    return {
      desiredRedirect: ''
    }
  },
  getters: {
    getRoute(state) {
      return state.desiredRedirect
    }
  },
  mutations: {
    setRoute(state, route) {
      state.desiredRedirect = route
    }
  }
}
