<template>
  <!-- default width="18" height="14" -->
  <path d="M1.1827 7.18073C1.02544 6.9132 0.906148 6.68815 0.824102 6.52527C0.899044 6.36409 1.00811 6.14262 1.1532 5.87977C1.47224 5.30176 1.96072 4.53229 2.63677 3.76555C3.9878 2.23329 6.04869 0.75 9 0.75C11.6575 0.75 13.7225 2.21964 15.1642 3.77873C15.8802 4.553 16.4236 5.3301 16.7879 5.91422C16.9486 6.17187 17.0735 6.39044 17.1621 6.5526C17.0807 6.71294 16.9659 6.92785 16.8173 7.18073C16.4757 7.76188 15.9598 8.53527 15.2637 9.30588C13.8676 10.8516 11.8042 12.3281 9 12.3281C6.19579 12.3281 4.13243 10.8516 2.73626 9.30588C2.04024 8.53527 1.52431 7.76188 1.1827 7.18073ZM17.3224 6.86059C17.3224 6.86065 17.3224 6.86062 17.3224 6.8605L17.3224 6.86059Z" stroke="#233F1F" stroke-width="1.5"/>
  <circle cx="9" cy="6.75" r="2.625" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconHideLot'
}
</script>
