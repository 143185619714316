import { $authHost } from '@/http'

const TRADE_OFFER_PATTERN_URL = '/trade-offer/patterns'

export const createPatternLot = async (pattern) => {
  const { data } = await $authHost.post(TRADE_OFFER_PATTERN_URL, { pattern })
  return data
}

export const getPatterns = async () => {
  const { data } = await $authHost.get(TRADE_OFFER_PATTERN_URL)
  return data
}

export const updatePatternLot = async (id, pattern) => {
  const { data } = await $authHost.put(`${TRADE_OFFER_PATTERN_URL}/${id}`, { pattern })
  return data
}

export const destroyPatternLot = async (id) => {
  const { data } = await $authHost.delete(`${TRADE_OFFER_PATTERN_URL}/${id}`)
  return data
}
