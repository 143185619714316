import { Centrifuge } from 'centrifuge'
import { WebSocket } from '@/modules/websocket/WebSocket'
import { WebSocketsCentrifugeSubscribes } from '@/modules/websocket/centrifuge/WebSocketsCentrifugeSubscribes'

export class WebSocketCentrifuge extends WebSocket {
  constructor () {
    super({
      listeners: [
        {
          name: 'on',
          event: 'connecting',
          method: 'onConnect'
        },
        {
          name: 'on',
          event: 'disconnected',
          method: 'onDisconnect'
        }
      ],
      name: 'WebSocketCentrifuge'
    })
    this.subscribes = new WebSocketsCentrifugeSubscribes()
    this.centrifuge = {}
    this.URL = process.env.VUE_APP_WEBSOKET_URL || 'ws://localhost:8888/connection/websocket'
  }

  connect () {
    try {
      const centrifuge = new Centrifuge(this.URL, {
        token: localStorage.getItem('centrifugeToken')
      })
      this.initListeners(centrifuge)
      centrifuge.connect()
      this.centrifuge = centrifuge
    } catch (e) {
      console.log(e)
    }
  }

  disconnect () {
    this.centrifuge.disconnect()
  }

  subscribe (channel, nameMethod) {
    if (Object.keys(this.centrifuge).length) {
      const sub = this.centrifuge.newSubscription(channel)
      sub.on(nameMethod, this.subscribes[nameMethod])
      sub.subscribe()
      sub.publish({})
    }
  }

  onConnect (ctx) {
    console.log('Подключение установлено', ctx)
  }

  onDisconnect (ctx) {
    console.log('Подключение разорвано', ctx)
  }
}
