<script setup>
import VBreadcrumbsItem from '@/modules/breadcrumbs/component/VBreadcrumbsItem'
import { useBreadcrumbs } from '@/modules/breadcrumbs/useBreadcrumbs'
const { breadcrumbs } = useBreadcrumbs()
</script>

<template>
  <ul class="breadcrumbs__list">
    <v-breadcrumbs-item
      v-for="(breadcrumb, i) in breadcrumbs"
      :key="i"
      :is-last="i === (breadcrumbs.length - 1)"
      :item="breadcrumb"
    />
  </ul>
</template>

<style scoped>
.breadcrumbs__list {
  padding: 20px 0;

  display: flex;
}
</style>
