import { NomenclatureService } from '@/service/api/NomenclatureService'
import { Request } from '@/service/api/Request/Request'

export class NomenclatureController extends Request {
  #nomenclatureService = new NomenclatureService()

  async getNomenclature (name) {
    try {
      return this.#nomenclatureService.getNomenclature(name)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
