// названия для модальных окон создавать в APP_MODAL
export default {
  namespaced: true,
  state: () => ({
    name: '',
    data: null
  }),
  mutations: {
    OPEN_MODAL: (state, name) => {
      state.name = name
    },
    CLOSE_MODAL: state => {
      state.name = ''
    }
  }
}
