<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__cop">
          <p class="footer__el">Copyright © 2022 «СИТНО»</p>
<!--          <button class="btn btn-link footer__el">Карта сайта</button>-->
          <VIcon class="footer__el cursor" width="122" height="34" icon-name="Logo" @click="goSitno"/>
        </div>

        <div class="footer__contact">
          <div class="footer__el">Контактная информация</div>
          <!--          <button class="btn btn-link btn-link-classic footer__el">Контактная информация</button>-->
          <div class="footer__email cur footer__el">Электроннная почта: <a href = "mailto: info@sitno.ru" class="btn btn-link btn-link-classic">info@sitno.ru</a></div>
          <div class="contact footer__el">
            <div class="contact__link">
              <button class="btn btn-contact">
                <VIcon width="29" height="29" icon-name="VK" fill="none" @click="goVK"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import { goSitno, goVK } from '@/utils/goRoute'

export default {
  setup () {
    return {
      goSitno,
      goVK
    }
  },
  components: { VIcon }
}
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
</style>
