import organizerMeta from '@/router/organizer/organizerMeta'

export default [
  {
    path: '/organizer/my-trade/sidebar',
    name: 'organizer-my-trade-sidebar',
    redirect: { name: 'organizer-my-trade-active' },
    meta: {
      ...organizerMeta,
      layout: 'main-sidebar-layout'
    },
    props: {
      title: 'Мои ТП'
    },
    component: () => import('@/views/Organizer/Sidebar/OrganizerSidebarMyTradeView'),
    children: [
      {
        path: '/organizer/my-trade/active',
        name: 'organizer-my-trade-active',
        meta: organizerMeta,
        component: () => import('@/views/Organizer/myTrade/OrganizerMyTradeActiveView')
      },
      {
        path: '/organizer/my-trade/archive',
        name: 'organizer-my-trade-archive',
        meta: organizerMeta,
        component: () => import('@/views/Organizer/myTrade/OrganizerMyTradeArchiveView')
      },
      {
        path: '/organizer/my-trade/results',
        name: 'organizer-my-trade-results',
        redirect: { name: 'organizer-my-trade-summarizing' },
        meta: organizerMeta,
        component: () => import('@/views/Organizer/myTrade/OrganizerMyTradeResultsView')
      },
      {
        path: '/organizer/my-trade/statistics',
        name: 'organizer-my-trade-statistics',
        meta: organizerMeta,
        component: () => import('@/views/Organizer/myTrade/OrganizerMyTradeStatisticsView')
      }
    ]
  }
]
