export default {
  namespaced: true,
  state () {
    return {
      filter: null,
      search: null
    }
  },
  getters: {
    getFilter (state) {
      return state.filter
    },
    getSearch (state) {
      return state.search
    }
  },
  mutations: {
    setFilter (state, filter) {
      state.filter = filter
    },
    setSearch (state, search) {
      state.search = search
    },
    clearFilter (state) {
      state.filter = null
    },
    clearSearch (state) {
      state.search = null
    }
  }
}
