// All
export const DELAY_SEARCH = 400
export const TRADE_OFFER_URL = '/trade-offer/lots'
export const TRADE_OFFER_URL_SHOWCASE = '/trade-offer/showcase'
export const EXPAND_LOT = 'expand=deliveryAddress,procedureType,paymentTerm,currency,status,company,deliveryCondition,file,position,user,bidder,file,is_follow,protocol,history,categories'
export const DELAY_ANIMATION = 300
export const EXPAND_SHOWCASE_LOT = 'expand=status,company,file,user,bidder,is_follow,categories,procedureType'
export const MAX_DATEPICKER = new Date(2100, 0, 1, 0, 0, 0, 0)
export const RUB_NAME = 'Рубли'

// Controller name
export const CATEGORY_CONTROLLER = 'categoryController'
export const AUTH_CONTROLLER = 'authController'
export const ITEM_CONTROLLER = 'itemController'
export const FILES_CONTROLLER = 'filesController'
export const LOT_CONTROLLER = 'lotController'
export const ACCREDITATION_CONTROLLER = 'accreditationController'
export const BIDDING_CONTROLLER = 'biddingProcedureController'
export const PARAMETERS_CONTROLLER = 'parametersController'
export const MANUAL_CONTROLLER = 'manualController'
export const NOMENCLATUR_CONTROLLER = 'nomenclaturController'

// Table modification
export const TABLE_OFFER_INJECT = 'tableModification'
