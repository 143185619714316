<template>
  <!-- default width="58" height="58" -->
  <mask id="path-2-inside-1_420_4950" fill="white">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4354 19.5845C37.4354 18.8356 36.8284 18.2285 36.0795 18.2285H25.233C24.4841 18.2285 23.877 18.8356 23.877 19.5845C23.877 20.3333 23.2699 20.9404 22.521 20.9404H17.7422C16.6376 20.9404 15.7422 21.8359 15.7422 22.9404V39.2781C15.7422 40.3827 16.6376 41.2781 17.7422 41.2781H43.5708C44.6753 41.2781 45.5708 40.3827 45.5708 39.2781V22.9404C45.5708 21.8359 44.6753 20.9404 43.5708 20.9404H38.7914C38.0425 20.9404 37.4354 20.3333 37.4354 19.5845Z"/>
  </mask>
  <path d="M25.233 19.9285H36.0795V16.5285H25.233V19.9285ZM17.7422 22.6404H22.521V19.2404H17.7422V22.6404ZM17.4422 39.2781V22.9404H14.0422V39.2781H17.4422ZM43.5708 39.5781H17.7422V42.9781H43.5708V39.5781ZM43.8708 22.9404V39.2781H47.2708V22.9404H43.8708ZM38.7914 22.6404H43.5708V19.2404H38.7914V22.6404ZM38.7914 19.2404C38.9814 19.2404 39.1354 19.3945 39.1354 19.5845H35.7354C35.7354 21.2722 37.1036 22.6404 38.7914 22.6404V19.2404ZM47.2708 22.9404C47.2708 20.897 45.6142 19.2404 43.5708 19.2404V22.6404C43.7364 22.6404 43.8708 22.7747 43.8708 22.9404H47.2708ZM43.5708 42.9781C45.6142 42.9781 47.2708 41.3215 47.2708 39.2781H43.8708C43.8708 39.4438 43.7364 39.5781 43.5708 39.5781V42.9781ZM14.0422 39.2781C14.0422 41.3215 15.6987 42.9781 17.7422 42.9781V39.5781C17.5765 39.5781 17.4422 39.4438 17.4422 39.2781H14.0422ZM17.7422 19.2404C15.6987 19.2404 14.0422 20.897 14.0422 22.9404H17.4422C17.4422 22.7747 17.5765 22.6404 17.7422 22.6404V19.2404ZM22.177 19.5845C22.177 19.3945 22.331 19.2404 22.521 19.2404V22.6404C24.2088 22.6404 25.577 21.2722 25.577 19.5845H22.177ZM36.0795 19.9285C35.8895 19.9285 35.7354 19.7745 35.7354 19.5845H39.1354C39.1354 17.8967 37.7672 16.5285 36.0795 16.5285V19.9285ZM25.233 16.5285C23.5452 16.5285 22.177 17.8967 22.177 19.5845H25.577C25.577 19.7745 25.423 19.9285 25.233 19.9285V16.5285Z" fill="white" mask="url(#path-2-inside-1_420_4950)"/>
  <circle cx="30.6561" cy="31.0614" r="5.04635" stroke="white" stroke-width="1.7"/>
  <path d="M6.62891 17.3999H19.886" stroke="white" stroke-width="1.5"/>
  <path d="M13.2578 10.7715L13.2578 24.0286" stroke="white" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconCamera'
}
</script>
