<template>
  <!-- default width="25" height="16" -->
  <path d="M1.36081 8.57304C1.13334 8.24015 0.962778 7.96204 0.847971 7.76491C0.952818 7.56981 1.10853 7.29641 1.3181 6.96982C1.76255 6.27716 2.4451 5.35166 3.3928 4.42708C5.28265 2.58332 8.21603 0.75 12.4488 0.75C16.2798 0.75 19.2182 2.57171 21.2259 4.43938C22.228 5.37157 22.9867 6.30522 23.4941 7.00501C23.7277 7.32733 23.9071 7.59872 24.0316 7.7957C23.9172 7.99055 23.7526 8.25713 23.5367 8.57304C23.0609 9.2694 22.3403 10.1991 21.3654 11.1276C19.4168 12.9834 16.4805 14.811 12.4488 14.811C8.41708 14.811 5.48079 12.9834 3.53218 11.1276C2.55725 10.1991 1.83665 9.2694 1.36081 8.57304Z" stroke="#233F1F" stroke-width="1.5"/>
  <circle cx="12.4486" cy="7.78047" r="3.91829" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconEye'
}
</script>
