<template>
  <!-- default width="38" height="38" -->
  <rect width="38" height="38" rx="19" fill="#FFF"/>
  <path d="M12.1573 14.8709C12.1345 14.7002 12.2673 14.5485 12.4395 14.5485H25.7823C25.9545 14.5485 26.0873 14.7002 26.0645 14.8709L24.0904 29.6764C24.0716 29.8179 23.9509 29.9235 23.8082 29.9235H14.4136C14.2709 29.9235 14.1502 29.8179 14.1313 29.6764L12.1573 14.8709Z" stroke="currentColor" stroke-width="1.70833"/>
  <path d="M10 11.416H28.2222" stroke="currentColor" stroke-width="1.70833"/>
  <path d="M19.1113 8L19.1113 11.4167" stroke="currentColor" stroke-width="1.70833"/>
  <path d="M16.2635 17.1113L17.4023 27.3613" stroke="currentColor" stroke-width="1.70833"/>
</template>

<script>
export default {
  name: ''
}
</script>
