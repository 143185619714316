import { Request } from '@/service/api/Request/Request'
import { BiddingProcedureService } from '@/service/api/BiddingProcedureService'
import { Alert } from '@/extension/Alert/Alert'

export class BiddingProcedureController extends Request {
  constructor () {
    super()
    this.biddingService = new BiddingProcedureService()
  }

  async getLastPrice (lotId) {
    try {
      return await this.biddingService.getLastPrice(lotId)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async setBidderDocuments (lotId, files, sort) {
    try {
      return await this.biddingService.sendBidderDocuments(lotId, files, sort)
    } catch (e) {
        await Alert.show('warn', 'Ставка успешно создана, но прикрепленный файл не отправлен. При необходимости передачи файла обратитесь в службу поддержки')  
    }
  }

  async getBidderCardList (lotId) {
    try {
      return await this.biddingService.getBidderCardList(lotId)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async participateBidder (formData, list) {
    try {
      return await this.biddingService.participateBidder(formData, list)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getOfferForEditView (lotId, userId, sort, isFake) {
    try {
      return await this.biddingService.getOfferForEditView(lotId, userId, sort, isFake)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async sendResultStatus (lotId, status) {
    try {
      await this.biddingService.sendResultStatus(lotId, status)
      await Alert.show('success', 'Статус торгов успешно изменён')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getCardList (lotId) {
    try {
      return await this.biddingService.getCardList(lotId)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async downloadExcelResultOrganizer(lotId) {
    try {
      return await this.biddingService.downloadExcelResultOrganizer(lotId)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
