<template>
  <div class="body" v-if="!isStop">
      <transition name="slide-fade">
        <VAlert v-if="getIsAlert && !isWorks && getIsAlert.description" />
      </transition>
      <component :is="layout" :key="slug">
      </component>
     <TheCookiesBanner v-if="isBanner" @close="isBanner = !isBanner" :class="classActive ? 'active' : ''" />

    <ModalNeedAccreditation v-if="isOpenModalNeedAccreditation" />
  </div>

  <div class="r-block" v-else>

    <div class="r-block__text">
      Коллеги, функционал тендерной площадки перенесен на <a class="btn btn-link" href="https://newtender.sitno.ru"><b>боевой сервер</b></a>
      <br>
      Для начала работы нужно сбросить все данные на всех этапах.
      <br>
      Мы обнуляем данные на промежуточной базе и на площадке.
      <br>
      <div class="mt-20">В скором времени площадка станет доступна для дальнейшего тестирования.</div>

    </div>
    <div class="mt-20">
      <a class="btn btn-primary btn-bg-transparent" href="https://newtender.sitno.ru"><b>Перейти</b></a>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { computed, onMounted, provide, ref, watch } from 'vue'
import AuthLayout from '@/layout/AuthLayout'
import MainSidebarLayout from '@/layout/MainSidebarLayout'
import MainLayout from '@/layout/MainLayout'
import MainLotLayout from '@/layout/MainLotLayout'
import VAlert from '@/components/ui/alert/VAlert'
import { useAlert } from '@/use/Base/useAlert'
import { useStore } from 'vuex'
import TheCookiesBanner from '@/components/TheCookiesBanner'
import { useWebSocket } from '@/modules/websocket/centrifuge/hooks/useWebSocket'
import { AppController } from '@/controllers/AppController'
import {
  ACCREDITATION_CONTROLLER,
  ITEM_CONTROLLER,
  AUTH_CONTROLLER,
  FILES_CONTROLLER,
  CATEGORY_CONTROLLER,
  MANUAL_CONTROLLER,
  LOT_CONTROLLER,
  NOMENCLATUR_CONTROLLER,
  BIDDING_CONTROLLER,
  PARAMETERS_CONTROLLER
} from '@/utils/consts'
import ModalNeedAccreditation from '@/components/ui/modal/ModalNeedAccreditation'
import { APP_MODAL } from '@/utils/consts/appModal'

export default {
  setup () {
    const isStop = false
    const route = useRoute()
    const store = useStore()
    const isBanner = ref(true)
    const classActive = ref(false)

    const appController = new AppController()
    provide(CATEGORY_CONTROLLER, appController.categoryController)
    provide(AUTH_CONTROLLER, appController.authController)
    provide(ITEM_CONTROLLER, appController.itemController)
    provide(FILES_CONTROLLER, appController.filesController)
    provide(LOT_CONTROLLER, appController.lotController)
    provide(ACCREDITATION_CONTROLLER, appController.accreditationController)
    provide(BIDDING_CONTROLLER, appController.biddingProcedureController)
    provide(PARAMETERS_CONTROLLER, appController.parametersController)
    provide(MANUAL_CONTROLLER, appController.manualController)
    provide(NOMENCLATUR_CONTROLLER, appController.nomenclaturController)

    const isWorks = computed(() => {
      return store.getters.getIsWorks
    })

    function getAcceptCookie () {
      if (document.cookie.includes('acceptCookie')) {
        isBanner.value = false
        return
      }
      setTimeout(() => {
        classActive.value = true
      }, 200)
      let date = new Date(Date.now() + 5256e+6)
      date = date.toUTCString()
      document.cookie = 'acceptCookie=true; expires=' + date
    }

    onMounted(getAcceptCookie)

    const { connect, disconnect, ws } = useWebSocket()
    provide('ws', ws)

    const getIsAuth = computed(() => {
      return store.getters['auth/getIsAuth']
    })

    watch(getIsAuth, () => {
      if (!getIsAuth.value) {
        disconnect()
      } else {
        connect()
      }
    })

    const isOpenModalNeedAccreditation = computed(() => store.state.modal.name === APP_MODAL.needAccreditation)

    return {
      isStop,
      isBanner,
      classActive,
      ...useAlert(),
      layout: computed(() => route.meta.layout),
      slug: computed(() => route.meta.slug),
      isWorks,
      isOpenModalNeedAccreditation
    }
  },
  components: {
    ModalNeedAccreditation,
    TheCookiesBanner,
    VAlert,
    AuthLayout,
    MainSidebarLayout,
    MainLayout,
    MainLotLayout
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/style.scss";
.body {
  position: relative;
  overflow: hidden;
}

.r-block {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-yellow;

  &__text {
    font-size: 24px;
    text-align: center;
    line-height: 30px;

    & > a {
      font-size: 24px;
    }

    & > div {
      font-size: 18px;
    }

    &.b-btn {
      text-align: center;
    }
  }
}
</style>
