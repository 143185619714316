<template>
  <!-- default width="30" height="28" -->
  <path d="M15 0L19.9374 8.20426L29.2658 10.3647L22.9889 17.5957L23.8168 27.1353L15 23.4L6.18322 27.1353L7.01113 17.5957L0.734152 10.3647L10.0626 8.20426L15 0Z" fill="white"/>
</template>

<script>
export default {
  name: 'IconFavoritesLot'
}
</script>
