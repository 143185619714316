import biddingMeta from './biddingMeta'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/bidding-procedure/:id',
    name: 'bidding-procedure',
    redirect: { name: 'bidding-procedure-history' },
    meta: {
      ...biddingMeta,
      back: {
        name: 'bidder-lot-info',
        params: 'id'
      }
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/bidding-procedure/:id/history',
    name: 'bidding-procedure-history',
    component: () => import('@/views/BiddingProcedure/BiddingStatusView'),
    meta: {
      ...biddingMeta,
      layout: 'main-layout',
      back: {
        name: 'bidder-lot-info',
        params: 'id'
      }
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/bidding-procedure/:id/statistic',
    name: 'bidding-procedure-statistic',
    component: () => import('@/views/BiddingProcedure/BiddingStatusView'),
    meta: {
      ...biddingMeta,
      layout: 'main-layout',
      back: {
        name: 'bidder-lot-info',
        params: 'id'
      }
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  }
]
