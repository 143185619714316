import { LotService } from '@/service/api/LotService'
import { Alert } from '@/extension/Alert/Alert'
import { Request } from '@/service/api/Request/Request'
import router from '@/router/index'
import { formatDateTimeForServer } from '@/utils/format/date'

export class LotController extends Request {
  #lotService = new LotService()

  async getLotById (id) {
    try {
      return await this.#lotService.getLotById(id)
    } catch (e) {
      await router.push({ name: 'showcase' })
      await this.handlerErrors.showAlert(e)
    }
  }

  async delete (id) {
    try {
      await this.#lotService.delete(id)
      await Alert.show('success', 'Лот успешно удалён')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async extend (id, data = {}) {
    try {
      const formData = {
        renewal_date: data.date ? formatDateTimeForServer(data.date) : null
      }

      await this.#lotService.extend(id, formData)
      await Alert.show('success', 'Лот успешно продлён')
    } catch (e) {
      console.log(e)
      await this.handlerErrors.showAlert(e)
    }
  }

  async public (id) {
    try {
      await this.#lotService.public(id)
      await Alert.show('success', 'Лот успешно опубликован')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async cancel (id) {
    try {
      await this.#lotService.cancel(id)
      await Alert.show('success', 'Лот успешно отменён')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async hide (id) {
    try {
      await this.#lotService.hide(id)
      await Alert.show('success', 'Лот успешно скрыт')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async open (id) {
    try {
      await this.#lotService.open(id)
      await Alert.show('success', 'Лот успешно открыт')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async attach (lotId, userId) {
    try {
      const message = await this.#lotService.attach(lotId, userId)
      await Alert.show('success', message)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async detach (lotId, userId) {
    try {
      const message = await this.#lotService.detach(lotId, userId)
      await Alert.show('success', message)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async follow (lotId, userId) {
    try {
      const message = await this.#lotService.follow(lotId, userId)
      await Alert.show('success', message)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async unfollow (lotId, userId) {
    try {
      const message = await this.#lotService.unfollow(lotId, userId)
      await Alert.show('success', message)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getChangeStatusLot (id) {
    try {
      return await this.#lotService.getLotById(id)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async createPatternLot (pattern) {
    try {
      await this.#lotService.createPatternLot(pattern)
      await Alert.show('success', 'Шаблон создан')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async updatePatternLot (id, pattern) {
    try {
      await this.#lotService.updatePatternLot(id, pattern)
      await Alert.show('success', 'Шаблон сохранен')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async destroyPatternLot (id) {
    try {
      await this.#lotService.destroyPatternLot(id)
      await Alert.show('success', 'Шаблон удален')
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getTemplate () {
    try {
      return await this.#lotService.getTemplate()
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async deleteBeforePublic (id) {
    try {
      return await this.#lotService.deleteBeforePublic(id)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async restore (id) {
    try {
      await this.#lotService.restore(id)
      await Alert.show('success', 'Лот успешно восстановлен')
    } catch (e) {
      await this.handlerErrors.showAlert(e, 'Не удалось восстановить лот')
    }
  }
}
