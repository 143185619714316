export default {
  namespaced: true,
  state () {
    return {
      accreditation: null
    }
  },
  getters: {
    getAccreditation (state) {
      return state.accreditation
    }
  },
  mutations: {
    setAccreditation (state, accreditation) {
      state.accreditation = accreditation
    }
  }
}
