<template>
  <!-- default width="33" height="33" -->
  <path d="M10.7354 23.2501C10.3211 23.2501 9.98535 23.5859 9.98535 24.0001C9.98535 24.4143 10.3211 24.7501 10.7354 24.7501V23.2501ZM22.5001 24.7501C22.9143 24.7501 23.2501 24.4143 23.2501 24.0001C23.2501 23.5859 22.9143 23.2501 22.5001 23.2501V24.7501ZM10.7354 24.7501H22.5001V23.2501H10.7354V24.7501Z" fill="currentColor"/>
  <path d="M16.853 8V21.1765M16.853 21.1765L11.6765 17.0872M16.853 21.1765L22.0295 17.0872" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <circle cx="16.5" cy="16.5" r="15.75" stroke="currentColor" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconLoadFile'
}
</script>
