<template>
  <li
    v-reader="notificationData.reading_at"
    :class="['notification', isWindow ? 'notification__bg-white notification__border-0' : '']"
    :data-index="idx"
  >
    <div class="cursor" @click="$router.push({ name: `bidder-lot-info/${notificationData.lot_id}` })">
      <div class="notification__header">
        <img class="notification__img" :src="require(`@/assets/icon/notifications/${notificationData.status}.svg`)" :alt="notificationData.status"/>
        <h3 class="notification__title">{{ notificationData.title }}</h3>
      </div>
      <div class="notification__description">
        <p>{{ notificationData.description }}</p>
      </div>
      <div class="notification__date">
        <p>{{ notificationData.date }}</p>
      </div>
    </div>
  </li>
</template>

<script>
import { reader } from '@/directives/notification.reader'

export default {
  props: {
    isWindow: {
      type: Boolean,
      required: false,
      default: false
    },
    notificationData: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: false
    }
  },
  directives: {
    reader
  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
