export default {
  namespaced: true,
  state () {
    return {
      items: [],
      isLoad: false,
      selectedItem: null
    }
  },
  getters: {
    getItems (state) {
      return state.items
    },
    getIsLoad (state) {
      return state.isLoad
    },
    getSelectedItem (state) {
      return state.selectedItem
    }
  },
  mutations: {
    setItems (state, items) {
      state.items.length = 0
      state.items = items
    },
    setOneItem (state, item) {
      state.items.push(item)
    },
    changeItem (state, item) {
      state.items.map((el) => {
        if (el.id === item.id) {
          Object.assign(el, item)
        }
      })
    },
    changeActiveStateItem (state, { action, items }) {
      items.map((id) => {
        state.items.map((el) => {
          if (el.id === id) {
            el.is_active = action === 'active'
            el.status = action === 'active' ? 'Активный' : 'Нет'
          }
        })
      })
    },
    selectedItem (state, item) {
      state.selectedItem = item
    },
    deleteItemsById (state, ids) {
      ids.forEach((id) => {
        state.items = state.items.filter(item => item.id !== id)
      })
    },
    clearItems (state) {
      state.items.length = 0
    },
    setIsLoad (state) {
      state.isLoad = !state.isLoad
    }
  }
}
