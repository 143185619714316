 import bidderMeta from '@/router/bidder/bidderMeta'
import { checkRole } from '@/utils/utils'
import { Alert } from '@/extension/Alert/Alert'

export default [
  {
    path: '/bidder/lot-create/:id',
    name: 'bidder-lot-create',
    meta: {
      ...bidderMeta,
      layout: 'main-lot-layout',
      back: {
        name: 'bidder-lot-info',
        params: 'id'
      },
    },
    component: () => import('@/views/Bidder/LotForm/BidderLotCreateView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        await Alert.show('error', 'Нет доступа')
      }
    }
  },
  {
    path: '/bidder/lot-edit/:id/:userId',
    name: 'bidder-lot-edit',
    meta: {
      ...bidderMeta,
      layout: 'main-lot-layout',
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          route: { name: 'organizer' },
          isCheckRole: true
        },
        {
          name: '',
          paramsKey: 'id',
          route: ({ id }) => {
            return { name: 'organizer-lot-info', params: { id } }
          }
        },
        {
          name: 'Ставка'
        }
      ],
      back: {
        name: 'organizer-lot-info',
        params: 'id'
      }
    },
    component: () => import('@/views/Bidder/BidderLotEditView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole(['bidder', 'organizer'])
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        await Alert.show('error', 'Нет доступа')
      }
    }
  },
  {
    path: '/bidder/lot-info/:id',
    name: 'bidder-lot-info',
    meta: {
      ...bidderMeta,
      layout: 'main-lot-layout',
      back: {},
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          route: { name: 'bidder' },
          isCheckRole: true
        },
        {
          name: '',
          paramsKey: 'id'
        }
      ]
    },
    component: () => import('@/views/Bidder/BidderLotInfoView'),
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('bidder')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        await Alert.show('error', 'Нет доступа')
      }
    }
  }
]
