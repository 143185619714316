<template>
  <!-- default width="19" height="19" -->
  <circle cx="9.5" cy="9.5" r="8.75" stroke="#233F1F" stroke-width="1.5"/>
  <path d="M5 10L14 10" stroke="#233F1F" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconBlock'
}
</script>
