import { helpers, required, minLength, maxLength, email } from '@vuelidate/validators'
import { checkNumberInn, checkNumberOgrn, notEarlier } from '@/utils/validator/customValidator'
import { ERROR_TEXT } from '@/utils/validator/errorText'

export const createNameBasedOnNumber = (num) => {
  if (num < 5) {
    return 'символа'
  } else {
    return 'символов'
  }
}

export default class ErrorValidationMessage {
  static RequiredError () {
    return helpers.withMessage(ERROR_TEXT.required, required)
  }

  static MinLengthError (minLengthField, fieldName) {
    return helpers.withMessage(`Минимальная длина ${fieldName} ${minLengthField} ${createNameBasedOnNumber(minLengthField)}`, minLength(minLengthField))
  }

  static EmailError () {
    return helpers.withMessage('Введите корректный email', email)
  }

  static MaxLengthError (maxLengthField, fieldName) {
    return helpers.withMessage(`Максимальная длина ${fieldName} ${maxLengthField} ${createNameBasedOnNumber(maxLengthField)}`, maxLength(maxLengthField))
  }

  static InnError () {
    return helpers.withMessage('Некорректное контрольное число', checkNumberInn)
  }

  static OgrnError () {
    return helpers.withMessage('Некорректное контрольное число', checkNumberOgrn)
  }

  static EarlierError () {
    return helpers.withMessage('Дата не может быть установлена раньше текущего числа', notEarlier)
  }
}
