import { AccreditationController } from '@/controllers/AccreditationController'
import { AuthController } from '@/controllers/AuthController'
import { CategoryController } from '@/controllers/CategoryController'
import { FilesController } from '@/controllers/FilesController'
import { ItemController } from '@/controllers/ItemController'
import { LotController } from '@/controllers/LotController'
import { BiddingProcedureController } from '@/controllers/BiddingProcedureController'
import { ParametersController } from '@/controllers/ParametersController'
import { ManualController } from '@/controllers/ManualController'
import { NomenclatureController } from '@/controllers/NomenclatureController'

export class AppController {
  constructor () {
    this.accreditationController = new AccreditationController()
    this.authController = new AuthController()
    this.categoryController = new CategoryController()
    this.filesController = new FilesController()
    this.itemController = new ItemController()
    this.lotController = new LotController()
    this.biddingProcedureController = new BiddingProcedureController()
    this.parametersController = new ParametersController()
    this.manualController = new ManualController()
    this.nomenclaturController = new NomenclatureController()
  }
}
