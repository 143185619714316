import { getUser } from '@/http/user/userApi'
import { Alert } from '@/extension/Alert/Alert'

export default {
  namespaced: true,
  state () {
    return {
      user: null,
      users: null
    }
  },
  getters: {
    getUser (state) {
      return state.user
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }
  },
  actions: {
    async setUser ({ commit, dispatch }, id) {
      try {
        const { data } = await getUser(id)
        commit('setUser', data)
      } catch (e) {
        await Alert('success', 'Не удалось получить пользователя')
      }
    }
  }
}
