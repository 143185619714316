import { ref } from 'vue'
import Validator from '@/extension/Validator/Validator'

export function useForm (
  rules,
  formData,
  formAction = null,
  formAfterAction = null,
  resetForm = true) {
  const validator = new Validator(rules, formData)
  const isSend = ref(false)

  const onSubmit = async () => {
    try {
      if (!validator.validateForm()) {
        return formAfterAction ? formAfterAction() : false
      }
      isSend.value = true
      if (formAction) {
        await formAction()
      }
      if (resetForm) {
        validator.v$.value.$reset()
      }
    } finally {
      isSend.value = false
    }
  }

  return {
    resetValidateField: validator.resetValidateField,
    validateForm: validator.validateForm,
    validateField: validator.validateField,
    v$: validator.v$,
    onSubmit,
    formData,
    isSend
  }
}
