<template>
  <!-- w -26 h -21 -->
  <mask id="path-1-inside-1_2110_1180" fill="white">
    <rect x="11.9619" width="13.1034" height="17.2414" rx="1"/>
  </mask>
  <rect x="11.9619" width="13.1034" height="17.2414" rx="1" stroke="currentColor" stroke-width="3" mask="url(#path-1-inside-1_2110_1180)"/>
  <path d="M12.2288 2.75879H10.2034C9.65108 2.75879 9.20337 3.2065 9.20337 3.75879V19.0002C9.20337 19.5525 9.65108 20.0002 10.2034 20.0002H21.3068C21.8591 20.0002 22.3068 19.5525 22.3068 19.0002V17" stroke="currentColor" stroke-width="1.5"/>
  <path d="M16.2287 11H1.22874M1.22874 11L5.81208 7M1.22874 11L5.81208 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</template>

<script>
export default {
  name: 'IconCopy'
}
</script>
