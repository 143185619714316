import { Request } from '@/service/api/Request/Request'
import { getAllMyCategory, getUserCategory, setUserCategory } from '@/http/user/userCategoryApi'
import store from '@/store/index'

export class CategoryService extends Request {
  async setCategory (categories) {
    try {
      const { data } = await setUserCategory(categories)
      return data
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getUserCategory () {
    try {
      const { data } = await getUserCategory()
      data.forEach((cat) => {
        cat.active = false
      })

      store.commit('userCategory/setCategoryList', data)
      return data
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }

  async getMyCategories () {
    try {
      const { data } = await getAllMyCategory()
      store.commit('userCategory/setActiveCategoryList', data)
      return data
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
