<template>
  <!-- default width="18" height="18" -->
  <path d="M0.953369 9H17.2034" stroke="currentColor" stroke-width="1.5"/>
  <path d="M9.07837 0.875L9.07837 17.125" stroke="currentColor" stroke-width="1.5"/>
</template>

<script>
export default {
  name: 'IconPlus'
}
</script>
