import { createStore } from 'vuex'
import auth from './modules/auth.module'
import accreditation from './modules/accreditation.module'
import lot from './modules/items/lot.module'
import user from './modules/users/user.module'
import table from './modules/items/table.module'
import userCategory from './modules/users/userCategory.module'
import files from './modules/files.module'
import pagination from './modules/items/pagination.module'
import filter from './modules/items/filter.module'
import item from './modules/items/item.module'
import display from './modules/items/display.module'
import notification from './modules/notification.module'
import manual from './modules/manual.module'
import parameter from './modules/parameter/parameter.module'
import cell from './modules/table/cell.module'
import cellValidate from './modules/table/cellValidate.module'
import redirect from './modules/smartRedirect.module'
import offer from './modules/offer.module'
import modal from '@/store/modules/modal.module'
import breadcrumbs from '@/modules/breadcrumbs/store/breadcrumbs.module'

export default createStore({
  state: {
    alert: null,
    currentRoute: '',
    isWorks: false,
    queryWorks: ''
  },
  getters: {
    getAlert (state) {
      return state.alert
    },
    getQueryWorks (state) {
      return state.queryWorks
    },
    getCurrentRoute (state) {
      return state.currentRoute
    },
    getIsWorks (state) {
      return state.isWorks
    }
  },
  mutations: {
    setAlert (state, alert) {
      state.alert = alert
    },
    clearAlert (state) {
      state.alert = null
    },
    setCurrentRoute (state, route) {
      state.currentRoute = route
    },
    setIsWorks (state) {
      state.isWorks = !state.isWorks
    },
    setQueryWorks (state, query) {
      state.queryWorks = query
    }
  },
  actions: {
    setAlert ({ commit, state }, alert) {
      commit('setAlert', alert)
      setTimeout(() => {
        commit('clearAlert')
      }, 7000)
    }
  },
  modules: {
    auth,
    accreditation,
    item,
    user,
    files,
    filter,
    userCategory,
    pagination,
    lot,
    table,
    notification,
    display,
    manual,
    parameter,
    cell,
    redirect,
    offer,
    modal,
    cellValidate,
    breadcrumbs
  }
})
