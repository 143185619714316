import { helpers } from '@vuelidate/validators'

export function validateOgrn (ogrn) {
  let result = false

  if (ogrn) {
    if (ogrn.length === 15) {
      const n15 = parseInt((parseInt(ogrn.slice(0, -1)) % 13).toString().slice(-1))
      if (n15 === parseInt(ogrn[14])) result = true
    } else {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1))
      if (n13 === parseInt(ogrn[12])) result = true
    }
  } else {
    result = true
  }
  return result
}

function validateINN (value) {
  if (!(/^\d{10}$/.test(value)) && !(/^\d{12}$/.test(value))) {
    return false
  }

  switch (value.length) {
    case 10: {
      const sum10 = checkSumINN(value, [2, 4, 10, 3, 5, 9, 4, 6, 8])
      return sum10 === +value[9]
    }
    case 12: {
      const sum11 = checkSumINN(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
      const sum12 = checkSumINN(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])

      return (sum11 === +value[10]) && (sum12 === +value[11])
    }
  }

  return false

  function checkSumINN (inn, coefficients) {
    let n = 0
    for (const i in coefficients) {
      n += coefficients[i] * inn[i]
    }

    const control = n % 11
    return control > 9 ? control % 10 : control
  }
}

export const validatorEarlier = (date, min = 0) => {
  const now = new Date()
  const currentDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes() + min, now.getUTCSeconds())).toISOString()
  return currentDate <= date
}

export const validatorLater = (date, startedDate, min) => {
  const now = new Date()
  const currentDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes() - min, now.getUTCSeconds())).toISOString()
  return currentDate <= date
}

// TODO DELETE
// export const validatorStepPrice = (val, formData) => {
//   let maxStep = 0
//   if (formData.is_procent) {
//     maxStep = Math.floor(formData.start_price * val / 100)
//   } else {
//     maxStep = Math.floor(formData.start_price - val)
//   }
//
//   return maxStep > 0
// }

export const checkNumberInn = helpers.withParams(
  { type: 'inn' },
  (inn) => validateINN(inn)
)

export const checkNumberOgrn = helpers.withParams(
  { type: 'ogrn' },
  (ogrn) => validateOgrn(ogrn) === true
)

export const toAccept = helpers.withParams(
  { type: 'toAccept' },
  (value) => value === true
)

export const notEarlier = helpers.withParams(
  { type: 'earlier' },
  (date) => validatorEarlier(date) === true
)

export const notLater = helpers.withParams(
  { type: 'later' },
  (date) => validatorLater(date) === true
)

export const checkRequired = val => !!val
export const checkNotLessThenZero = val => +val >= 0
export const checkMoreThenZero = val => +val > 0

export const CUSTOM_HELPERS = {
  withMessageScroll: function (message, params, validator) {
    return helpers.withMessage(message, this.withScroll(params, validator))
  },
  // Для страниц, на которых поля с одинаковым названием.
  // block - имя блока в котором находится поле,
  // field - name поля,
  // id - id сущности,
  // cb - коллбэк для кастомного скролла.
  // Пример:
  // id="block--id" - блок
  // id="block__field--id" - поле
  withScroll: ({ block, field, id, cb = null }, validator) => {
    return helpers.withParams({ type: 'scroll', block, field, id, cb }, validator)
  },
}
