import { findById } from '@/utils/array'

export default {
  namespaced: true,
  state () {
    return {
      item: {},
      positions: [],
      story: [],
      lastPrice: [],
      pattern: [],
      authLastPrice: [],
      reRenderLot: false,
      currentCurrency: {}
    }
  },
  getters: {
    getLot (state) {
      return state.item
    },
    getPattern (state) {
      return state.pattern
    },
    getCurrentPattern: state => id => {
      if (!state.pattern?.length) return null

      return findById(id, state.pattern)
    },
    getPositions (state) {
      return state.positions
    },
    getStory (state) {
      return state.story
    },
    getLastPrice (state) {
      return state.lastPrice
    },
    getAuthLastPrice (state) {
      return state.authLastPrice
    },
    getReRenderLot: state => state.reRenderLot,
    getCurrentCurrencyName: state => state.currentCurrency?.name
  },
  mutations: {
    setLot (state, item) {
      state.item = item
    },
    setPattern (state, items) {
      state.pattern = items
    },
    setPositions (state, items) {
      state.positions = items
    },
    setStory (state, story) {
      state.story = story
    },
    setLastPrice (state, price) {
      state.lastPrice = price
    },
    setAuthLastPrice (state, price) {
      state.authLastPrice = price
    },
    setCurrentCurrency (state, currency) {
      state.currentCurrency = currency
    }
  },
  actions: {
    callReRenderLot ({ state }) {
      state.reRenderLot = false
      setTimeout(() => {
        state.reRenderLot = true
      }, 0)
    }
  }
}
