<template>
  <!-- default width="8" height="14" -->
  <path d="M6.9873 13L2.00029 6.81818L6.98731 1" stroke="currentColor" stroke-width="2"/>
</template>

<script>
export default {
  name: 'IconArrowBack'
}
</script>
