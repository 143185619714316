import securityMeta from './securityMeta'
import users from './users/users'
import trade from '@/router/security/trade/trade'
import accreditation from '@/router/security/accreditation/accreditation'
import { checkRole } from '@/utils/utils'

export default [
  {
    path: '/security',
    name: 'security',
    redirect: { name: 'security-users-all' },
    meta: {
      ...securityMeta
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/security/users',
    redirect: { name: 'security-users-all' },
    name: 'security-users',
    component: () => import('@/views/Security/Sidebar/SecuritySidebarUsersView'),
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    children: [
      ...users
    ],
    props: {
      title: 'Пользователи'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/security/trade',
    redirect: { name: 'security-trade-active' },
    name: 'security-trade',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Sidebar/SecuritySidebarTradeView'),
    children: [
      ...trade
    ],
    props: {
      title: 'Торговые процедуры'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/security/accreditation',
    redirect: { name: 'security-accreditation-active' },
    name: 'security-accreditation',
    meta: {
      ...securityMeta,
      layout: 'main-sidebar-layout'
    },
    component: () => import('@/views/Security/Sidebar/SecuritySidebarAccreditationsView'),
    children: [
      ...accreditation
    ],
    props: {
      title: 'Заявки на аккредитацию'
    },
    async beforeEnter (to, from, next) {
      try {
        const candidate = await checkRole('security')
        if (candidate) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (e) {
        next({ name: 'login' })
      }
    }
  }
]
