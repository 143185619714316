import {
  CATEGORY_LOT,
  COMPANY,
  CONDITIONS_DELIVERY, SPECIAL_CONDITIONS_DELIVERY, CRITERIAL_EXECUTION, TERM_CONTRACT, CONDITIONS_PAYMENT, CURRENCY, MEASUREMENT,
  ORGANIZATIONS, PARAMETER_CATEGORY, PARAMETER_DEPARTMENT, PARAMETER_TYPE,
  PARTNERS,
  PROCEDURE_TYPE, RELATION
} from '@/utils/manual/manualList'

export default {
  namespaced: true,
  state () {
    return {
      [PARTNERS]: [],
      [ORGANIZATIONS]: [],
      [COMPANY]: [],
      [PROCEDURE_TYPE]: [],
      [CATEGORY_LOT]: [],
      [CONDITIONS_DELIVERY]: [],
      [SPECIAL_CONDITIONS_DELIVERY]: [],
      [CRITERIAL_EXECUTION]: [],
      [TERM_CONTRACT]: [],
      [CONDITIONS_PAYMENT]: [],
      [CURRENCY]: [],
      [MEASUREMENT]: [],
      [PARAMETER_TYPE]: [],
      [PARAMETER_DEPARTMENT]: [],
      [PARAMETER_CATEGORY]: [],
      [RELATION]: []
    }
  },
  getters: {
    getManualByKey: (state) => (key) => {
      return state[key]
    }
  },
  mutations: {
    setManual (state, { key, items }) {
      state[key] = items
    }
  }
}
