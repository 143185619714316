<template>
  <VModal @close="closeModal">
    <div class="column gap-30">
      <h4 class="modal__title">Внимание!</h4>

      <div class="column gap-20">
        <h4 class="modal__subtitle">Необходима аккредитация</h4>

        <p class="max-w-550 font--500">
          Для того, чтобы получить аккредитацию, прикрепите документы в личном профиле, сохраните изменения, после - нажмите на кнопку "Получить аккредитацию"
        </p>
      </div>

      <LinkBidderInstruction class="hover--light-gray" />

      <router-link
        class="btn btn-primary btn-bg-green btn--p self-center"
        style="width: initial"
        to="bidder-profile"
        @click="goToProfile"
      >
        Перейти в личный профиль
      </router-link>
    </div>
  </VModal>
</template>

<script setup>
import VModal from '@/components/ui/modal/VModal'
import LinkBidderInstruction from '@/components/ui/link/LinkBidderInstruction'
import store from '@/store'
import { goToPage } from '@/utils/goRoute'

const closeModal = () => store.commit('modal/CLOSE_MODAL')
function goToProfile () {
  closeModal()
  goToPage('bidder-profile')
}
</script>
