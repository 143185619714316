import store from '@/store'

export function filterMiddleware(to, from) {
  const toItem = to.path.split('/').slice(1, 3).join('')
  const fromItem = from.path.split('/').slice(1, 3).join('')
  if (toItem !== fromItem) {
    store.commit('filter/clearFilter')
    store.commit('filter/clearSearch')
  }
}
