import adminMeta from '@/router/admin/adminMeta'

export default [
  {
    path: '/admin/profile/info/:id',
    name: 'admin-profile-info',
    meta: adminMeta,
    component: () => import('@/views/Admin/Profile/AdminProfileInfoView')
  },
  {
    path: '/admin/profile/documents/:id',
    name: 'admin-profile-documents',
    meta: adminMeta,
    component: () => import('@/views/Admin/Profile/AdminProfileDocumentsView')
  },
  {
    path: '/admin/profile/changelog/:id',
    name: 'admin-profile-changelog',
    meta: adminMeta,
    component: () => import('@/views/Admin/Profile/AdminProfileChangelogView')
  },
  {
    path: '/admin/profile/edit/:id',
    name: 'admin-profile-edit',
    meta: adminMeta,
    component: () => import('@/views/Admin/Profile/AdminProfileEditView')
  }
]
