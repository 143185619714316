<template>
  <!-- default width="122" height="34" -->
  <path d="M9.88949 0.000488281C15.1313 0.000488281 19.6829 2.31165 19.6829 7.25512H12.5294C12.2007 6.26896 11.1133 5.77335 9.88949 5.77335C8.04611 5.77335 6.9234 6.9264 6.9234 8.57254C6.9234 10.2263 8.04611 11.3793 9.88949 11.3793C11.1133 11.3793 12.2007 10.8837 12.5294 9.8925H19.6829C19.6829 14.841 15.1313 17.1472 9.88949 17.1472C4.12167 17.1472 0 14.4112 0 8.57254C0 2.73899 4.12167 0.000488281 9.88949 0.000488281Z" fill="currentColor"/>
  <path d="M29.5712 11.3776L35.8347 8.27747V0.33252H42.4293V16.8167H35.8347V13.8481H35.5059L29.5712 16.8167H22.9766V0.33252H29.5712V11.3776Z" fill="currentColor"/>
  <path d="M68.4684 5.7716L64.1824 8.86918V16.8167H57.5903V5.7716L53.3042 8.86918V16.8167H46.707V0.33252H53.3042V3.29861H53.633L57.5903 0.33252H64.1824V3.29861H64.5111L68.4684 0.33252H75.0631V16.8167H68.4684V5.7716Z" fill="currentColor"/>
  <path d="M91.5405 11.3776H85.9345V16.8167H79.3398V0.33252H85.9345V5.7716H91.5405V0.33252H98.1326V16.8167H91.5405V11.3776Z" fill="currentColor"/>
  <path d="M111.477 0.000488281C117.25 0.000488281 121.369 2.73899 121.369 8.57254C121.369 14.4112 117.25 17.1472 111.477 17.1472C105.709 17.1472 101.59 14.4112 101.59 8.57254C101.59 2.73899 105.709 0.000488281 111.477 0.000488281ZM111.477 5.47499C109.336 5.47499 108.281 6.76205 108.281 8.57254C108.281 10.3881 109.336 11.6727 111.477 11.6727C113.622 11.6727 114.676 10.3881 114.676 8.57254C114.676 6.76205 113.622 5.47499 111.477 5.47499Z" fill="currentColor"/>
  <path d="M1.58984 29.7276C1.58984 31.4774 2.87439 32.7772 4.51547 32.7772C5.72163 32.7772 6.40436 32.2208 6.83676 31.6924C7.08456 31.3839 7.26915 31.0122 7.37789 30.6101H6.44987C6.34114 30.8579 6.21724 31.0602 6.04782 31.2448C5.7545 31.5862 5.27406 31.8947 4.51547 31.8947C3.44839 31.8947 2.53555 31.0273 2.53555 29.7276C2.53555 28.4582 3.38517 27.5783 4.51547 27.5783C5.25889 27.5783 5.72163 27.8564 5.99978 28.1497C6.15656 28.3369 6.29563 28.5215 6.38666 28.7389H7.3172C7.20846 28.3672 7.03652 28.0258 6.79124 27.7173C6.38666 27.2217 5.67611 26.6958 4.51547 26.6958C2.87439 26.6958 1.58984 27.9803 1.58984 29.7276Z" fill="currentColor"/>
  <path d="M8.54299 32.654H9.5949L12.7355 28.1505H12.7531V32.654H13.6811V26.8052H12.6292L9.48608 31.3062H9.45575L9.47098 31.1999V26.8052H8.54297L8.54299 32.654Z" fill="currentColor"/>
  <path d="M14.6172 32.6539H15.6084L16.9536 27.6851H18.0056L19.3356 32.6539H20.342L18.7009 26.8052H16.2406L14.6172 32.6539Z" fill="currentColor"/>
  <path d="M20.9102 29.7277C20.9102 31.4775 22.1795 32.7772 23.7119 32.7772C24.8422 32.7772 25.492 32.2032 25.707 31.8796L25.8157 31.7253V32.6533H26.7614V26.8045H25.8157V27.7629L25.707 27.5783C25.4769 27.2824 24.8422 26.6958 23.7119 26.6958C22.1795 26.6958 20.9102 27.9803 20.9102 29.7276V29.7277ZM21.8559 29.7277C21.8559 28.4583 22.7055 27.5784 23.8358 27.5784C24.9509 27.5784 25.8157 28.4583 25.8157 29.7277C25.8157 31.0123 24.9509 31.8947 23.8358 31.8947C22.7055 31.8947 21.8559 31.0123 21.8559 29.7277Z" fill="currentColor"/>
  <path d="M31.6602 32.6539H32.5882V30.0241H33.764L35.7439 32.6539H36.9197L34.5833 29.5589L36.7351 26.8052H35.6377L33.764 29.1416H32.5882V26.8052H31.6602V32.6539Z" fill="currentColor"/>
  <path d="M37.2031 29.7277C37.2031 31.4775 38.4725 32.7772 40.0049 32.7772C41.1352 32.7772 41.785 32.2032 42.0025 31.8796L42.1087 31.7253V32.6533H43.0544V26.8045H42.1087V27.7629L42.0025 27.5783C41.7698 27.2824 41.1352 26.6958 40.0049 26.6958C38.4725 26.6958 37.2031 27.9803 37.2031 29.7276V29.7277ZM38.1488 29.7277C38.1488 28.4583 38.9985 27.5784 40.1288 27.5784C41.2439 27.5784 42.1087 28.4583 42.1087 29.7277C42.1087 31.0123 41.2439 31.8947 40.1288 31.8947C38.9985 31.8947 38.1488 31.0123 38.1488 29.7277Z" fill="currentColor"/>
  <path d="M43.9219 32.6539H45.0825L46.9537 30.0241H47.9449V32.6539H48.8881V30.0241H49.8793L51.7531 32.6539H52.9112L50.6986 29.5589L52.7417 26.8052H51.6291L49.8793 29.1416H48.8881V26.8052H47.9449V29.1416H46.9537L45.2064 26.8052H44.0913L46.1344 29.5589L43.9219 32.6539Z" fill="currentColor"/>
  <path d="M53.0312 33.9991H53.9593V32.6539H58.5234V33.9991H59.4515V31.7714H58.8168L57.4715 26.8052H55.0112L53.6659 31.7714H53.0312V33.9991ZM54.6091 31.7714L55.7065 27.6851H56.761L57.8736 31.7714H54.6091Z" fill="currentColor"/>
  <path d="M59.9727 29.7276C59.9727 31.4774 61.2572 32.7772 63.0197 32.7772C64.7695 32.7772 66.054 31.4774 66.054 29.7276C66.054 27.9803 64.7695 26.6958 63.0197 26.6958C61.2572 26.6958 59.9727 27.9803 59.9727 29.7276ZM60.9158 29.7276C60.9158 28.4582 61.7983 27.5783 63.0197 27.5783C64.2284 27.5783 65.1083 28.4582 65.1083 29.7276C65.1083 31.0122 64.2284 31.8947 63.0197 31.8947C61.7983 31.8947 60.9158 31.0122 60.9158 29.7276Z" fill="currentColor"/>
  <path d="M67.332 32.6539H68.26V27.6851H71.3551V26.8052H67.332V32.6539Z" fill="currentColor"/>
  <path d="M71.9375 29.7276C71.9375 31.4774 73.222 32.7772 74.9845 32.7772C76.7343 32.7772 78.0189 31.4774 78.0189 29.7276C78.0189 27.9803 76.7343 26.6958 74.9845 26.6958C73.222 26.6958 71.9375 27.9803 71.9375 29.7276ZM72.8807 29.7276C72.8807 28.4582 73.7632 27.5783 74.9845 27.5783C76.1932 27.5783 77.0732 28.4582 77.0732 29.7276C77.0732 31.0122 76.1932 31.8947 74.9845 31.8947C73.7632 31.8947 72.8807 31.0122 72.8807 29.7276Z" fill="currentColor"/>
  <path d="M79.5947 28.6314L79.7642 30.2547H80.4621L80.6467 28.6314V24.4692H79.5947V28.6314ZM79.4102 32.1285C79.4102 32.5305 79.7035 32.8238 80.1207 32.8238C80.5228 32.8238 80.8186 32.5305 80.8186 32.1285C80.8186 31.7264 80.5228 31.4306 80.1207 31.4306C79.7035 31.4306 79.4102 31.7264 79.4102 32.1285Z" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconLogo'
}
</script>

<style scoped>

</style>
