import organizerMeta from '@/router/organizer/organizerMeta'

export default [
  {
    path: '/organizer/profile/info',
    name: 'organizer-profile-info',
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Profile/OrganizerProfileInfoView')
  },
  {
    path: '/organizer/profile/documents',
    name: 'organizer-profile-documents',
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Profile/OrganizerProfileDocumentsView')
  },
  {
    path: '/organizer/profile/changelog',
    name: 'organizer-profile-changelog',
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Profile/OrganizerProfileChangelogView')
  },
  {
    path: '/organizer/profile/edit/:id',
    name: 'organizer-profile-edit',
    meta: organizerMeta,
    component: () => import('@/views/Organizer/Profile/OrganizerProfileEditView')
  }
]
