<template>
  <!-- default width="38" height="38" -->
  <path d="M20.5667 12.9245L25.3501 18.0423M20.5667 12.9245L23.3001 10L28.0834 15.1179L25.3501 18.0423L20.5667 12.9245ZM20.5667 12.9245L11.283 22.8574C11.1018 23.0513 11 23.3143 11 23.5885V28.2781H15.3831C15.6394 28.2781 15.8852 28.1692 16.0664 27.9752L25.3501 18.0423L20.5667 12.9245Z" stroke="currentColor" stroke-width="1.70833" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script>
export default {
  name: 'IconAccordionEdit'
}
</script>
