import { helpers } from '@vuelidate/validators'
import {
  regexIncludeCapitalizeLetter,
  regexIncludeNumbers,
  regexIncludeOnlyLatins,
  regexIncludeSpecialSymbol
} from '@/utils/validator/regex'
import ErrorValidationMessage from '@/utils/validator/errorValidationMessage'

export const requiredValid = {
  required: ErrorValidationMessage.RequiredError()
}

export const nameValid = {
  required: ErrorValidationMessage.RequiredError(),
  minLength: ErrorValidationMessage.MinLengthError(2, 'имени')
}

export const loginValid = {
  required: ErrorValidationMessage.RequiredError(),
  minLength: ErrorValidationMessage.MinLengthError(3, 'логина')
}

export const emailValid = {
  required: ErrorValidationMessage.RequiredError(),
  email: ErrorValidationMessage.EmailError()
}

export const toAcceptCheckbox = {
  checked: helpers.withMessage('Обязательное поле', value => value === true)
}

export const passwordValid = {
  required: ErrorValidationMessage.RequiredError(),
  minLength: ErrorValidationMessage.MinLengthError(8, 'пароля'),
  regexIncludeNumbers: helpers.withMessage('Поле должно содержать минимум 1 цифру', regexIncludeNumbers),
  regexIncludeOnlyLatins: helpers.withMessage('Поле может содержать буквы только латинского алфавита, а так же минимум одну букву нижнего регистра', regexIncludeOnlyLatins),
  regexIncludeCapitalizeLetter: helpers.withMessage('Поле должно содержать минимум 1 заглавную букву', regexIncludeCapitalizeLetter),
  regexIncludeSpecialSymbol: helpers.withMessage('Поле должно содержать минимум 1 специальный символ', regexIncludeSpecialSymbol)
}

export const okvedValid = {
  required: ErrorValidationMessage.RequiredError(),
  minLength: ErrorValidationMessage.MinLengthError(2, 'ОКВЭД'),
  maxLength: ErrorValidationMessage.MaxLengthError(8, 'ОКВЭД')
}

export const ogrnValid = {
  minLength: ErrorValidationMessage.MinLengthError(13, 'ОГРН'),
  maxLength: ErrorValidationMessage.MaxLengthError(15, 'ОГРН'),
  ogrn: ErrorValidationMessage.OgrnError()
}

export const innValid = {
  required: ErrorValidationMessage.RequiredError(),
  minLength: ErrorValidationMessage.MinLengthError(10, 'ИНН'),
  maxLength: ErrorValidationMessage.MaxLengthError(15, 'ИНН'),
  inn: ErrorValidationMessage.InnError()
}

export const kppValid = {
  minLength: ErrorValidationMessage.MinLengthError(9, 'КПП'),
  maxLength: ErrorValidationMessage.MaxLengthError(9, 'КПП')
}

export const earlier = {
  earlier: ErrorValidationMessage.EarlierError()
}
