export const fieldProps = {
  emits: ['update:modelValue', 'input', 'blur'],
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    classInput: {
      type: [Array, String],
      default: 'input'
    },
    classLabel: {
      type: [Array, String],
      default: 'label'
    },
    classForm: {
      type: [Array, String]
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: [Object]
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  }
}

export const decoratorProps = {
  emits: ['decorator'],
  props: {
    row: {
      type: Object,
      required: true
    },
    headCell: {
      type: Object,
      required: true
    }
  }
}

export const itemProps = {
  props: {
    urlSetRows: {
      type: String,
      required: false
    },
    requestIsAuth: {
      type: Boolean,
      required: false,
      default: false
    },
    urlActionQuery: {
      type: String,
      required: false
    }
  }
}

export const lotProps = {
  props: {
    counterLot: {
      type: Number,
      required: true
    },
    datePublication: {
      type: String,
      required: false,
      default: 'Не указано'
    },
    dateBeforeStart: {
      type: String,
      required: true
    },
    dateStart: {
      type: String,
      required: true
    },
    dateEnd: {
      type: String,
      required: true
    },
    interactionButton: {
      type: String,
      required: true,
      default: ''
    },
    isChosenIcon: {
      type: Boolean,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    }
  }
}

export const lotInfoProps = {
  props: {
    lot: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: false
    },
    validateField: {
      type: Function,
      required: false
    },
    v$: {
      type: Object,
      required: false
    },
    serverErrors: {
      type: Object,
      required: false
    },
    isBidder: {
      type: Boolean,
      required: false
    },
    isOrganizer: {
      type: Boolean,
      required: false
    },
    isBidderActive: {
      type: Boolean,
      required: false
    },
    isEditDelivery: {
      type: Boolean,
      required: false,
    },
    isEditPayment: {
      type: Boolean,
      required: false
    }
  }
}
