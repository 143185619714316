<template>
  <!-- default width="27" height="27" -->
  <rect x="0.5" y="10.1504" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="0.5" y="0.5" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="10.1523" y="10.1504" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="10.1523" y="0.5" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="19.8008" y="10.1504" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="19.8008" y="0.5" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="0.5" y="19.8008" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="10.1523" y="19.8008" width="6.23779" height="6.23779" stroke="currentColor"/>
  <rect x="19.8008" y="19.8008" width="6.23779" height="6.23779" stroke="currentColor"/>
</template>

<script>
export default {
  name: 'IconToggleDisplayBlocks'
}
</script>
