<template>
  <div :class="['alert', getAlert?.type]" @click="closeAlert">
    <div class="alert__icon">
      <img :src="require(`@/assets/icon/alert/${getIconAlert}`)" :alt="getTitleAlert"/>
    </div>
    <div class="alert__title">{{ getTitleAlert }}</div>
    <div class="alert__description">{{ getAlert?.description }}</div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()

    const getAlert = computed(() => {
      return store.getters.getAlert
    })

    const getIconAlert = computed(() => {
      if (getAlert.value?.type === 'error') {
        return 'error.svg'
      } else if (getAlert.value?.type === 'warn') {
        return 'warn.svg'
      } else if (getAlert.value?.type === 'success') {
        return 'accept.svg'
      } else {
        return 'info.svg'
      }
    })

    const getTitleAlert = computed(() => {
      if (getAlert.value?.type === 'error') {
        return 'Сообщение об ошибке'
      } else if (getAlert.value?.type === 'warn') {
        return 'Внимание'
      } else if (getAlert.value?.type === 'info') {
        return 'Информация'
      } else {
        return 'Одобрение'
      }
    })

    const closeAlert = () => {
      store.commit('clearAlert')
    }

    return {
      getAlert,
      getIconAlert,
      getTitleAlert,
      closeAlert
    }
  }
}
</script>
