import {
  authRequest,
  hostRequest,
  axiosCreateDefault,
  hostInterceptorResponseReject,
  hostInterceptorResponseSuccess,
  authHostInterceptorResponseSuccess,
  authHostInterceptorResponseReject
} from '@/http/index.function'

const $host = axiosCreateDefault()
const $authHost = axiosCreateDefault()

$host.interceptors.request.use(hostRequest)
$authHost.interceptors.request.use(authRequest)

$host.interceptors.response.use(hostInterceptorResponseSuccess, hostInterceptorResponseReject)
$authHost.interceptors.response.use(authHostInterceptorResponseSuccess, authHostInterceptorResponseReject)

export {
  $host,
  $authHost
}
