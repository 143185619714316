import {
  changeStatus,
  getBidderCardList,
  getLatestPrice, getOffer,
  getOfferForEditView,
  participateBidder, sendDocumentBidder, downloadExcel
} from '@/http/offer/offerApi'
import { createFormDataNewFiles } from '@/utils/files'

export class BiddingProcedureService {
  async getLastPrice (lotId) {
    const { data } = await getLatestPrice(lotId)
    return data
  }

  async getBidderCardList (lotId) {
    const { data } = await getBidderCardList(lotId)
    return data
  }

  async participateBidder (formData, list) {
    const data = {
      lot: formData,
      offers: list
    }
    return await participateBidder(data)
  }

  async sendBidderDocuments (lotId, files, sort) {
    const data = createFormDataNewFiles(files, { sort })
    if (!data) return

    return await sendDocumentBidder(lotId, data)
  }

  async getOfferForEditView (lotId, userId, sort, isFake) {
    return await getOfferForEditView(lotId, userId, sort, isFake)
  }

  async sendResultStatus (lotId, status) {
    return await changeStatus(lotId, status)
  }

  async getCardList (lotId) {
    const { data } = await getOffer(lotId)
    return data || []
  }

  async downloadExcelResultOrganizer(lotId) {
    const { data } = await downloadExcel(lotId)
    window.open(data.data)
  }
}
