import useVuelidate from '@vuelidate/core'
import { ScrollToError } from '@/modules/scrollToError/scrollToError'
import { scrollToNodeById } from '@/utils/scroll'

export default class Validator {
  constructor (rules, formData) {
    this.v$ = useVuelidate(rules, formData)
  }

  validateField = (nameField) => {
    if (this.v$.value[nameField]) {
      this.v$.value[nameField].$touch()
      if (this.v$.value[nameField].$error) {
        this.v$.value[nameField].$validate()
        this.v$.value[nameField].$touch()
      }
    }
  }

  validateForm = () => {
    this.v$.value.$validate()
    if (this.v$.value.$error) {
      const error = this.v$.value.$errors[0]

      if (error.$params?.type === 'scroll') {
        const params = error.$params
        if (params.cb) {
          params.cb(params)
        } else {
          const id = `${params.block}__${params.field}--${params.id}`
          scrollToNodeById(id)
        }
      } else {
        scrollToItem(this.v$.value.$errors[0].$property)
      }
      return this.v$.value.$touch()
    }
    return true
  }

  resetValidateField = (nameField) => {
    this.v$.value[nameField].$reset()
  }
}

function scrollToItem(val) {
  const item = document.querySelector(`#${val}`)
  if (!item) return
  ScrollToError.toErrorItem(item)
}
