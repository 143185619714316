const formatterRUB = new Intl.NumberFormat('ru-Ru', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 2
})

const formatterCurrency = new Intl.NumberFormat('ru-Ru', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

function formatRUB (num) {
  return formatterRUB.format(num)
}

function formatCurrency (num) {
  const format = formatterCurrency.format(num)

  let length = 8
  const isMinus = format[0] === '-'
  if (isMinus) length++

  if (format.length === length) {
    return format.replace(/\s/, '')
  }

  return format
}

function formatPercent (num) {
  return `${num}%`
}

export {
  formatRUB,
  formatCurrency,
  formatPercent
}
