import { onBeforeUnmount, onMounted } from 'vue'

export function useCloseOutsideModal (fn) {
  onMounted(() => {
    document.addEventListener('click', fn)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', fn)
  })
}
