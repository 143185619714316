<template>
  <main class="main">
    <TheHeader v-if="$route.name !== 'spinner'" />
    <router-view />
  </main>
</template>

<script>
import TheHeader from '@/components/header/TheHeader'

export default {
  components: {
    TheHeader
  }
}
</script>
