<template>
  <main class="main">
    <TheHeader />
    <div class="main-container">
      <div class="block">
        <div class="block__img block__img-lot">
          <div class="container">
            <div class="block__info">
              <div class="block__title">{{ title }}</div>
            </div>
          </div>
        </div>

        <div
          class="block"
          :class="{'bg--white' : bgIsWhite}"
        >
          <div class="block__inner">
            <div class="container container--lot">
              <VBreadcrumbsList />
              <VSidebar :options="sidebar"  is-vertical/>

              <div class="lot-layout mt-40 mb-120">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter />
  </main>
</template>

<script>
import TheHeader from '@/components/header/TheHeader'
import TheFooter from '@/components/TheFooter'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { checkCurrentStatus, checkBidderCount } from '@/utils/lot/function.lot'
import VBreadcrumbsList from '@/modules/breadcrumbs/component/VBreadcrumbsList'
import VSidebar from '@/components/views/Sidebar/VSidebar'
import { getSlugs } from '@/utils/utils'
import { slugsMainSidebarLayout } from '@/utils/slugs'

export default {
  setup () {
    const router = useRouter()
    const store = useStore()

    const route = computed(() => {
      return router.currentRoute.value
    })

    const bgIsWhite = computed(() => {
      return route.value.name !== 'bidder-lot-create'
    })

    const showSidebar = computed(() => {
      return route.value.name === 'bidder-lot-info' ||
        route.value.name === 'organizer-lot-info'
    })

    const getLot = computed(() => {
      return store.getters['lot/getLot']
    })

    const isArchiveLot = computed(() => {
      const status = getLot.value?.status?.name
      const map = ['Отменён', 'Торги завершены', 'Торги не состоялись']
      return !!map.includes(status)
    })

    const goAuction = () => {
      if (getLot.value.procedure_type_id === 2) {
        router.push({ name: 'bidding-procedure', params: { id: getLot.value.id } })
      } else {
        router.push({ name: 'bidder-lot-create', params: { id: getLot.value.id } })
      }
    }

    return {
      ...getSlugs(slugsMainSidebarLayout, route.value.meta.slug),
      checkCurrentStatus,
      getLot,
      goAuction,
      bgIsWhite,
      showSidebar,
      isArchiveLot,
      checkBidderCount,
      store
    }
  },
  components: {
    VSidebar,
    VBreadcrumbsList,
    TheHeader,
    TheFooter
  }
}
</script>
