<template>
  <div
    :class="['nav__personal',
    isOpenInfo ? 'show-window' : '']"
    @click="handlerPersonalModal"
    data-person="person"
  >
    <div class="nav__icon" >
      <img v-if="getIcons" class="avatarFile" :src="getIcons" alt="Аватарка">
      <VIcon v-else icon-name="User" />
    </div>

    <p class="nav__personal-name">

      <span>
        {{ $store.getters['auth/getUser'].name || 'Войти' }}
      </span>

      <span v-if="isAuth"  class="nav__personal-arrow">
        <VIcon width="11" height="7" icon-name="Arrow" />
      </span>
    </p>

    <TheModalPersonal v-if="isOpenInfo" @close="handlerPersonalModal" @open="handlerErrorModal"/>

    <teleport to="body">
      <TheModalError
        v-if="isOpenErrorModal"
        :is-open="isOpenErrorModal"
        @close="handlerErrorModal"
      />
    </teleport>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, ref, computed } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import TheModalPersonal from '@/components/ui/modal/header/TheModalPersonal'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import TheModalError from '@/components/ui/modal/header/TheModalError'

const props = defineProps({
  isAuth: {
    type: Boolean,
    required: true
  }
})

const store = useStore()
const router = useRouter()
const isOpenInfo = ref(false)

const getIcons = computed(() => {
  if (store.getters['auth/getUser']) { return store.getters['auth/getUser'].avatar?.[0][0].url }
  return false
})

const isOpenErrorModal = ref(false)
const handlerErrorModal = () => {
  isOpenErrorModal.value = !isOpenErrorModal.value
}

const handlerPersonalModal = () => {
  if (!props.isAuth) {
    router.push({ name: 'login' })
  } else {
    isOpenInfo.value = !isOpenInfo.value
  }
}

defineComponent({
  TheModalPersonal,
  VIcon
})
</script>

<style scoped>
.avatarFile {
  display: block;
  width: 45px;
  height: 45px;
}
</style>
