<template>
  <div class="modal" @click="closeModalOutSide">
    <div :class="['modal__inner', maxWidthClass && !confirm, modalInnerClass, scrollY ? 'scroll-y' : '']">

      <div
        :class="['modal__close', modalInnerClass === 'cartLot' ? 'bg-yellow' : '']"
        @click="toggleState(false)"
      >
        <VIcon icon-name="Close" />
      </div>

      <TheModalConfirm
        v-if="confirm"
        :confirm-question="confirmQuestion"
        :confirm-btn-accept="confirmBtnAccept"
        :confirm-btn-decline="confirmBtnDecline"
        :maxWidthClass="maxWidthClass"
        @accept="toggleState"
        @decline="toggleState"
      >
        <slot name="confirm" />
      </TheModalConfirm>

      <slot v-else />

    </div>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, defineEmits, ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import TheModalConfirm from '@/components/ui/modal/modules/TheModalConfirm'

const emits = defineEmits(['close'])

defineProps({
  confirm: {
    type: Boolean,
    required: false,
    default: false
  },
  confirmQuestion: {
    type: String,
    required: false
  },
  confirmBtnAccept: {
    type: String,
    required: false,
    default: 'Да'
  },
  confirmBtnDecline: {
    type: String,
    required: false,
    default: 'Нет'
  },
  maxWidthClass: {
    type: String,
    required: false
  },
  modalInnerClass: {
    type: [String, Array],
    required: false
  },
  scrollY: {
    type: Boolean,
    required: false
  }
})

const result = ref(false)

const closeModal = () => {
  emits('close', result.value)
}

const closeModalOutSide = (e) => {
  if (!e.target.classList.contains('modal__inner')
    && !e.target.closest('.modal__inner')
    && !e.target.closest('.no-close')
    && !e.target.classList.contains('.no-close')
  ) {
    closeModal()
  }
}

const toggleState = (boolean) => {
  result.value = boolean
  closeModal()
}

defineComponent({
  TheModalConfirm,
  VIcon
})
</script>
