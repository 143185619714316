import { login, logout } from '@/http/auth/authApi'
import { refreshArr } from '@/utils/reactive'
import { getAllMyCategory } from '@/http/user/userCategoryApi'
import { Alert } from '@/extension/Alert/Alert'
import { spliceBy } from '@/utils/utils'

export default {
  namespaced: true,
  state () {
    return {
      isAuth: false,
      user: {}
    }
  },
  getters: {
    getIsAuth (state) {
      return state.isAuth
    },
    getUser (state) {
      return state.user
    },
    getRole (state) {
      return state.user.roles?.[0].name
    },
    currentUserId: state => state.user?.id,
    isAdmin: state => state.user?.roles?.[0].id === 1,
    isUser: state => state.user?.roles?.[0].id === 2,
    isBidder: state => state.user?.roles?.[0].id === 3,
    isSecurity: state => state.user?.roles?.[0].id === 4,
    isOrganizer: state => state.user?.roles?.[0].id === 5
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setIsAuth (state, boolean) {
      state.isAuth = boolean
    },
    setToken (state, token) {
      localStorage.setItem('accessToken', token)
    },
    logout (state) {
      state.isAuth = false
      state.user = {}
      localStorage.removeItem('accessToken')
    },
    setUserCategory (state, categories) {
      refreshArr(state.user.category, categories)
    },
    deleteUserCategory (state, id) {
      spliceBy(id, state.user.category)
    }
  },
  actions: {
    async login ({ commit }, formData) {
      const { data } = await login(formData)
      commit('setUser', data.user)
      commit('setIsAuth', true)
      localStorage.setItem('centrifugeToken', data.centrifugo_token)
      if (data?.token) {
        commit('setToken', data.token)
      }
    },
    async logout ({ commit }) {
      try {
        await logout()
        commit('logout')
      } catch (e) {
        commit('logout')
      }
    },

    async syncUserCategories ({ commit }) {
      try {
        const { data } = await getAllMyCategory()
        if (!data.length) return
        const categories = Object.values(data)[0]
        if (!categories) return
        commit('setUserCategory', categories)
      } catch (e) {
        await Alert.show('error', 'Не удалось получить обновленный список категорий')
      }
    }
  }
}
