<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <component :is="currentIconName"></component>
  </svg>
</template>

<script>
import { computed } from 'vue'

import IconError from '@/components/ui/icon/Icons/IconError'
import IconChecked from '@/components/ui/icon/Icons/IconChecked'
import IconExcel from '@/components/ui/icon/Icons/IconExcel'
import IconYellowDelete from '@/components/ui/icon/Icons/Base/IconYellowDelete'
import IconYellowEdit from '@/components/ui/icon/Icons/Base/IconYellowEdit'
import IconAccordionAdd from '@/components/ui/icon/Icons/Accordion/IconAccordionAdd'
import IconAccordionArrow from '@/components/ui/icon/Icons/Accordion/IconAccordionArrow'
import IconAccordionDelete from '@/components/ui/icon/Icons/Accordion/IconAccordionDelete'
import IconAccordionEdit from '@/components/ui/icon/Icons/Accordion/IconAccordionEdit'
import IconArrow from '@/components/ui/icon/Icons/Base/IconArrow'
import IconArrowBack from '@/components/ui/icon/Icons/Buttons/IconArrowBack'
import IconArrowNext from '@/components/ui/icon/Icons/Buttons/IconArrowNext'
import IconCalendarArrow from '@/components/ui/icon/Icons/Form/IconCalendarArrow'
import IconCamera from '@/components/ui/icon/Icons/Form/IconCamera'
import IconClose from '@/components/ui/icon/Icons/Buttons/IconClose'
import IconDeleteFile from '@/components/ui/icon/Icons/Form/IconDeleteFile'
import IconEdit from '@/components/ui/icon/Icons/Buttons/IconEdit'
import IconEye from '@/components/ui/icon/Icons/Form/IconEye'
import IconEyeHidden from '@/components/ui/icon/Icons/Form/IconEyeHidden'
import IconFavoritesLot from '@/components/ui/icon/Icons/Lot/IconFavoritesLot'
import IconFile from '@/components/ui/icon/Icons/Form/IconFile'
import IconFilter from '@/components/ui/icon/Icons/Buttons/IconFilter'
import IconLogo from '@/components/ui/icon/Icons/IconLogo'
import IconModalAlert from '@/components/ui/icon/Icons/Modal/IconModalAlert'
import IconModalSuccess from '@/components/ui/icon/Icons/Modal/IconModalSuccess'
import IconNotification from '@/components/ui/icon/Icons/IconNotification'
import IconPassword from '@/components/ui/icon/Icons/Buttons/IconPassword'
import IconPlus from '@/components/ui/icon/Icons/Buttons/IconPlus'
import IconSearch from '@/components/ui/icon/Icons/Form/IconSearch'
import IconSetting from '@/components/ui/icon/Icons/Buttons/IconSetting'
import IconToggleDisplayBlocks from '@/components/ui/icon/Icons/Buttons/IconToggleDisplayBlocks'
import IconToggleDisplayTable from '@/components/ui/icon/Icons/Buttons/IconToggleDisplayTable'
import IconTriangle from '@/components/ui/icon/Icons/IconTriangle'
import IconLoadFile from '@/components/ui/icon/Icons/Form/IconLoadFile'
import IconUser from '@/components/ui/icon/Icons/IconUser'
import IconUsersLot from '@/components/ui/icon/Icons/Lot/IconUsersLot'
import IconVK from '@/components/ui/icon/Icons/Socials/IconVK'
import IconRival from '@/components/ui/icon/Icons/Table/IconRival'
import IconMenuLot from '@/components/ui/icon/Icons/Lot/IconMenuLot'
import IconActivate from '@/components/ui/icon/Icons/Table/IconActivate'
import IconDeactivate from '@/components/ui/icon/Icons/Table/IconDeactivate'
import IconPardon from '@/components/ui/icon/Icons/Table/IconPardon'
import IconPublic from '@/components/ui/icon/Icons/Table/IconPublic'
import IconDelete from '@/components/ui/icon/Icons/Table/IconDelete'
import IconBlock from '@/components/ui/icon/Icons/Table/IconBlock'
import IconExtendLot from '@/components/ui/icon/Icons/Lot/IconExtendLot'
import IconCloseLot from '@/components/ui/icon/Icons/Table/IconClose'
import IconCancelLot from '@/components/ui/icon/Icons/Lot/IconCancelLot'
import IconHideLot from '@/components/ui/icon/Icons/Lot/IconHideLot'
import IconOpenLot from '@/components/ui/icon/Icons/Lot/IconOpenLot'
import IconSaveTemplate from '@/components/ui/icon/Icons/Lot/IconSaveTemplate'
import IconVectorLong from '@/components/ui/icon/Icons/IconVectorLong'
import IconCopy from '@/components/ui/icon/Icons/Buttons/IconCopy'
import IconTooltip from '@/components/ui/icon/Icons/IconTooltip'
import IconTableSmall from '@/components/ui/icon/Icons/Buttons/IconTableSmall'
import IconModalMenuLogout from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuLogout'
import IconModalMenuError from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuError'
import IconModalMenuPersonal from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuPersonal'
import IconModalMenuProfile from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuProfile'
import IconModalMenuSetting from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuSetting'
import IconModalMenuInstuction from '@/components/ui/icon/Icons/Modal/Menu/IconModalMenuInstuction'

export default {
  components: {
    IconModalMenuError,
    IconModalMenuLogout,
    IconModalMenuInstuction,
    IconModalMenuPersonal,
    IconModalMenuProfile,
    IconModalMenuSetting,
    IconTableSmall,
    IconTooltip,
    IconCopy,
    IconError,
    IconExcel,
    IconYellowDelete,
    IconYellowEdit,
    IconSaveTemplate,
    IconCancelLot,
    IconHideLot,
    IconOpenLot,
    IconExtendLot,
    IconCloseLot,
    IconBlock,
    IconDelete,
    IconPublic,
    IconPardon,
    IconDeactivate,
    IconActivate,
    IconAccordionAdd,
    IconAccordionArrow,
    IconAccordionDelete,
    IconAccordionEdit,
    IconArrow,
    IconArrowBack,
    IconArrowNext,
    IconCalendarArrow,
    IconCamera,
    IconClose,
    IconDeleteFile,
    IconEye,
    IconEyeHidden,
    IconEdit,
    IconFavoritesLot,
    IconFile,
    IconFilter,
    IconLoadFile,
    IconLogo,
    IconModalAlert,
    IconModalSuccess,
    IconNotification,
    IconPassword,
    IconPlus,
    IconSearch,
    IconSetting,
    IconToggleDisplayBlocks,
    IconToggleDisplayTable,
    IconTriangle,
    IconUser,
    IconUsersLot,
    IconVK,
    IconRival,
    IconMenuLot,
    IconChecked,
    IconVectorLong
  },
  props: {
    iconName: {
      type: String,
      required: true,
      validator (value) {
        return [
          'ModalMenuError',
          'ModalMenuLogout',
          'ModalMenuInstuction',
          'ModalMenuPersonal',
          'ModalMenuProfile',
          'ModalMenuSetting',
          'AccordionAdd',
          'AccordionArrow',
          'AccordionDelete',
          'AccordionEdit',
          'Arrow',
          'ArrowBack',
          'ArrowNext',
          'CalendarArrow',
          'Camera',
          'Copy',
          'Close',
          'DeleteFile',
          'Edit',
          'Eye',
          'Error',
          'Excel',
          'EyeHidden',
          'FavoritesLot',
          'File',
          'Filter',
          'LoadFile',
          'Logo',
          'ModalAlert',
          'ModalSuccess',
          'Notification',
          'Password',
          'Plus',
          'Search',
          'Setting',
          'TableSmall',
          'ToggleDisplayBlocks',
          'ToggleDisplayTable',
          'Tooltip',
          'Triangle',
          'User',
          'UsersLot',
          'VK',
          'Rival',
          'MenuLot',
          'Deactivate',
          'Activate',
          'Delete',
          'Block',
          'Pardon',
          'Public',
          'CloseLot',
          'ExtendLot',
          'CancelLot',
          'HideLot',
          'OpenLot',
          'SaveTemplate',
          'YellowEdit',
          'YellowDelete',
          'Checked',
          'VectorLong'
        ].includes(value)
      }
    },
    width: {
      type: [Number, String],
      required: false,
      default: 45
    },
    height: {
      type: [Number, String],
      required: false,
      default: 45
    },
    fill: {
      type: String,
      required: false,
      default: 'none'
    }
  },
  setup (props) {
    const viewBox = computed(() => `0 0 ${props.width} ${props.height}`)
    const currentIconName = computed(() => 'icon' + props.iconName)

    return {
      viewBox,
      currentIconName
    }
  }
}
</script>
