export const defaultNav = [
  {
    iconName: 'ModalMenuPersonal',
    title: 'Личный кабинет',
    action: 'personal'
  },
  {
    iconName: 'ModalMenuError',
    title: 'Сообщить об ошибке',
    action: 'error'
  }
]

export const adminsBtns = [
  {
    iconName: 'ModalMenuPersonal',
    title: 'Личный кабинет участника',
    route: {
      name: 'bidder'
    }
  },
  {
    iconName: 'ModalMenuPersonal',
    title: 'Личный кабинет оргнизатора',
    route: {
      name: 'organizer'
    }
  },
  {
    iconName: 'ModalMenuPersonal',
    title: 'Личный кабинет С/Б',
    route: {
      name: 'security'
    }
  },
]

export const outBtn = {
  iconName: 'ModalMenuLogout',
  title: 'Выход',
  action: 'logout'
}

const profileBtn = (route) => {
  return {
    title: 'Мой профиль',
    iconName: 'ModalMenuProfile',
    route
  }
}

const instructionBtn = {
    iconName: 'ModalMenuInstuction',
    title: 'Инструкция',
    action: 'instruction'
}

export const allUserNav = [...defaultNav, outBtn]
export const unAuthUser = [...allUserNav, instructionBtn]
export const adminsNav = [...adminsBtns, ...allUserNav]
export const organizerNav = [...defaultNav,
  profileBtn({
    name: 'organizer-profile'
  }),
  outBtn
]

export const bidderNav = [...defaultNav,
  profileBtn({
    name: 'bidder-profile'
  }),
  {
    title: 'Настройки',
    iconName: 'ModalMenuSetting',
    route: {
      name: 'bidder-setting'
    }
  },
  instructionBtn,
  outBtn
]
