<template>
  <!-- default width="23" height="27" -->
  <path d="M0.75 0.75H14.6893L22.25 8.31066V26.25H0.75V0.75Z" stroke="currentColor" stroke-width="1.5"/>
  <path d="M5.75 11.2002H17.25" stroke="currentColor" stroke-width="1.70833"/>
  <path d="M5.75 15.7998H17.25" stroke="currentColor" stroke-width="1.70833"/>
  <path d="M5.75 20.4H17.25" stroke="currentColor" stroke-width="1.70833"/>
</template>

<script>
export default {
  name: 'IconFile'
}
</script>
