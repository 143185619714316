<template>
  <button
    class="btn btn-link btn-link-big  btn-link-weight-500 btn-link-nf btn-modified btn-modified-plus"
    :class="{'btn-link-setting' : icon === 'Setting'}"
  >
    <VIcon
      v-if="icon"
      :width="setIconWidth"
      :height="setIconHeight"
      :icon-name="icon"
    />
    <slot />
  </button>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import { computed } from 'vue'
export default {
  components: { VIcon },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'ArrowBack'
    },
    iconWidth: {
      type: [String, Number],
      required: false,
      default: null
    },
    iconHeight: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  setup (props) {
    const setIconWidth = computed(() => {
      if (props.iconWidth) return props.iconWidth

      switch (props.icon) {
        case 'ArrowBack': return 8
        case 'Setting': return 23
        case 'Edit': return 19
        case 'Password': return 18
      }

      return 0
    })

    const setIconHeight = computed(() => {
      if (props.iconWidth) return props.iconWidth

      switch (props.icon) {
        case 'ArrowBack': return 14
        case 'Setting': return 23
        case 'Edit': return 21
        case 'Password': return 21
      }

      return 0
    })

    return {
      setIconWidth,
      setIconHeight
    }
  }
}
</script>
