import bidderMeta from '@/router/bidder/bidderMeta'

export default [
  {
    path: '/bidder/my-auctions/sidebar',
    name: 'bidder-my-auctions-sidebar',
    redirect: { name: 'bidder-my-auctions-active' },
    meta: {
      ...bidderMeta,
      layout: 'main-sidebar-layout'
    },
    props: {
      title: 'Мои аукционы'
    },
    component: () => import('@/views/Bidder/Sidebar/BidderSidebarMyAuctionsView'),
    children: [
      {
        path: '/bidder/my-auctions/active',
        name: 'bidder-my-auctions-active',
        meta: {
          ...bidderMeta,
          layout: 'main-sidebar-layout'
        },
        component: () => import('@/views/Bidder/MyAuctions/BidderMyAuctionsActiveView')
      },
      {
        path: '/bidder/my-auctions/archive',
        name: 'bidder-my-auctions-archive',
        meta: {
          ...bidderMeta,
          layout: 'main-sidebar-layout'
        },
        component: () => import('@/views/Bidder/MyAuctions/BidderMyAuctionsArchiveView')
      },
      {
        path: '/bidder/my-auctions/protocols',
        name: 'bidder-my-auctions-protocols',
        meta: {
          ...bidderMeta,
          layout: 'main-sidebar-layout'
        },
        children: [
          {
            path: '/bidder/my-auctions/protocol/:id',
            name: 'bidder-my-auctions-protocol',
            meta: {
              ...bidderMeta,
              layout: 'main-sidebar-layout'
            },
            component: () => import('@/views/Bidder/MyAuctions/Protocol/BidderMyAuctionsTabsProtocol')
          }
        ],
        component: () => import('@/views/Bidder/MyAuctions/BidderMyAuctionsProtocolsView')
      }
    ]
  }
]
