import { $authHost, $host } from '@/http/index'

export const unGetItem = async (auth, url) => {
  let data
  if (auth) {
    data = await $authHost.get(url)
  } else {
    data = await $host.get(url)
  }
  return data
}

export const getUserChangeHistory = async (id) => {
  const { data } = await $authHost.get(`/registers/change-history?search[User]=${id}`)
  return data.data
}

// Универсальный роут тачбара
export const changeItem = async (url, action, id, custom = null, formData = {}) => {
  if (custom) {
    return custom(id, formData)
  }
  const { data } = await $authHost.post(url, { action, id, ...formData })
  return data
}
