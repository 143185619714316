import { changeItem } from '@/http/tableApi'
import store from '@/store/index'
import { Alert } from '@/extension/Alert/Alert'

const actionList = new Map([
  ['delete', ({ item }) => {
    store.commit('item/deleteItemsById', item)
  }],
  ['active', ({ action, item }) => toggleActiveStateItem(action, item)],
  ['deactive', ({ action, item }) => toggleActiveStateItem(action, item)]
])

function toggleActiveStateItem (action, items) {
  store.commit('item/changeActiveStateItem', { action, items })
}

export class ItemService {
  async changeItem (url, action, items, custom, formData = null) {
    const item = JSON.parse(JSON.stringify(items))
    const data = await changeItem(url, action, item, custom, formData)
    if (actionList.has(action)) {
      actionList.get(action)({ action, item })
    }
    if (!data?.message) {
      return await Alert.show('success', 'Действие выполнено')
    }
    return data.message
  }
}
