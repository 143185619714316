<template>
  <!-- default width="50" height="50" -->
  <circle cx="24.9987" cy="25" r="21.4167" stroke="#5B8F4E" stroke-width="3"/>
  <path d="M14.582 27.5L20.5344 33.3333L35.4154 18.75" stroke="#5B8F4E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script>
export default {
  name: 'IconModalAlert'
}
</script>
