<template>
  <!-- default width="22" height="27" -->
  <circle cx="7.48885" cy="6.19979" r="3.74276" stroke="currentColor" stroke-width="1.5"/>
  <path d="M7.48828 9.94531V12.1917" stroke="currentColor" stroke-width="1.5"/>
  <mask id="path-3-inside-1_405_14395" fill="white">
    <path d="M14.9759 19.1793C14.9759 17.1934 14.187 15.2888 12.7827 13.8846C11.3784 12.4803 9.47386 11.6914 7.48794 11.6914C5.50201 11.6914 3.59743 12.4803 2.19317 13.8846C0.788906 15.2888 2.99866e-07 17.1934 0 19.1793L7.48794 19.1793H14.9759Z"/>
  </mask>
  <path d="M14.9759 19.1793C14.9759 17.1934 14.187 15.2888 12.7827 13.8846C11.3784 12.4803 9.47386 11.6914 7.48794 11.6914C5.50201 11.6914 3.59743 12.4803 2.19317 13.8846C0.788906 15.2888 2.99866e-07 17.1934 0 19.1793L7.48794 19.1793H14.9759Z" stroke="currentColor" stroke-width="3" mask="url(#path-3-inside-1_405_14395)"/>
  <path d="M13.9316 8.08984V10.3362" stroke="currentColor" stroke-width="1.5"/>
  <path d="M9.28711 12.2842C10.4987 11.0868 12.1419 10.4141 13.8554 10.4141C15.5688 10.4141 17.2121 11.0868 18.4236 12.2842C19.6352 13.4816 20.3159 15.1057 20.3159 16.7991H14.4063" stroke="currentColor" stroke-width="1.5"/>
  <mask id="path-6-inside-2_405_14395" fill="white">
    <path d="M10.9541 7.74754C11.6409 8.39868 12.5222 8.80668 13.4631 8.90904C14.4039 9.0114 15.3524 8.80246 16.1631 8.31425C16.9739 7.82604 17.6022 7.08547 17.9518 6.20602C18.3015 5.32657 18.3531 4.35674 18.0989 3.44513C17.8446 2.53352 17.2985 1.73041 16.5442 1.15885C15.7899 0.587283 14.869 0.278794 13.9226 0.280644C12.9762 0.282494 12.0565 0.594581 11.3044 1.16909C10.5523 1.7436 10.0094 2.54884 9.7587 3.46144L11.123 3.83619C11.2917 3.222 11.6571 2.68006 12.1633 2.29341C12.6694 1.90675 13.2884 1.69671 13.9254 1.69547C14.5623 1.69422 15.1821 1.90184 15.6897 2.28651C16.1974 2.67118 16.5649 3.21169 16.7361 3.82522C16.9072 4.43875 16.8724 5.09145 16.6371 5.68334C16.4018 6.27522 15.9789 6.77363 15.4333 7.10221C14.8876 7.43078 14.2493 7.5714 13.6161 7.50251C12.9829 7.43362 12.3897 7.15903 11.9275 6.7208L10.9541 7.74754Z"/>
  </mask>
  <path d="M10.9541 7.74754C11.6409 8.39868 12.5222 8.80668 13.4631 8.90904C14.4039 9.0114 15.3524 8.80246 16.1631 8.31425C16.9739 7.82604 17.6022 7.08547 17.9518 6.20602C18.3015 5.32657 18.3531 4.35674 18.0989 3.44513C17.8446 2.53352 17.2985 1.73041 16.5442 1.15885C15.7899 0.587283 14.869 0.278794 13.9226 0.280644C12.9762 0.282494 12.0565 0.594581 11.3044 1.16909C10.5523 1.7436 10.0094 2.54884 9.7587 3.46144L11.123 3.83619C11.2917 3.222 11.6571 2.68006 12.1633 2.29341C12.6694 1.90675 13.2884 1.69671 13.9254 1.69547C14.5623 1.69422 15.1821 1.90184 15.6897 2.28651C16.1974 2.67118 16.5649 3.21169 16.7361 3.82522C16.9072 4.43875 16.8724 5.09145 16.6371 5.68334C16.4018 6.27522 15.9789 6.77363 15.4333 7.10221C14.8876 7.43078 14.2493 7.5714 13.6161 7.50251C12.9829 7.43362 12.3897 7.15903 11.9275 6.7208L10.9541 7.74754Z" stroke="currentColor" stroke-width="3" mask="url(#path-6-inside-2_405_14395)"/>
</template>

<script>
export default {
  name: 'IconUsersLot'
}
</script>
