<template>
  <!-- default width="18" height="21" -->
  <rect x="0.85" y="7.85" width="16.3" height="12.3" rx="1.15" stroke="currentColor" stroke-width="1.7"/>
  <path d="M14 8C14 6.14348 14 1 9 1C4 1 4 6.14348 4 8" stroke="currentColor" stroke-width="1.7"/>
  <circle cx="9" cy="13" r="2" fill="currentColor"/>
  <rect x="8" y="13" width="2" height="5" rx="1" fill="currentColor"/>
</template>

<script>
export default {
  name: 'IconPassword'
}
</script>
