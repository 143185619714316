<script setup>
import VNotification from './VNotification'
import { defineProps } from 'vue'
import { useNotificationAnim } from '@/use/useNotitificationAnim'

defineProps({
  dataFilter: {
    type: Array,
    required: false
  },
  isWindow: {
    type: Boolean,
    required: false,
    default: false
  },
  notifications: {
    type: Array,
    required: true
  }
})

useNotificationAnim()

</script>

<template>
  <ul :class="['notification__list ', isWindow ? 'notification__list--scroll' : '']">
        <VNotification
          v-for="(notification, idx) in notifications"
          :key="notification.title"
          :is-window="isWindow"
          :idx="idx"
          :notification-data="notification"
        />
  </ul>
</template>
