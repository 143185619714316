function findById (id, arr) {
  return arr.find(item => item.id === id)
}
function findBy (key, value, array) {
  return array.find(item => item[key] === value)
}

function sortBy (key, arr) {
  if (key[0] === '-') {
    key = key.slice(1)
    return arr.sort((a, b) => sortDESC(key, a, b))
  }
  return arr.sort((a, b) => sortASC(key, a, b))
}
function sortASC (key, a, b) {
  if (a[key] > b[key]) return 1
  if (a[key] < b[key]) return -1
  return 0
}
function sortDESC (key, a, b) {
  if (a[key] > b[key]) return -1
  if (a[key] < b[key]) return 1
  return 0
}

// arr = [{ key, label, text }]
function syncArrayWithData (arr, data) {
  for (const item of arr) {
    if (item.key in data) {
      item.text = data[item.key]
    }
  }
}

function getIds (arr) {
  return arr.map(item => item.id)
}

function getIdsString (arr) {
  return getIds(arr).join(',')
}

export {
  findById,
  findBy,
  sortBy,
  syncArrayWithData,
  getIds,
  getIdsString
}
