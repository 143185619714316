import { $authHost } from '@/http'
import store from '@/store'
import axios from 'axios'

const TRADE_URL = '/trade-offer'
const OFFER_URL = `${TRADE_URL}/offer/`
const TRADE_OFFER_SUB_OFFER = `${TRADE_URL}/sub-offer/`
const TRADE_OFFER_STATISTIC_URL = `${TRADE_URL}/statistic`

export const getLatestPrice = async (lotId) => {
  const { data } = await $authHost.get(`${TRADE_OFFER_SUB_OFFER}last?lot_id=${lotId}`)
  return data
}

export const createOffer = async (id, formData) => {
  const { data } = await $authHost.post(OFFER_URL, formData)
  return data
}

export const getOffer = async (lotId = null, positionId = null) => {
  const lotSearch = `?search[LotId]=${lotId}`
  const positionSearch = `?search[PositionId]=${positionId}`

  const { data } = await $authHost.get(`${OFFER_URL}${lotSearch || positionSearch || ''}`)
  return data
}

export const getBidderCardList = async (lotId) => {
  const { data } = await $authHost.get(`${TRADE_OFFER_SUB_OFFER}story/?lot_id=${lotId}`)
  return data
}

export const sendDocumentBidder = async (lotId, file) => {
  return await $authHost.post(`/trade-offer/lots/${lotId}/add-user-file`, file)
}

export const sendDocumentsBidder = async (lotId, files) => {
  return await axios.all(files.forEach(file => sendDocumentBidder(lotId, file)))
}

export const participateBidder = async (formData) => {
  const { data } = await $authHost.post(OFFER_URL, formData)
  return data
}

export const getOfferForEditView = async (lotId, userId, sort, isFake) => {
  const { data } = await $authHost.get(`${TRADE_OFFER_SUB_OFFER}by-sort/?lot_id=${lotId}&user_id=${userId}&sort=${sort}&is_fake=${isFake}`)
  return data
}

export const getTime = async () => {
  if (!store.getters['auth/getIsAuth']) return
  const { data } = await $authHost.get('/trade-offer/time')
  return data.data
}

export const setWinner = async (lotId, winners) => {
  const { data } = await $authHost.put(`/trade-offer/winner/${lotId}`, { winners })
  return data
}

export const changeStatus = async (lotId, status, formData = {}) => {
  const { data } = await $authHost.patch(`/trade-offer/lots/${lotId}`, { status_id: status, ...formData })
  return data
}

export const sendFilesOffer = async (lotId, protocol) => {
  const { data } = await $authHost.post(`/trade-offer/lots/${lotId}/add-protocol`, protocol)
  return data
}

export const getProtocols = async () => {
  const { data } = await $authHost.get('/trade-offer/winner?expand=user,lot')
  return data
}

export const getProtocolById = async (protocolId) => {
  const { data } = await $authHost.get(`/trade-offer/winner/${protocolId}?expand=user,lot`)
  return data
}

export const getTradeOfferStatistic = async (params = {}) => {
  const { data } = await $authHost.get(`${TRADE_OFFER_STATISTIC_URL}`, { params })
  return data
}

export const downloadExcel = async (lotId) => {
  return await $authHost.get(`/generate/${lotId}`)
}
